import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_CAMP_INMATES_DATA,
  GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  SET_ALL_CREATE_LIGHTNING_INMATES_REGISTRATION,
  SET_ALL_LIGHTNING_INMATES_REGISTRATION,
  SET_ALL_UPDATE_LIGHTNING_INMATES_REGISTRATION,
  SET_EXPORT_CAMP_INMATES_DATA,
  SET_LIGHTNING_INMATES_REGISTRATION,
  SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
} from "redux/constants/LightningInmatesRegistration";

// Define an initial state
const initialState = {
  lightningInmatesRegistrationData: [],
  lightningIsInmatesRegistrationDataFetched: false,
  lightningInmatesRegistrationListingParam: {},
  lightningCampInmatesRegistrationExportedData: {},
  lightningCampInmatesRegistrationImportTemplateData: {},
  token: localStorage.getItem(AUTH_TOKEN),
};

// Reducer function
const lightningInmatesRegistration = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_LIGHTNING_INMATES_REGISTRATION:
      return {
        ...state,
        lightningInmatesRegistrationData: action.payload,
        lightningIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_CREATE_LIGHTNING_INMATES_REGISTRATION:
      // Handle the logic to create a new report here
      // You can update the state accordingly
      // For example, if you want to store the new report data, you can do something like this:

      return {
        ...state,
        lightningInmatesRegistrationData: action.payload,
        lightningIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_UPDATE_LIGHTNING_INMATES_REGISTRATION:
      return {
        ...state,
        lightningInmatesRegistrationData: action.payload,
        lightningIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_LIGHTNING_INMATES_REGISTRATION:
      return {
        ...state,
        lightningInmatesRegistrationListingParam: action.payload,
      };

    case GET_EXPORT_CAMP_INMATES_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_CAMP_INMATES_DATA:
      return {
        ...state,
        lightningCampInmatesRegistrationExportedData: action.payload,
      };

    case GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA:
      return {
        ...state,
        payload: action.payload,
      };

    case SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA:
      return {
        ...state,
        lightningCampInmatesRegistrationImportTemplateData: action.payload,
      };

    default:
      return state;
  }
};

export default lightningInmatesRegistration;
