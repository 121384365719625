/**
 * The below file contains the action methods for landslide report.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 07-12-2022
 */

import {
  GET_ALL_LANDSLIDE,
  GET_EXPORT_LANDSLIDE_DATA,
  SET_EXPORT_LANDSLIDE_DATA,
  SET_LANDSLIDE_LISTING,
  SET_LANDSLIDE_LISTING_PARAM,
  LANDSLIDE_IMPORT,
  LANDSLIDE_ADD_BASIC_SECTION,
  LANDSLIDE_EDIT_BASIC_SECTION,
  LANDSLIDE_ADD_INFDAMAGE_SECTION,
  LANDSLIDE_ADD_INFDAMAGEOTHER_SECTION,
  LANDSLIDE_GET_ALL_SECTION,
  LANDSLIDE_SET_ALL_SECTION,
  LANDSLIDE_RESET_BUTTON_LOADING,
  LANDSLIDE_SET_PARENT_ID,
  LANDSLIDE_ADD_REMARK_SECTION,
  LANDSLIDE_SET_NIL_REPORTING,
  LANDSLIDE_REPORT_SUBMIT,
  LANDSLIDE_REPORT_DISCARD,
  LANDSLIDE_SET_REDIRECT_TO_LISTING,
  LANDSLIDE_SET_DATA_SAVED,
  LANDSLIDE_ADD_POPAFFECTED_SECTION,
  LANDSLIDE_ADD_ERODED_SECTION,
  LANDSLIDE_ADD_RELIEFCAMP_SECTION,
  LANDSLIDE_ADD_CAMPINMATES_SECTION,
  LANDSLIDE_ADD_HLL_SECTION,
  LANDSLIDE_ADD_LIVESTOCK_SECTION,
  LANDSLIDE_ADD_HOUSEDAMAGED_SECTION,
  LANDSLIDE_ADD_RESCUEOPERATION_SECTION,
  LANDSLIDE_ADD_RELIEFDISTRIBUTION_SECTION,
  EXPORT_FO_LEVEL_LANDSLIDE_REPORT,
  SET_EXPORT_FO_LEVEL_LANDSLIDE_REPORT,
  LANDSLIDE_REVENUE_REPORT_APPROVE,
  LANDSLIDE_REVENUE_REPORT_DISAPPROVE,
  LANDSLIDE_REVENUE_REPORT_VERIFY,
  GET_LANDSLIDE_DISTRICT_REVIEW_LIST,
  SET_LANDSLIDE_DISTRICT_REVIEW_LIST,
  GET_LANDSLIDE_STATE_REVIEW_LIST,
  SET_LANDSLIDE_STATE_REVIEW_LIST,
  LANDSLIDE_DISTRICT_REPORT_APPROVE,
  LANDSLIDE_DISTRICT_REPORT_DISAPPROVE,
  LANDSLIDE_DISTRICT_REPORT_VERIFY,
  LANDSLIDE_STATE_REPORT_APPROVE,
  LANDSLIDE_STATE_REPORT_DISAPPROVE,
  GET_STATE_CUMULATIVE_LANDSLIDE_REPORT,
  SET_STATE_CUMULATIVE_LANDSLIDE_REPORT,
  GET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT,
  SET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT,
} from "redux/constants/Landslide";
import {
  LANDSLIDE_ADD_NONCAMPINMATES_SECTION,
  LANDSLIDE_SET_RELIEF_CENTER_SECTION,
  FREEZE_UNFREEZE_LANDSLIDE_RANGE_REPORT,
} from "../constants/Landslide";

//#region Action methods for landslide listing page

/**
 * Method for getting the landslide listing
 * @param {any} params Landslide Data
 * @returns Json Object
 */
export const getAllLandslide = (params) => {
  return {
    type: GET_ALL_LANDSLIDE,
    payload: params,
  };
};

/**
 * Method for setting the landslide listing details in state
 * @param {any} landslide Landslide Data
 * @returns Json Object
 */
export const setLandslideListing = (landslide) => {
  return {
    type: SET_LANDSLIDE_LISTING,
    payload: landslide,
  };
};

/**
 * Method for setting the landslide parameters
 * @param {any} data Landslide Data
 * @returns Json Object
 */
export const setLandslideTableParam = (data) => {
  return {
    type: SET_LANDSLIDE_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for getting the export file of landslide
 * @returns Json Object
 */
export const getExportLandslide = () => {
  return {
    type: GET_EXPORT_LANDSLIDE_DATA,
  };
};

/**
 * Method for setting the landslide export data in the state
 * @param {any} data Landslide Data
 * @returns Json Object
 */
export const setExportLandslide = (data) => {
  return {
    type: SET_EXPORT_LANDSLIDE_DATA,
    payload: data,
  };
};
//#endregion Action methods for landslide listing page

//#region Action methods for landslide import page
/**
 * Method for landslide import
 * @param {any} data Landslide Data
 * @returns Json Object
 */
export const landslideImport = (data) => {
  return {
    type: LANDSLIDE_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for landslide import page

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for getting the all section details
 * @param {number} data Basic Section Data Form Id and param
 * @returns Json Object
 */
export const landslideGetAllSection = (data) => {
  return {
    type: LANDSLIDE_GET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const landslideSetAllSection = (data) => {
  return {
    type: LANDSLIDE_SET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const landslideSetReliefCenterSection = (data) => {
  return {
    type: LANDSLIDE_SET_RELIEF_CENTER_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the parent id
 * @param {number} id Parent Id
 * @returns Json Object
 */
export const landslideSetParentId = (id) => {
  return {
    type: LANDSLIDE_SET_PARENT_ID,
    payload: id,
  };
};

/**
 * Method for reseting the button loading status
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const landslideResetButtonLoading = (data) => {
  return {
    type: LANDSLIDE_RESET_BUTTON_LOADING,
    payload: data,
  };
};

/**
 * Method for submitting the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const landslideReportSubmit = (data) => {
  return {
    type: LANDSLIDE_REPORT_SUBMIT,
    payload: data,
  };
};

/**
 * Method for discarding the report
 * @param {object} data Report Data
 * @returns Json Object
 */
export const landslideReportDiscard = (data) => {
  return {
    type: LANDSLIDE_REPORT_DISCARD,
    payload: data,
  };
};

/**
 * Method for setting the redirect to listing variable
 * @param {object} data  Data
 * @returns Json Object
 */
export const landslideSetRedirectToListing = (data) => {
  return {
    type: LANDSLIDE_SET_REDIRECT_TO_LISTING,
    payload: data,
  };
};

/**
 * Method for setting the value of landslideDataSaved object
 * @param {object} data Data
 * @returns Json Object
 */
export const landslideSetDataSaved = (data) => {
  return {
    type: LANDSLIDE_SET_DATA_SAVED,
    payload: data,
  };
};
//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const landslideAddBasicSection = (data) => {
  return {
    type: LANDSLIDE_ADD_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for editing the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const landslideEditBasicSection = (data) => {
  return {
    type: LANDSLIDE_EDIT_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the nil reporting value
 * @param {boolean} data Bool value
 * @returns Json Object
 */
export const landslideSetNilReporting = (data) => {
  return {
    type: LANDSLIDE_SET_NIL_REPORTING,
    payload: data,
  };
};
//#endregion Basic Section

//#region Infrastructure Damage Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the infDamage section details
 * @param {object} data InfDamage Section Data
 * @returns Json Object
 */
export const landslideAddInfDamageSection = (data) => {
  return {
    type: LANDSLIDE_ADD_INFDAMAGE_SECTION,
    payload: data,
  };
};
//#endregion Infrastructure Damage Section

//#region Infrastructure Damage Other Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the infDamageOther section details
 * @param {object} data InfDamageOther Section Data
 * @returns Json Object
 */
export const landslideAddInfDamageOtherSection = (data) => {
  return {
    type: LANDSLIDE_ADD_INFDAMAGEOTHER_SECTION,
    payload: data,
  };
};
//#endregion InfDamageOther Section

//#region Remark Section
/**
 * Method for adding the remarks
 * @param {object} data Remark Section Data
 * @returns Json Object
 */
export const landslideAddRemarkSection = (data) => {
  return {
    type: LANDSLIDE_ADD_REMARK_SECTION,
    payload: data,
  };
};
//#endregion Remark Section

//#region Population Affected Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 21-11-2022

/**
 * Method for adding the popAffected section details
 * @param {object} data PopAffected Section Data
 * @returns Json Object
 */
export const landslideAddPopAffectedSection = (data) => {
  return {
    type: LANDSLIDE_ADD_POPAFFECTED_SECTION,
    payload: data,
  };
};
//#endregion Population Affected Section

//#region Eroded Section
/**
 * Method for adding the Eroded section details
 * @param {object} data Eroded Section Data
 * @returns Json Object
 */
export const landslideAddErodedSection = (data) => {
  return {
    type: LANDSLIDE_ADD_ERODED_SECTION,
    payload: data,
  };
};
//#endregion Eroded Section

//#region Relief Camp Section
/**
 * Method for adding the Relief Camp section details
 * @param {object} data Relief Camp Section Data
 * @returns Json Object
 */
export const landslideAddReliefCampSection = (data) => {
  return {
    type: LANDSLIDE_ADD_RELIEFCAMP_SECTION,
    payload: data,
  };
};
//#endregion Relief Camp Section

//#region Camp Inmates Section
/**
 * Method for adding the Camp Inmates section details
 * @param {object} data Camp Inmates Section Data
 * @returns Json Object
 */
export const landslideAddCampInmatesSection = (data) => {
  return {
    type: LANDSLIDE_ADD_CAMPINMATES_SECTION,
    payload: data,
  };
};
//#endregion Camp Inmates Section

//#region Camp Non Inmates Section
/**
 * Method for adding the Non Camp Inmates section details
 * @param {object} data Non Camp Inmates Section Data
 * @returns Json Object
 */
export const landslideAddNonCampInmatesSection = (data) => {
  return {
    type: LANDSLIDE_ADD_NONCAMPINMATES_SECTION,
    payload: data,
  };
};
//#endregion Camp Inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the Human Live Lost section details
 * @param {object} data Human Live Lost Section Data
 * @returns Json Object
 */
export const landslideAddHllSection = (data) => {
  return {
    type: LANDSLIDE_ADD_HLL_SECTION,
    payload: data,
  };
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the LiveStock section details
 * @param {object} data LiveStock Section Data
 * @returns Json Object
 */
export const landslideAddLiveStockSection = (data) => {
  return {
    type: LANDSLIDE_ADD_LIVESTOCK_SECTION,
    payload: data,
  };
};
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the HouseDamaged section details
 * @param {object} data HouseDamaged Section Data
 * @returns Json Object
 */
export const landslideAddHouseDamagedSection = (data) => {
  return {
    type: LANDSLIDE_ADD_HOUSEDAMAGED_SECTION,
    payload: data,
  };
};
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the RescueOperation section details
 * @param {object} data RescueOperation Section Data
 * @returns Json Object
 */
export const landslideAddRescueOperationSection = (data) => {
  return {
    type: LANDSLIDE_ADD_RESCUEOPERATION_SECTION,
    payload: data,
  };
};
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the ReliefDistribution section details
 * @param {object} data ReliefDistribution Section Data
 * @returns Json Object
 */
export const landslideAddReliefDistributionSection = (data) => {
  return {
    type: LANDSLIDE_ADD_RELIEFDISTRIBUTION_SECTION,
    payload: data,
  };
};
//#endregion ReliefDistribution Section

//#region Methods for Reports and Approval and Disapproval
/**
 * Method for getting the FO level export report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Data
 * @returns Json Object
 */
export const getExportFoLevelLandslideReport = (data) => {
  return {
    type: EXPORT_FO_LEVEL_LANDSLIDE_REPORT,
    payload: data,
  };
};

/**
 * Method for setting the exported data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Data
 * @returns Json Object
 */
export const setExportFOLevelLandslideReport = (data) => {
  return {
    type: SET_EXPORT_FO_LEVEL_LANDSLIDE_REPORT,
    payload: data,
  };
};

/**
 * Method for approving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const landslideRevenueReportApprove = (data) => {
  return {
    type: LANDSLIDE_REVENUE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const landslideRevenueReportDisapprove = (data) => {
  return {
    type: LANDSLIDE_REVENUE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const landslideRevenueReportVerify = (data) => {
  return {
    type: LANDSLIDE_REVENUE_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for getting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getLandslideDistrictReviewList = (data) => {
  return {
    type: GET_LANDSLIDE_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setLandslideDistrictReviewList = (data) => {
  return {
    type: SET_LANDSLIDE_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for getting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getLandslideStateReviewList = (data) => {
  return {
    type: GET_LANDSLIDE_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setLandslideStateReviewList = (data) => {
  return {
    type: SET_LANDSLIDE_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const landslideDistrictReportApprove = (data) => {
  return {
    type: LANDSLIDE_DISTRICT_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const landslideDistrictReportDisapprove = (data) => {
  return {
    type: LANDSLIDE_DISTRICT_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report Data
 * @returns Json Object
 */
export const landslideDistrictReportVerify = (data) => {
  return {
    type: LANDSLIDE_DISTRICT_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const landslideStateReportApprove = (data) => {
  return {
    type: LANDSLIDE_STATE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const landslideStateReportDisapprove = (data) => {
  return {
    type: LANDSLIDE_STATE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for exporting state cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportStateCumulativeLandslideReport = (data) => {
  return {
    type: GET_STATE_CUMULATIVE_LANDSLIDE_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportStateCumulativeLandslideReport = (data) => {
  return {
    type: SET_STATE_CUMULATIVE_LANDSLIDE_REPORT,
    payload: data,
  };
};

/**
 * Method for exporting district cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportDistrictCumulativeLandslideReport = (data) => {
  return {
    type: GET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportDistrictCumulativeLandslideReport = (data) => {
  return {
    type: SET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT,
    payload: data,
  };
};

/**
 * Method for setting Freezing and Unfreezing Landslide Form Report for a Date Range
 * @author: vishal.mishra@velsof.com
 * @date : 22-01-2025
 * @param {object} data Report data
 * @returns Json Object
 */
export const landslideFreezeUnfreezeReportRange = (data) => {
  return {
    type: FREEZE_UNFREEZE_LANDSLIDE_RANGE_REPORT,
    payload: data,
  };
};

//#endregion Methods for Reports and Approval and Disapproval
