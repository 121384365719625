/**
 * The below file contains the Sagas method for Earthquake reports.
 * @author: shivam.sharma2@velsof.com
 * @date : 21-12-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  BasicSection,
  DiscardButton,
  InfDamageOtherSection,
  InfDamageSection,
  PopAffectedSection,
  ErodedSection,
  RemarkSection,
  SubmitButton,
  approveButton,
  disapproveButton,
  ReliefCampSection,
  CampInmatesSection,
  HllSection,
  LiveStockSection,
  HouseDamagedSection,
  RescueOperationSection,
  ReliefDistributionSection,
  verifyButton,
} from "constants/ApplicationConstant";
import {
  Earthquake_Added_Basic_Section,
  Earthquake_Added_CampInmates_Section,
  Earthquake_Added_Eroded_Section,
  Earthquake_Added_HLL_Section,
  Earthquake_Added_HouseDamaged_Section,
  Earthquake_Added_InfDamageOther_Section,
  Earthquake_Added_InfDamage_Section,
  Earthquake_Added_LiveStock_Section,
  Earthquake_Added_PopAffected_Section,
  Earthquake_Added_ReliefCamp_Section,
  Earthquake_Added_ReliefDistribution_Section,
  Earthquake_Added_Remark_Section,
  Earthquake_Added_RescueOperation_Section,
  Earthquake_Imported,
  Earthquake_Report_Discard,
  Earthquake_Report_Submit,
  Earthquake_Report_Approved,
  Earthquake_Report_Disapproved,
  Earthquake_Updated_Basic_Section,
  Earthquake_Report_Verify,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  earthquakeResetButtonLoading,
  earthquakeSetAllSection,
  earthquakeSetDataSaved,
  earthquakeSetParentId,
  earthquakeSetRedirectToListing,
  setExportEarthquake,
  setEarthquakeListing,
  setEarthquakeDistrictReviewList,
  setExportDistrictCumulativeEarthquakeReport,
  setExportStateCumulativeEarthquakeReport,
  setExportFOLevelEarthquakeReport,
  setEarthquakeStateReviewList,
} from "redux/actions/Earthquake";
import {
  GET_ALL_EARTHQUAKE,
  GET_EXPORT_EARTHQUAKE_DATA,
  EARTHQUAKE_ADD_BASIC_SECTION,
  EARTHQUAKE_ADD_INFDAMAGEOTHER_SECTION,
  EARTHQUAKE_ADD_INFDAMAGE_SECTION,
  EARTHQUAKE_ADD_REMARK_SECTION,
  EARTHQUAKE_EDIT_BASIC_SECTION,
  EARTHQUAKE_GET_ALL_SECTION,
  EARTHQUAKE_IMPORT,
  EARTHQUAKE_REPORT_DISCARD,
  EARTHQUAKE_REPORT_SUBMIT,
  EARTHQUAKE_ADD_POPAFFECTED_SECTION,
  EARTHQUAKE_ADD_ERODED_SECTION,
  EARTHQUAKE_ADD_RELIEFCAMP_SECTION,
  EARTHQUAKE_ADD_CAMPINMATES_SECTION,
  EARTHQUAKE_ADD_HLL_SECTION,
  EARTHQUAKE_ADD_LIVESTOCK_SECTION,
  EARTHQUAKE_ADD_HOUSEDAMAGED_SECTION,
  EARTHQUAKE_ADD_RESCUEOPERATION_SECTION,
  EARTHQUAKE_ADD_RELIEFDISTRIBUTION_SECTION,
  GET_EARTHQUAKE_DISTRICT_REVIEW_LIST,
  GET_EARTHQUAKE_STATE_REVIEW_LIST,
  EXPORT_FO_LEVEL_EARTHQUAKE_REPORT,
  EARTHQUAKE_REVENUE_REPORT_APPROVE,
  EARTHQUAKE_REVENUE_REPORT_DISAPPROVE,
  EARTHQUAKE_REVENUE_REPORT_VERIFY,
  EARTHQUAKE_DISTRICT_REPORT_APPROVE,
  EARTHQUAKE_DISTRICT_REPORT_DISAPPROVE,
  EARTHQUAKE_DISTRICT_REPORT_VERIFY,
  EARTHQUAKE_STATE_REPORT_APPROVE,
  EARTHQUAKE_STATE_REPORT_DISAPPROVE,
  GET_DISTRICT_CUMULATIVE_EARTHQUAKE_REPORT,
  GET_STATE_CUMULATIVE_EARTHQUAKE_REPORT,
} from "redux/constants/Earthquake";
import EarthquakeService from "services/EarthquakeService";
import {
  EARTHQUAKE_ADD_NONCAMPINMATES_SECTION,
  FREEZE_UNFREEZE_EARTHQUAKE_RANGE_REPORT,
} from "../constants/Earthquake";
import {
  freezeButton,
  NonCampInmatesSection,
} from "../../constants/ApplicationConstant";
import {
  Earthquake_Added_NonCampInmates_Section,
  Earthquake_Freeze_Successfully,
} from "../../constants/MessageConstant";
import { earthquakeSetReliefCenterSection } from "../actions/Earthquake";

//#region Method for listing

/**
 * Method for getting the earthquakes
 */
export function* getAllEarthquake() {
  yield takeEvery(GET_ALL_EARTHQUAKE, function* ({ payload }) {
    try {
      const earthquake = yield call(
        EarthquakeService.getAllEarthquake,
        payload
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(setEarthquakeListing(earthquake));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the earthquake export details
 */
export function* getEarthquakeExport() {
  yield takeEvery(GET_EXPORT_EARTHQUAKE_DATA, function* () {
    try {
      const earthquake = yield call(EarthquakeService.getExportEarthquakeData);
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(earthquake),
        };
        yield put(setExportEarthquake(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for earthquake import page

/**
 * Method for importing the earthquake
 */
export function* earthquakeImport() {
  yield takeEvery(EARTHQUAKE_IMPORT, function* ({ payload }) {
    try {
      const earthquake = yield call(
        EarthquakeService.earthquakeImport,
        payload
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: Earthquake_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for earthquake import page

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for getting the all section data
 */
export function* earthquakeGetAllSection() {
  yield takeEvery(EARTHQUAKE_GET_ALL_SECTION, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        EarthquakeService.earthquakeGetAllSection,
        payload.id,
        payload.param
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(earthquakeSetAllSection(infDepartment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for submitting the report
 */
export function* earthquakeReportSubmit() {
  yield takeEvery(EARTHQUAKE_REPORT_SUBMIT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        EarthquakeService.earthquakeReportSubmit,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: Earthquake_Report_Submit, type: Success })
        );
        yield put(earthquakeSetRedirectToListing(true));
        yield put(earthquakeResetButtonLoading({ section: SubmitButton }));
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: SubmitButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for discarding the report
 */
export function* earthquakeReportDiscard() {
  yield takeEvery(EARTHQUAKE_REPORT_DISCARD, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        EarthquakeService.earthquakeReportDiscard,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: Earthquake_Report_Discard, type: Success })
        );
        yield put(earthquakeSetRedirectToListing(true));
        yield put(earthquakeResetButtonLoading({ section: DiscardButton }));
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: DiscardButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding basic section
 */
export function* earthquakeAddBasicSection() {
  yield takeEvery(EARTHQUAKE_ADD_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };

      const earthquake = yield call(
        EarthquakeService.earthquakeAddBasicSection,
        final
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(earthquakeSetParentId(earthquake.data.id));
        yield put(earthquakeResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: Earthquake_Added_Basic_Section,
            type: Success,
          })
        );
        yield put(
          earthquakeSetDataSaved({ section: BasicSection, value: true })
        );
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for editing basic section
 */
export function* earthquakeEditBasicSection() {
  yield takeEvery(EARTHQUAKE_EDIT_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const earthquake = yield call(
        EarthquakeService.earthquakeEditBasicSection,
        payload.id,
        final
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(earthquakeResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: Earthquake_Updated_Basic_Section,
            type: Success,
          })
        );
        yield put(
          earthquakeSetDataSaved({ section: BasicSection, value: true })
        );
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Basic Section

//#region InfDamage Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding infDamage section
 */
export function* earthquakeAddInfDamageSection() {
  yield takeEvery(EARTHQUAKE_ADD_INFDAMAGE_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const earthquake = yield call(
        EarthquakeService.earthquakeAddInfDamageSection,
        final
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(earthquakeResetButtonLoading({ section: InfDamageSection }));
        yield put(
          showMessage({
            message: Earthquake_Added_InfDamage_Section,
            type: Success,
          })
        );
        yield put(
          earthquakeSetDataSaved({ section: InfDamageSection, value: true })
        );
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: InfDamageSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding infDamageOther section
 */
export function* earthquakeAddInfDamageOtherSection() {
  yield takeEvery(
    EARTHQUAKE_ADD_INFDAMAGEOTHER_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const earthquake = yield call(
          EarthquakeService.earthquakeAddInfDamageOtherSection,
          final
        );
        if (earthquake.error != null && earthquake.error.message) {
          yield put(
            showMessage({ message: earthquake.error.message, type: Error })
          );
        } else {
          yield put(
            earthquakeResetButtonLoading({ section: InfDamageOtherSection })
          );
          yield put(
            showMessage({
              message: Earthquake_Added_InfDamageOther_Section,
              type: Success,
            })
          );
          yield put(
            earthquakeSetDataSaved({
              section: InfDamageOtherSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          earthquakeResetButtonLoading({ section: InfDamageOtherSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion InfDamageOther Section

//#region Remark Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding Remark section
 */
export function* earthquakeAddRemarkSection() {
  yield takeEvery(EARTHQUAKE_ADD_REMARK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const Earthquake = yield call(
        EarthquakeService.earthquakeAddRemarkSection,
        final
      );
      if (Earthquake.error != null && Earthquake.error.message) {
        yield put(
          showMessage({ message: Earthquake.error.message, type: Error })
        );
      } else {
        yield put(earthquakeResetButtonLoading({ section: RemarkSection }));
        yield put(
          showMessage({
            message: Earthquake_Added_Remark_Section,
            type: Success,
          })
        );
        yield put(
          earthquakeSetDataSaved({ section: RemarkSection, value: true })
        );
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: RemarkSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Remark Section

//#region PopAffected Section
//@author: shivam.sharma2@velsof.com
//@date : 21-11-2022

/** Method for adding popaffected section
 */
export function* earthquakeAddPopAffectedSection() {
  yield takeEvery(EARTHQUAKE_ADD_POPAFFECTED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const earthquake = yield call(
        EarthquakeService.earthquakeAddPopAffectedSection,
        final
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(
          earthquakeResetButtonLoading({ section: PopAffectedSection })
        );
        yield put(
          showMessage({
            message: Earthquake_Added_PopAffected_Section,
            type: Success,
          })
        );
        yield put(
          earthquakeSetDataSaved({ section: PopAffectedSection, value: true })
        );
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: PopAffectedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion PopAffected Section

//#region Eroded Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding eroded section
 */
export function* earthquakeAddErodedSection() {
  yield takeEvery(EARTHQUAKE_ADD_ERODED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const earthquake = yield call(
        EarthquakeService.earthquakeAddErodedSection,
        final
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(earthquakeResetButtonLoading({ section: ErodedSection }));
        yield put(
          showMessage({
            message: Earthquake_Added_Eroded_Section,
            type: Success,
          })
        );
        yield put(
          earthquakeSetDataSaved({ section: ErodedSection, value: true })
        );
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: ErodedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Eroded Section

//#region Relief Camp Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding relief camp section
 */
export function* earthquakeAddReliefCampSection() {
  yield takeEvery(EARTHQUAKE_ADD_RELIEFCAMP_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const earthquake = yield call(
        EarthquakeService.earthquakeAddReliefCampSection,
        final
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(earthquakeResetButtonLoading({ section: ReliefCampSection }));
        yield put(
          showMessage({
            message: Earthquake_Added_ReliefCamp_Section,
            type: Success,
          })
        );
        yield put(
          earthquakeSetDataSaved({ section: ReliefCampSection, value: true })
        );
        yield put(
          earthquakeSetReliefCenterSection(
            earthquake?.data?.data?.reliefCenterData
          )
        );
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: ReliefCampSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Relief Camp Section

//#region Camp inmates Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding camp inmates section
 */
export function* earthquakeAddCampInmatesSection() {
  yield takeEvery(EARTHQUAKE_ADD_CAMPINMATES_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const earthquake = yield call(
        EarthquakeService.earthquakeAddCampInmatesSection,
        final
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(
          earthquakeResetButtonLoading({ section: CampInmatesSection })
        );
        yield put(
          showMessage({
            message: Earthquake_Added_CampInmates_Section,
            type: Success,
          })
        );
        yield put(
          earthquakeSetDataSaved({ section: CampInmatesSection, value: true })
        );
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: CampInmatesSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Camp Inmates Section

//#region Non Camp inmates Section
//@author: vishal.mishra@velsof.com
//@date : 06-02-2025

/** Method for adding non camp inmates section
 */
export function* earthquakeAddNonCampInmatesSection() {
  yield takeEvery(
    EARTHQUAKE_ADD_NONCAMPINMATES_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const earthquake = yield call(
          EarthquakeService.earthquakeAddNonCampInmatesSection,
          final
        );
        if (earthquake.error != null && earthquake.error.message) {
          yield put(
            showMessage({ message: earthquake.error.message, type: Error })
          );
        } else {
          yield put(
            earthquakeResetButtonLoading({ section: NonCampInmatesSection })
          );
          yield put(
            showMessage({
              message: Earthquake_Added_NonCampInmates_Section,
              type: Success,
            })
          );
          yield put(
            earthquakeSetDataSaved({
              section: NonCampInmatesSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          earthquakeResetButtonLoading({ section: NonCampInmatesSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion Non Camp Inmates Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding Human Live Lost section
 */
export function* earthquakeAddHLLSection() {
  yield takeEvery(EARTHQUAKE_ADD_HLL_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const earthquake = yield call(
        EarthquakeService.earthquakeAddHLLSection,
        final
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(earthquakeResetButtonLoading({ section: HllSection }));
        yield put(
          showMessage({
            message: Earthquake_Added_HLL_Section,
            type: Success,
          })
        );
        yield put(earthquakeSetDataSaved({ section: HllSection, value: true }));
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: HllSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding LiveStock section
 */
export function* earthquakeAddLiveStockSection() {
  yield takeEvery(EARTHQUAKE_ADD_LIVESTOCK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const earthquake = yield call(
        EarthquakeService.earthquakeAddLiveStockSection,
        final
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(earthquakeResetButtonLoading({ section: LiveStockSection }));
        yield put(
          showMessage({
            message: Earthquake_Added_LiveStock_Section,
            type: Success,
          })
        );
        yield put(
          earthquakeSetDataSaved({ section: LiveStockSection, value: true })
        );
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: LiveStockSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding HouseDamaged section
 */
export function* earthquakeAddHouseDamagedSection() {
  yield takeEvery(EARTHQUAKE_ADD_HOUSEDAMAGED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const earthquake = yield call(
        EarthquakeService.earthquakeAddHouseDamagedSection,
        final
      );
      if (earthquake.error != null && earthquake.error.message) {
        yield put(
          showMessage({ message: earthquake.error.message, type: Error })
        );
      } else {
        yield put(
          earthquakeResetButtonLoading({ section: HouseDamagedSection })
        );
        yield put(
          showMessage({
            message: Earthquake_Added_HouseDamaged_Section,
            type: Success,
          })
        );
        yield put(
          earthquakeSetDataSaved({ section: HouseDamagedSection, value: true })
        );
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: HouseDamagedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding RescueOperation section
 */
export function* earthquakeAddRescueOperationSection() {
  yield takeEvery(
    EARTHQUAKE_ADD_RESCUEOPERATION_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const earthquake = yield call(
          EarthquakeService.earthquakeAddRescueOperationSection,
          final
        );
        if (earthquake.error != null && earthquake.error.message) {
          yield put(
            showMessage({ message: earthquake.error.message, type: Error })
          );
        } else {
          yield put(
            earthquakeResetButtonLoading({ section: RescueOperationSection })
          );
          yield put(
            showMessage({
              message: Earthquake_Added_RescueOperation_Section,
              type: Success,
            })
          );
          yield put(
            earthquakeSetDataSaved({
              section: RescueOperationSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          earthquakeResetButtonLoading({ section: RescueOperationSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/** Method for adding ReliefDistribution section
 */
export function* earthquakeAddReliefDistributionSection() {
  yield takeEvery(
    EARTHQUAKE_ADD_RELIEFDISTRIBUTION_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const earthquake = yield call(
          EarthquakeService.earthquakeAddReliefDistributionSection,
          final
        );
        if (earthquake.error != null && earthquake.error.message) {
          yield put(
            showMessage({ message: earthquake.error.message, type: Error })
          );
        } else {
          yield put(
            earthquakeResetButtonLoading({ section: ReliefDistributionSection })
          );
          yield put(
            showMessage({
              message: Earthquake_Added_ReliefDistribution_Section,
              type: Success,
            })
          );
          yield put(
            earthquakeSetDataSaved({
              section: ReliefDistributionSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          earthquakeResetButtonLoading({ section: ReliefDistributionSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion ReliefDistribution Section

//#region
/**
 * The below method is for exporting the report in earthquake view form
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for getting the district export details
 */
export function* getExportFoLevelEarthquakeReport() {
  yield takeEvery(EXPORT_FO_LEVEL_EARTHQUAKE_REPORT, function* ({ payload }) {
    try {
      const exportedReport = yield call(
        EarthquakeService.getExportFoLevelEarthquakeReport,
        payload
      );

      if (exportedReport.error != null && exportedReport.error.message) {
        yield put(
          showMessage({ message: exportedReport.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(exportedReport),
          format: payload.format,
        };
        yield put(setExportFOLevelEarthquakeReport(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#region Method for Approval and Disapproval
/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for approving the revenue report
 */
export function* earthquakeRevenueReportApprove() {
  yield takeEvery(EARTHQUAKE_REVENUE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const earthquakeReportStatus = yield call(
        EarthquakeService.earthquakeRevenueReportApprove,
        payload
      );
      if (
        earthquakeReportStatus.error != null &&
        earthquakeReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: earthquakeReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Earthquake_Report_Approved, type: Success })
        );
        yield put(earthquakeSetRedirectToListing(true));
        yield put(earthquakeResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the revenue report
 */
export function* earthquakeRevenueReportDisapprove() {
  yield takeEvery(
    EARTHQUAKE_REVENUE_REPORT_DISAPPROVE,
    function* ({ payload }) {
      try {
        const earthquakeReportStatus = yield call(
          EarthquakeService.earthquakeRevenueReportDisapprove,
          payload
        );
        if (
          earthquakeReportStatus.error != null &&
          earthquakeReportStatus.error.message
        ) {
          yield put(
            showMessage({
              message: earthquakeReportStatus.error.message,
              type: Error,
            })
          );
        } else {
          yield put(
            showMessage({
              message: Earthquake_Report_Disapproved,
              type: Success,
            })
          );
          yield put(earthquakeSetRedirectToListing(true));
          yield put(
            earthquakeResetButtonLoading({ section: disapproveButton })
          );
        }
      } catch (err) {
        yield put(earthquakeResetButtonLoading({ section: disapproveButton }));
      }
    }
  );
}

/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for verify the revenue report
 */
export function* earthquakeRevenueReportVerify() {
  yield takeEvery(EARTHQUAKE_REVENUE_REPORT_VERIFY, function* ({ payload }) {
    try {
      const earthquakeReportStatus = yield call(
        EarthquakeService.earthquakeRevenueReportVerify,
        payload
      );
      if (
        earthquakeReportStatus.error != null &&
        earthquakeReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: earthquakeReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Earthquake_Report_Verify, type: Success })
        );
        yield put(earthquakeSetRedirectToListing(true));
        yield put(earthquakeResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* earthquakeDistrictReportApprove() {
  yield takeEvery(EARTHQUAKE_DISTRICT_REPORT_APPROVE, function* ({ payload }) {
    try {
      const earthquakeReportStatus = yield call(
        EarthquakeService.earthquakeDistrictReportApprove,
        payload
      );
      if (
        earthquakeReportStatus.error != null &&
        earthquakeReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: earthquakeReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Earthquake_Report_Approved, type: Success })
        );
        yield put(earthquakeSetRedirectToListing(true));
        yield put(earthquakeResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* earthquakeDistrictReportDisapprove() {
  yield takeEvery(
    EARTHQUAKE_DISTRICT_REPORT_DISAPPROVE,
    function* ({ payload }) {
      try {
        const earthquakeReportStatus = yield call(
          EarthquakeService.earthquakeDistrictReportDisapprove,
          payload
        );
        if (
          earthquakeReportStatus.error != null &&
          earthquakeReportStatus.error.message
        ) {
          yield put(
            showMessage({
              message: earthquakeReportStatus.error.message,
              type: Error,
            })
          );
        } else {
          yield put(
            showMessage({
              message: Earthquake_Report_Disapproved,
              type: Success,
            })
          );
          yield put(earthquakeSetRedirectToListing(true));
          yield put(
            earthquakeResetButtonLoading({ section: disapproveButton })
          );
        }
      } catch (err) {
        yield put(earthquakeResetButtonLoading({ section: disapproveButton }));
      }
    }
  );
}

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* earthquakeDistrictReportVerify() {
  yield takeEvery(EARTHQUAKE_DISTRICT_REPORT_VERIFY, function* ({ payload }) {
    try {
      const earthquakeReportStatus = yield call(
        EarthquakeService.earthquakeDistrictReportVerify,
        payload
      );
      if (
        earthquakeReportStatus.error != null &&
        earthquakeReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: earthquakeReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Earthquake_Report_Verify, type: Success })
        );
        yield put(earthquakeSetRedirectToListing(true));
        yield put(earthquakeResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* earthquakeStateReportApprove() {
  yield takeEvery(EARTHQUAKE_STATE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const earthquakeReportStatus = yield call(
        EarthquakeService.earthquakeStateReportApprove,
        payload
      );
      if (
        earthquakeReportStatus.error != null &&
        earthquakeReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: earthquakeReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Earthquake_Report_Approved, type: Success })
        );
        yield put(earthquakeSetRedirectToListing(true));
        yield put(earthquakeResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* earthquakeStateReportDisapprove() {
  yield takeEvery(EARTHQUAKE_STATE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const earthquakeReportStatus = yield call(
        EarthquakeService.earthquakeStateReportDisapprove,
        payload
      );
      if (
        earthquakeReportStatus.error != null &&
        earthquakeReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: earthquakeReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Earthquake_Report_Disapproved, type: Success })
        );
        yield put(earthquakeSetRedirectToListing(true));
        yield put(earthquakeResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(earthquakeResetButtonLoading({ section: disapproveButton }));
    }
  });
}
//#endregion Method for Approval and Disapproval

//#region
/**
 * The below method is for exporting the report in earthquake cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */

/**
 * Method for getting the cumulative state export details
 */
export function* getExportStateCumulativeEarthquakeReport() {
  yield takeEvery(
    GET_STATE_CUMULATIVE_EARTHQUAKE_REPORT,
    function* ({ payload }) {
      try {
        const exportedReport = yield call(
          EarthquakeService.getExportStateCumulativeEarthquakeReport,
          payload
        );
        if (exportedReport.error != null && exportedReport.error.message) {
          yield put(
            showMessage({ message: exportedReport.error.message, type: Error })
          );
        } else {
          if (payload?.sendEmail) {
            // Convert the Blob response to text
            const textResponse = yield exportedReport?.text();
            const parsedResponse = JSON.parse(textResponse);
            yield put(
              showMessage({
                message: parsedResponse?.message,
                type: Success,
              })
            );
          } else {
            let exportData = {
              reportData:
                payload.format === "html"
                  ? exportedReport
                  : window.URL.createObjectURL(exportedReport),
              format: payload.format,
            };
            yield put(setExportStateCumulativeEarthquakeReport(exportData));
          }
        }
      } catch (err) {
        let errPromise = yield err.response.data.text();
        yield put(
          showMessage({
            message: JSON.parse(errPromise).error.message,
            type: Error,
          })
        );
      }
    }
  );
}
//#endregion

/**
 * Method for getting the cumulative district export details
 */
export function* getExportDistrictCumulativeEarthquakeReport() {
  yield takeEvery(
    GET_DISTRICT_CUMULATIVE_EARTHQUAKE_REPORT,
    function* ({ payload }) {
      try {
        const exportedReport = yield call(
          EarthquakeService.getExportDistrictCumulativeEarthquakeReport,
          payload
        );
        if (exportedReport.error != null && exportedReport.error.message) {
          yield put(
            showMessage({ message: exportedReport.error.message, type: Error })
          );
        } else {
          let exportData = {
            reportData:
              payload.format === "html"
                ? exportedReport
                : window.URL.createObjectURL(exportedReport),
            format: payload.format,
          };
          yield put(setExportDistrictCumulativeEarthquakeReport(exportData));
        }
      } catch (err) {
        let errPromise = yield err.response.data.text();
        yield put(
          showMessage({
            message: JSON.parse(errPromise).error.message,
            type: Error,
          })
        );
      }
    }
  );
}
//#endregion

/**
 * Method for getting the district review list
 */
export function* getEarthquakeDistrictReviewList() {
  yield takeEvery(GET_EARTHQUAKE_DISTRICT_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        EarthquakeService.getEarthquakeDistrictReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setEarthquakeDistrictReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the state review list
 */
export function* getEarthquakeStateReviewList() {
  yield takeEvery(GET_EARTHQUAKE_STATE_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        EarthquakeService.getEarthquakeStateReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setEarthquakeStateReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for Freezing and unfreezing earthquake report for a selected date range
 * @author: vishal.mishra@velsof.com
 * @date : 06-02-2025
 */
export function* earthquakeFreezeReportRange() {
  yield takeEvery(
    FREEZE_UNFREEZE_EARTHQUAKE_RANGE_REPORT,
    function* ({ payload }) {
      try {
        const earthquakeReportStatus = yield call(
          EarthquakeService.earthquakeFreezeReportRange,
          payload
        );
        if (
          earthquakeReportStatus.error != null &&
          earthquakeReportStatus.error.message
        ) {
          yield put(
            showMessage({
              message: earthquakeReportStatus.error.message,
              type: Error,
            })
          );
        } else {
          yield put(
            showMessage({
              message: Earthquake_Freeze_Successfully,
              type: Success,
            })
          );
          yield put(earthquakeResetButtonLoading({ section: freezeButton }));
        }
      } catch (err) {
        yield put(earthquakeResetButtonLoading({ section: freezeButton }));
      }
    }
  );
}

//#endregion Method for Approval and Disapproval

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllEarthquake),
    fork(getEarthquakeExport),
    fork(earthquakeImport),
    fork(earthquakeAddBasicSection),
    fork(earthquakeEditBasicSection),
    fork(earthquakeGetAllSection),
    fork(earthquakeAddInfDamageSection),
    fork(earthquakeAddInfDamageOtherSection),
    fork(earthquakeAddRemarkSection),
    fork(earthquakeReportSubmit),
    fork(earthquakeReportDiscard),
    fork(earthquakeAddPopAffectedSection),
    fork(earthquakeAddErodedSection),
    fork(earthquakeAddReliefCampSection),
    fork(earthquakeAddCampInmatesSection),
    fork(earthquakeAddNonCampInmatesSection),
    fork(earthquakeAddHLLSection),
    fork(earthquakeAddLiveStockSection),
    fork(earthquakeAddHouseDamagedSection),
    fork(earthquakeAddRescueOperationSection),
    fork(earthquakeAddReliefDistributionSection),
    fork(earthquakeRevenueReportApprove),
    fork(earthquakeRevenueReportDisapprove),
    fork(earthquakeRevenueReportVerify),
    fork(earthquakeDistrictReportApprove),
    fork(earthquakeDistrictReportDisapprove),
    fork(earthquakeDistrictReportVerify),
    fork(earthquakeStateReportApprove),
    fork(earthquakeStateReportDisapprove),
    fork(getExportFoLevelEarthquakeReport),
    fork(getEarthquakeDistrictReviewList),
    fork(getEarthquakeStateReviewList),
    fork(getExportStateCumulativeEarthquakeReport),
    fork(getExportDistrictCumulativeEarthquakeReport),
    fork(earthquakeFreezeReportRange),
  ]);
}
