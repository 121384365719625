/**
 * The below file contains the action methods for urbanflood report.
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */

import {
  GET_ALL_URBANFLOOD,
  GET_EXPORT_URBANFLOOD_DATA,
  SET_EXPORT_URBANFLOOD_DATA,
  SET_URBANFLOOD_LISTING,
  SET_URBANFLOOD_LISTING_PARAM,
  URBANFLOOD_IMPORT,
  URBANFLOOD_ADD_BASIC_SECTION,
  URBANFLOOD_EDIT_BASIC_SECTION,
  URBANFLOOD_ADD_INFDAMAGE_SECTION,
  URBANFLOOD_ADD_INFDAMAGEOTHER_SECTION,
  URBANFLOOD_GET_ALL_SECTION,
  URBANFLOOD_SET_ALL_SECTION,
  URBANFLOOD_RESET_BUTTON_LOADING,
  URBANFLOOD_SET_PARENT_ID,
  URBANFLOOD_ADD_REMARK_SECTION,
  URBANFLOOD_SET_NIL_REPORTING,
  URBANFLOOD_REPORT_SUBMIT,
  URBANFLOOD_REPORT_DISCARD,
  URBANFLOOD_SET_REDIRECT_TO_LISTING,
  URBANFLOOD_SET_DATA_SAVED,
  URBANFLOOD_ADD_POPAFFECTED_SECTION,
  URBANFLOOD_ADD_ERODED_SECTION,
  URBANFLOOD_ADD_RELIEFCAMP_SECTION,
  URBANFLOOD_ADD_CAMPINMATES_SECTION,
  URBANFLOOD_ADD_HLL_SECTION,
  URBANFLOOD_ADD_LIVESTOCK_SECTION,
  URBANFLOOD_ADD_HOUSEDAMAGED_SECTION,
  URBANFLOOD_ADD_RESCUEOPERATION_SECTION,
  URBANFLOOD_ADD_RELIEFDISTRIBUTION_SECTION,
  EXPORT_FO_LEVEL_URBANFLOOD_REPORT,
  SET_EXPORT_FO_LEVEL_URBANFLOOD_REPORT,
  URBANFLOOD_REVENUE_REPORT_APPROVE,
  URBANFLOOD_REVENUE_REPORT_DISAPPROVE,
  URBANFLOOD_REVENUE_REPORT_VERIFY,
  GET_URBANFLOOD_DISTRICT_REVIEW_LIST,
  SET_URBANFLOOD_DISTRICT_REVIEW_LIST,
  GET_URBANFLOOD_STATE_REVIEW_LIST,
  SET_URBANFLOOD_STATE_REVIEW_LIST,
  URBANFLOOD_DISTRICT_REPORT_APPROVE,
  URBANFLOOD_DISTRICT_REPORT_DISAPPROVE,
  URBANFLOOD_DISTRICT_REPORT_VERIFY,
  URBANFLOOD_STATE_REPORT_APPROVE,
  URBANFLOOD_STATE_REPORT_DISAPPROVE,
  GET_STATE_CUMULATIVE_URBANFLOOD_REPORT,
  SET_STATE_CUMULATIVE_URBANFLOOD_REPORT,
  GET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT,
  SET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT,
} from "redux/constants/UrbanFlood";
import {
  URBANFLOOD_ADD_NONCAMPINMATES_SECTION,
  URBANFLOOD_SET_RELIEF_CENTER_SECTION,
  FREEZE_UNFREEZE_URBANFLOOD_RANGE_REPORT,
} from "../constants/UrbanFlood";

//#region Action methods for urbanflood listing page

/**
 * Method for getting the urbanflood listing
 * @param {any} params UrbanFlood Data
 * @returns Json Object
 */
export const getAllUrbanFlood = (params) => {
  return {
    type: GET_ALL_URBANFLOOD,
    payload: params,
  };
};

/**
 * Method for setting the urbanflood listing details in state
 * @param {any} urbanflood UrbanFlood Data
 * @returns Json Object
 */
export const setUrbanFloodListing = (urbanflood) => {
  return {
    type: SET_URBANFLOOD_LISTING,
    payload: urbanflood,
  };
};

/**
 * Method for setting the urbanflood parameters
 * @param {any} data UrbanFlood Data
 * @returns Json Object
 */
export const setUrbanFloodTableParam = (data) => {
  return {
    type: SET_URBANFLOOD_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for getting the export file of urbanflood
 * @returns Json Object
 */
export const getExportUrbanFlood = () => {
  return {
    type: GET_EXPORT_URBANFLOOD_DATA,
  };
};

/**
 * Method for setting the urbanflood export data in the state
 * @param {any} data UrbanFlood Data
 * @returns Json Object
 */
export const setExportUrbanFlood = (data) => {
  return {
    type: SET_EXPORT_URBANFLOOD_DATA,
    payload: data,
  };
};
//#endregion Action methods for urbanflood listing page

//#region Action methods for urbanflood import page
/**
 * Method for urbanflood import
 * @param {any} data UrbanFlood Data
 * @returns Json Object
 */
export const urbanfloodImport = (data) => {
  return {
    type: URBANFLOOD_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for urbanflood import page

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for getting the all section details
 * @param {number} data Basic Section Data Form Id and param
 * @returns Json Object
 */
export const urbanfloodGetAllSection = (data) => {
  return {
    type: URBANFLOOD_GET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const urbanfloodSetAllSection = (data) => {
  return {
    type: URBANFLOOD_SET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const urbanfloodSetReliefCenterSection = (data) => {
  return {
    type: URBANFLOOD_SET_RELIEF_CENTER_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the parent id
 * @param {number} id Parent Id
 * @returns Json Object
 */
export const urbanfloodSetParentId = (id) => {
  return {
    type: URBANFLOOD_SET_PARENT_ID,
    payload: id,
  };
};

/**
 * Method for reseting the button loading status
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const urbanfloodResetButtonLoading = (data) => {
  return {
    type: URBANFLOOD_RESET_BUTTON_LOADING,
    payload: data,
  };
};

/**
 * Method for submitting the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const urbanfloodReportSubmit = (data) => {
  return {
    type: URBANFLOOD_REPORT_SUBMIT,
    payload: data,
  };
};

/**
 * Method for discarding the report
 * @param {object} data Report Data
 * @returns Json Object
 */
export const urbanfloodReportDiscard = (data) => {
  return {
    type: URBANFLOOD_REPORT_DISCARD,
    payload: data,
  };
};

/**
 * Method for setting the redirect to listing variable
 * @param {object} data  Data
 * @returns Json Object
 */
export const urbanfloodSetRedirectToListing = (data) => {
  return {
    type: URBANFLOOD_SET_REDIRECT_TO_LISTING,
    payload: data,
  };
};

/**
 * Method for setting the value of urbanfloodDataSaved object
 * @param {object} data Data
 * @returns Json Object
 */
export const urbanfloodSetDataSaved = (data) => {
  return {
    type: URBANFLOOD_SET_DATA_SAVED,
    payload: data,
  };
};
//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const urbanfloodAddBasicSection = (data) => {
  return {
    type: URBANFLOOD_ADD_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for editing the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const urbanfloodEditBasicSection = (data) => {
  return {
    type: URBANFLOOD_EDIT_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the nil reporting value
 * @param {boolean} data Bool value
 * @returns Json Object
 */
export const urbanfloodSetNilReporting = (data) => {
  return {
    type: URBANFLOOD_SET_NIL_REPORTING,
    payload: data,
  };
};
//#endregion Basic Section

//#region Infrastructure Damage Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the infDamage section details
 * @param {object} data InfDamage Section Data
 * @returns Json Object
 */
export const urbanfloodAddInfDamageSection = (data) => {
  return {
    type: URBANFLOOD_ADD_INFDAMAGE_SECTION,
    payload: data,
  };
};
//#endregion Infrastructure Damage Section

//#region Infrastructure Damage Other Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the infDamageOther section details
 * @param {object} data InfDamageOther Section Data
 * @returns Json Object
 */
export const urbanfloodAddInfDamageOtherSection = (data) => {
  return {
    type: URBANFLOOD_ADD_INFDAMAGEOTHER_SECTION,
    payload: data,
  };
};
//#endregion InfDamageOther Section

//#region Remark Section
/**
 * Method for adding the remarks
 * @param {object} data Remark Section Data
 * @returns Json Object
 */
export const urbanfloodAddRemarkSection = (data) => {
  return {
    type: URBANFLOOD_ADD_REMARK_SECTION,
    payload: data,
  };
};
//#endregion Remark Section

//#region Population Affected Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022

/**
 * Method for adding the popAffected section details
 * @param {object} data PopAffected Section Data
 * @returns Json Object
 */
export const urbanfloodAddPopAffectedSection = (data) => {
  return {
    type: URBANFLOOD_ADD_POPAFFECTED_SECTION,
    payload: data,
  };
};
//#endregion Population Affected Section

//#region Eroded Section
/**
 * Method for adding the Eroded section details
 * @param {object} data Eroded Section Data
 * @returns Json Object
 */
export const urbanfloodAddErodedSection = (data) => {
  return {
    type: URBANFLOOD_ADD_ERODED_SECTION,
    payload: data,
  };
};
//#endregion Eroded Section

//#region Relief Camp Section
/**
 * Method for adding the Relief Camp section details
 * @param {object} data Relief Camp Section Data
 * @returns Json Object
 */
export const urbanfloodAddReliefCampSection = (data) => {
  return {
    type: URBANFLOOD_ADD_RELIEFCAMP_SECTION,
    payload: data,
  };
};
//#endregion Relief Camp Section

//#region Camp Inmates Section
/**
 * Method for adding the Camp Inmates section details
 * @param {object} data Camp Inmates Section Data
 * @returns Json Object
 */
export const urbanfloodAddCampInmatesSection = (data) => {
  return {
    type: URBANFLOOD_ADD_CAMPINMATES_SECTION,
    payload: data,
  };
};
//#endregion Camp Inmates Section

//#region Camp Non Inmates Section
/**
 * Method for adding the Non Camp Inmates section details
 * @param {object} data Non Camp Inmates Section Data
 * @returns Json Object
 */
export const urbanfloodAddNonCampInmatesSection = (data) => {
  return {
    type: URBANFLOOD_ADD_NONCAMPINMATES_SECTION,
    payload: data,
  };
};
//#endregion Non Camp Inmates Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the Human Live Lost section details
 * @param {object} data Human Live Lost Section Data
 * @returns Json Object
 */
export const urbanfloodAddHllSection = (data) => {
  return {
    type: URBANFLOOD_ADD_HLL_SECTION,
    payload: data,
  };
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the LiveStock section details
 * @param {object} data LiveStock Section Data
 * @returns Json Object
 */
export const urbanfloodAddLiveStockSection = (data) => {
  return {
    type: URBANFLOOD_ADD_LIVESTOCK_SECTION,
    payload: data,
  };
};
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the HouseDamaged section details
 * @param {object} data HouseDamaged Section Data
 * @returns Json Object
 */
export const urbanfloodAddHouseDamagedSection = (data) => {
  return {
    type: URBANFLOOD_ADD_HOUSEDAMAGED_SECTION,
    payload: data,
  };
};
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the RescueOperation section details
 * @param {object} data RescueOperation Section Data
 * @returns Json Object
 */
export const urbanfloodAddRescueOperationSection = (data) => {
  return {
    type: URBANFLOOD_ADD_RESCUEOPERATION_SECTION,
    payload: data,
  };
};
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
/**
 * Method for adding the ReliefDistribution section details
 * @param {object} data ReliefDistribution Section Data
 * @returns Json Object
 */
export const urbanfloodAddReliefDistributionSection = (data) => {
  return {
    type: URBANFLOOD_ADD_RELIEFDISTRIBUTION_SECTION,
    payload: data,
  };
};
//#endregion ReliefDistribution Section

//#region Methods for Reports and Approval and Disapproval
/**
 * Method for getting the FO level export report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Data
 * @returns Json Object
 */
export const getExportFoLevelUrbanFloodReport = (data) => {
  return {
    type: EXPORT_FO_LEVEL_URBANFLOOD_REPORT,
    payload: data,
  };
};

/**
 * Method for setting the exported data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Data
 * @returns Json Object
 */
export const setExportFOLevelUrbanFloodReport = (data) => {
  return {
    type: SET_EXPORT_FO_LEVEL_URBANFLOOD_REPORT,
    payload: data,
  };
};

/**
 * Method for approving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const urbanfloodRevenueReportApprove = (data) => {
  return {
    type: URBANFLOOD_REVENUE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const urbanfloodRevenueReportDisapprove = (data) => {
  return {
    type: URBANFLOOD_REVENUE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const urbanfloodRevenueReportVerify = (data) => {
  return {
    type: URBANFLOOD_REVENUE_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for getting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const getUrbanFloodDistrictReviewList = (data) => {
  return {
    type: GET_URBANFLOOD_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const setUrbanFloodDistrictReviewList = (data) => {
  return {
    type: SET_URBANFLOOD_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for getting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const getUrbanFloodStateReviewList = (data) => {
  return {
    type: GET_URBANFLOOD_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const setUrbanFloodStateReviewList = (data) => {
  return {
    type: SET_URBANFLOOD_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const urbanfloodDistrictReportApprove = (data) => {
  return {
    type: URBANFLOOD_DISTRICT_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const urbanfloodDistrictReportDisapprove = (data) => {
  return {
    type: URBANFLOOD_DISTRICT_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report Data
 * @returns Json Object
 */
export const urbanfloodDistrictReportVerify = (data) => {
  return {
    type: URBANFLOOD_DISTRICT_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const urbanfloodStateReportApprove = (data) => {
  return {
    type: URBANFLOOD_STATE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const urbanfloodStateReportDisapprove = (data) => {
  return {
    type: URBANFLOOD_STATE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for exporting state cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportStateCumulativeUrbanFloodReport = (data) => {
  return {
    type: GET_STATE_CUMULATIVE_URBANFLOOD_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportStateCumulativeUrbanFloodReport = (data) => {
  return {
    type: SET_STATE_CUMULATIVE_URBANFLOOD_REPORT,
    payload: data,
  };
};

/**
 * Method for exporting district cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportDistrictCumulativeUrbanFloodReport = (data) => {
  return {
    type: GET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportDistrictCumulativeUrbanFloodReport = (data) => {
  return {
    type: SET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT,
    payload: data,
  };
};

/**
 * Method for setting Freezing and Unfreezing UrbanFlood Form Report for a Date Range
 * @author: vishal.mishra@velsof.com
 * @date : 10-02-2025
 * @param {object} data Report data
 * @returns Json Object
 */
export const urbanfloodFreezeUnfreezeReportRange = (data) => {
  return {
    type: FREEZE_UNFREEZE_URBANFLOOD_RANGE_REPORT,
    payload: data,
  };
};

//#endregion Methods for Reports and Approval and Disapproval
