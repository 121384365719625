/**
 * The below file contains the action methods for lightning report.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 26-12-2022
 */

import {
  GET_ALL_LIGHTNING,
  GET_EXPORT_LIGHTNING_DATA,
  SET_EXPORT_LIGHTNING_DATA,
  SET_LIGHTNING_LISTING,
  SET_LIGHTNING_LISTING_PARAM,
  LIGHTNING_IMPORT,
  LIGHTNING_ADD_BASIC_SECTION,
  LIGHTNING_EDIT_BASIC_SECTION,
  LIGHTNING_ADD_INFDAMAGEOTHER_SECTION,
  LIGHTNING_GET_ALL_SECTION,
  LIGHTNING_SET_ALL_SECTION,
  LIGHTNING_RESET_BUTTON_LOADING,
  LIGHTNING_SET_PARENT_ID,
  LIGHTNING_ADD_REMARK_SECTION,
  LIGHTNING_SET_NIL_REPORTING,
  LIGHTNING_REPORT_SUBMIT,
  LIGHTNING_REPORT_DISCARD,
  LIGHTNING_SET_REDIRECT_TO_LISTING,
  LIGHTNING_SET_DATA_SAVED,
  LIGHTNING_ADD_POPAFFECTED_SECTION,
  LIGHTNING_ADD_RELIEFCAMP_SECTION,
  LIGHTNING_ADD_CAMPINMATES_SECTION,
  LIGHTNING_ADD_HLL_SECTION,
  LIGHTNING_ADD_LIVESTOCK_SECTION,
  LIGHTNING_ADD_HOUSEDAMAGED_SECTION,
  LIGHTNING_ADD_RESCUEOPERATION_SECTION,
  LIGHTNING_ADD_RELIEFDISTRIBUTION_SECTION,
  EXPORT_FO_LEVEL_LIGHTNING_REPORT,
  SET_EXPORT_FO_LEVEL_LIGHTNING_REPORT,
  LIGHTNING_REVENUE_REPORT_APPROVE,
  LIGHTNING_REVENUE_REPORT_DISAPPROVE,
  LIGHTNING_REVENUE_REPORT_VERIFY,
  GET_LIGHTNING_DISTRICT_REVIEW_LIST,
  SET_LIGHTNING_DISTRICT_REVIEW_LIST,
  GET_LIGHTNING_STATE_REVIEW_LIST,
  SET_LIGHTNING_STATE_REVIEW_LIST,
  LIGHTNING_DISTRICT_REPORT_APPROVE,
  LIGHTNING_DISTRICT_REPORT_DISAPPROVE,
  LIGHTNING_DISTRICT_REPORT_VERIFY,
  LIGHTNING_STATE_REPORT_APPROVE,
  LIGHTNING_STATE_REPORT_DISAPPROVE,
  GET_STATE_CUMULATIVE_LIGHTNING_REPORT,
  SET_STATE_CUMULATIVE_LIGHTNING_REPORT,
  GET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT,
  SET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT,
} from "redux/constants/Lightning";
import {
  LIGHTNING_ADD_NONCAMPINMATES_SECTION,
  LIGHTNING_SET_RELIEF_CENTER_SECTION,
  FREEZE_UNFREEZE_LIGHTNING_RANGE_REPORT,
} from "../constants/Lightning";

//#region Action methods for lightning listing page

/**
 * Method for getting the lightning listing
 * @param {any} params Lightning Data
 * @returns Json Object
 */
export const getAllLightning = (params) => {
  return {
    type: GET_ALL_LIGHTNING,
    payload: params,
  };
};

/**
 * Method for setting the lightning listing details in state
 * @param {any} lightning Lightning Data
 * @returns Json Object
 */
export const setLightningListing = (lightning) => {
  return {
    type: SET_LIGHTNING_LISTING,
    payload: lightning,
  };
};

/**
 * Method for setting the lightning parameters
 * @param {any} data Lightning Data
 * @returns Json Object
 */
export const setLightningTableParam = (data) => {
  return {
    type: SET_LIGHTNING_LISTING_PARAM,
    payload: data,
  };
};

/**
 * Method for getting the export file of lightning
 * @returns Json Object
 */
export const getExportLightning = () => {
  return {
    type: GET_EXPORT_LIGHTNING_DATA,
  };
};

/**
 * Method for setting the lightning export data in the state
 * @param {any} data Lightning Data
 * @returns Json Object
 */
export const setExportLightning = (data) => {
  return {
    type: SET_EXPORT_LIGHTNING_DATA,
    payload: data,
  };
};
//#endregion Action methods for lightning listing page

//#region Action methods for lightning import page
/**
 * Method for lightning import
 * @param {any} data Lightning Data
 * @returns Json Object
 */
export const lightningImport = (data) => {
  return {
    type: LIGHTNING_IMPORT,
    payload: data,
  };
};
//#endregion Action methods for lightning import page

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for getting the all section details
 * @param {number} data Basic Section Data Form Id and param
 * @returns Json Object
 */
export const lightningGetAllSection = (data) => {
  return {
    type: LIGHTNING_GET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const lightningSetAllSection = (data) => {
  return {
    type: LIGHTNING_SET_ALL_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the all section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const lightningSetReliefCenterSection = (data) => {
  return {
    type: LIGHTNING_SET_RELIEF_CENTER_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the parent id
 * @param {number} id Parent Id
 * @returns Json Object
 */
export const lightningSetParentId = (id) => {
  return {
    type: LIGHTNING_SET_PARENT_ID,
    payload: id,
  };
};

/**
 * Method for reseting the button loading status
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const lightningResetButtonLoading = (data) => {
  return {
    type: LIGHTNING_RESET_BUTTON_LOADING,
    payload: data,
  };
};

/**
 * Method for submitting the report
 * @param {object} data Report data
 * @returns Json Object
 */
export const lightningReportSubmit = (data) => {
  return {
    type: LIGHTNING_REPORT_SUBMIT,
    payload: data,
  };
};

/**
 * Method for discarding the report
 * @param {object} data Report Data
 * @returns Json Object
 */
export const lightningReportDiscard = (data) => {
  return {
    type: LIGHTNING_REPORT_DISCARD,
    payload: data,
  };
};

/**
 * Method for setting the redirect to listing variable
 * @param {object} data  Data
 * @returns Json Object
 */
export const lightningSetRedirectToListing = (data) => {
  return {
    type: LIGHTNING_SET_REDIRECT_TO_LISTING,
    payload: data,
  };
};

/**
 * Method for setting the value of lightningDataSaved object
 * @param {object} data Data
 * @returns Json Object
 */
export const lightningSetDataSaved = (data) => {
  return {
    type: LIGHTNING_SET_DATA_SAVED,
    payload: data,
  };
};
//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const lightningAddBasicSection = (data) => {
  return {
    type: LIGHTNING_ADD_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for editing the basic section details
 * @param {object} data Basic Section Data
 * @returns Json Object
 */
export const lightningEditBasicSection = (data) => {
  return {
    type: LIGHTNING_EDIT_BASIC_SECTION,
    payload: data,
  };
};

/**
 * Method for setting the nil reporting value
 * @param {boolean} data Bool value
 * @returns Json Object
 */
export const lightningSetNilReporting = (data) => {
  return {
    type: LIGHTNING_SET_NIL_REPORTING,
    payload: data,
  };
};
//#endregion Basic Section

//#region Infrastructure Damage Other Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the infDamageOther section details
 * @param {object} data InfDamageOther Section Data
 * @returns Json Object
 */
export const lightningAddInfDamageOtherSection = (data) => {
  return {
    type: LIGHTNING_ADD_INFDAMAGEOTHER_SECTION,
    payload: data,
  };
};
//#endregion InfDamageOther Section

//#region Remark Section
/**
 * Method for adding the remarks
 * @param {object} data Remark Section Data
 * @returns Json Object
 */
export const lightningAddRemarkSection = (data) => {
  return {
    type: LIGHTNING_ADD_REMARK_SECTION,
    payload: data,
  };
};
//#endregion Remark Section

//#region Population Affected Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the popAffected section details
 * @param {object} data PopAffected Section Data
 * @returns Json Object
 */
export const lightningAddPopAffectedSection = (data) => {
  return {
    type: LIGHTNING_ADD_POPAFFECTED_SECTION,
    payload: data,
  };
};
//#endregion Population Affected Section

//#region Relief Camp Section
/**
 * Method for adding the Relief Camp section details
 * @param {object} data Relief Camp Section Data
 * @returns Json Object
 */
export const lightningAddReliefCampSection = (data) => {
  return {
    type: LIGHTNING_ADD_RELIEFCAMP_SECTION,
    payload: data,
  };
};
//#endregion Relief Camp Section

//#region Camp Inmates Section
/**
 * Method for adding the Camp Inmates section details
 * @param {object} data Camp Inmates Section Data
 * @returns Json Object
 */
export const lightningAddCampInmatesSection = (data) => {
  return {
    type: LIGHTNING_ADD_CAMPINMATES_SECTION,
    payload: data,
  };
};
//#endregion Camp Inmates Section

//#region Camp Non Inmates Section
/**
 * Method for adding the Non Camp Inmates section details
 * @param {object} data Non Camp Inmates Section Data
 * @returns Json Object
 */
export const lightningAddNonCampInmatesSection = (data) => {
  return {
    type: LIGHTNING_ADD_NONCAMPINMATES_SECTION,
    payload: data,
  };
};
//#endregion Non Camp Inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the Human Live Lost section details
 * @param {object} data Human Live Lost Section Data
 * @returns Json Object
 */
export const lightningAddHllSection = (data) => {
  return {
    type: LIGHTNING_ADD_HLL_SECTION,
    payload: data,
  };
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the LiveStock section details
 * @param {object} data LiveStock Section Data
 * @returns Json Object
 */
export const lightningAddLiveStockSection = (data) => {
  return {
    type: LIGHTNING_ADD_LIVESTOCK_SECTION,
    payload: data,
  };
};
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the HouseDamaged section details
 * @param {object} data HouseDamaged Section Data
 * @returns Json Object
 */
export const lightningAddHouseDamagedSection = (data) => {
  return {
    type: LIGHTNING_ADD_HOUSEDAMAGED_SECTION,
    payload: data,
  };
};
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the RescueOperation section details
 * @param {object} data RescueOperation Section Data
 * @returns Json Object
 */
export const lightningAddRescueOperationSection = (data) => {
  return {
    type: LIGHTNING_ADD_RESCUEOPERATION_SECTION,
    payload: data,
  };
};
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the ReliefDistribution section details
 * @param {object} data ReliefDistribution Section Data
 * @returns Json Object
 */
export const lightningAddReliefDistributionSection = (data) => {
  return {
    type: LIGHTNING_ADD_RELIEFDISTRIBUTION_SECTION,
    payload: data,
  };
};
//#endregion ReliefDistribution Section

//#region Methods for Reports and Approval and Disapproval
/**
 * Method for getting the FO level export report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Data
 * @returns Json Object
 */
export const getExportFoLevelLightningReport = (data) => {
  return {
    type: EXPORT_FO_LEVEL_LIGHTNING_REPORT,
    payload: data,
  };
};

/**
 * Method for setting the exported data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Data
 * @returns Json Object
 */
export const setExportFOLevelLightningReport = (data) => {
  return {
    type: SET_EXPORT_FO_LEVEL_LIGHTNING_REPORT,
    payload: data,
  };
};

/**
 * Method for approving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const lightningRevenueReportApprove = (data) => {
  return {
    type: LIGHTNING_REVENUE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const lightningRevenueReportDisapprove = (data) => {
  return {
    type: LIGHTNING_REVENUE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const lightningRevenueReportVerify = (data) => {
  return {
    type: LIGHTNING_REVENUE_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for getting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getLightningDistrictReviewList = (data) => {
  return {
    type: GET_LIGHTNING_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the district review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setLightningDistrictReviewList = (data) => {
  return {
    type: SET_LIGHTNING_DISTRICT_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for getting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getLightningStateReviewList = (data) => {
  return {
    type: GET_LIGHTNING_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for setting the state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setLightningStateReviewList = (data) => {
  return {
    type: SET_LIGHTNING_STATE_REVIEW_LIST,
    payload: data,
  };
};

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const lightningDistrictReportApprove = (data) => {
  return {
    type: LIGHTNING_DISTRICT_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const lightningDistrictReportDisapprove = (data) => {
  return {
    type: LIGHTNING_DISTRICT_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for verifying the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report Data
 * @returns Json Object
 */
export const lightningDistrictReportVerify = (data) => {
  return {
    type: LIGHTNING_DISTRICT_REPORT_VERIFY,
    payload: data,
  };
};

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const lightningStateReportApprove = (data) => {
  return {
    type: LIGHTNING_STATE_REPORT_APPROVE,
    payload: data,
  };
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const lightningStateReportDisapprove = (data) => {
  return {
    type: LIGHTNING_STATE_REPORT_DISAPPROVE,
    payload: data,
  };
};

/**
 * Method for exporting state cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportStateCumulativeLightningReport = (data) => {
  return {
    type: GET_STATE_CUMULATIVE_LIGHTNING_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportStateCumulativeLightningReport = (data) => {
  return {
    type: SET_STATE_CUMULATIVE_LIGHTNING_REPORT,
    payload: data,
  };
};

/**
 * Method for exporting district cumulative report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const getExportDistrictCumulativeLightningReport = (data) => {
  return {
    type: GET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT,
    payload: data,
  };
};

/**
 * Method for setting exported state cumulative report data
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} data Report data
 * @returns Json Object
 */
export const setExportDistrictCumulativeLightningReport = (data) => {
  return {
    type: SET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT,
    payload: data,
  };
};

/**
 * Method for setting Freezing and Unfreezing Lightning Form Report for a Date Range
 * @author: vishal.mishra@velsof.com
 * @date : 29-01-2025
 * @param {object} data Report data
 * @returns Json Object
 */
export const lightningFreezeUnfreezeReportRange = (data) => {
  return {
    type: FREEZE_UNFREEZE_LIGHTNING_RANGE_REPORT,
    payload: data,
  };
};

//#endregion Methods for Reports and Approval and Disapproval
