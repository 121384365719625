/**
 * The below file contains the actions used in the Non Camp Inmates Registration.
 * @author: vishal.mishra@velsof.com
 * @date :06-02-2025
 */

import {
  NON_CAMP_INMATES_REGISTRATION_IMPORT,
  GET_ALL_CREATE_URBANFLOOD_NON_INMATES_REGISTRATION,
  GET_ALL_URBANFLOOD_NON_INMATES_REGISTRATION,
  GET_ALL_UPDATE_URBANFLOOD_NON_INMATES_REGISTRATION,
  GET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_ALL_CREATE_URBANFLOOD_NON_INMATES_REGISTRATION,
  SET_ALL_URBANFLOOD_NON_INMATES_REGISTRATION,
  SET_ALL_UPDATE_URBANFLOOD_NON_INMATES_REGISTRATION,
  SET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_URBANFLOOD_NON_INMATES_REGISTRATION,
  NON_CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
} from "redux/constants/UrbanFloodNonInmatesRegistration";

// Import the constants you defined

// Action to fetch the Non Camp Inmates Registration
export const urbanfloodGetAllNonInmatesRegistration = (data) => {
  return {
    type: GET_ALL_URBANFLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const urbanfloodSetAllNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_URBANFLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Non Camp Inmates Registration
export const urbanfloodGetAllCreateNonInmatesRegistration = (data) => {
  return {
    type: GET_ALL_CREATE_URBANFLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const urbanfloodSetAllCreateNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_CREATE_URBANFLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Non Camp Inmates Registration
export const urbanfloodGetAllUpdateNonInmatesRegistration = (data, id) => {
  return {
    type: GET_ALL_UPDATE_URBANFLOOD_NON_INMATES_REGISTRATION,
    payload: data,
    id: id,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const urbanfloodSetAllUpdateNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_UPDATE_URBANFLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for setting the UrbanFlood Non Inmates Registration parameters
 * @param {any} data UrbanFlood Data
 * @returns Json Object
 */
export const urbanfloodSetNonInmatesRegistrationTableParam = (data) => {
  return {
    type: SET_URBANFLOOD_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for Non Camp Inmates Registration import
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const urbanfloodNonCampInmatesRegistrationImport = (data) => {
  return {
    type: NON_CAMP_INMATES_REGISTRATION_IMPORT,
    payload: data,
  };
};

/**
 * Method for Non Camp Inmates Registration import excel
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const urbanfloodNonCampInmatesRegistrationImportExcel = (data) => {
  return {
    type: NON_CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
    payload: data,
  };
};

/**
 * Method for getting the export file of Non Camp Inmates Registration
 * @returns Json Object
 */
export const urbanfloodGetExportNonInmatesRegistration = (data) => {
  return {
    type: GET_EXPORT_NON_CAMP_INMATES_DATA,
    payload: data,
  };
};

/**
 * Method for setting the reliefCamp export data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const urbanfloodSetExportNonInmatesRegistration = (data) => {
  return {
    type: SET_EXPORT_NON_CAMP_INMATES_DATA,
    payload: data,
  };
};
