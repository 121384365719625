/**
 * The below file contains the actions used in the Non Camp Inmates Registration.
 * @author: vishal.mishra@velsof.com
 * @date :06-02-2025
 */

import {
  NON_CAMP_INMATES_REGISTRATION_IMPORT,
  GET_ALL_CREATE_EARTHQUAKE_NON_INMATES_REGISTRATION,
  GET_ALL_EARTHQUAKE_NON_INMATES_REGISTRATION,
  GET_ALL_UPDATE_EARTHQUAKE_NON_INMATES_REGISTRATION,
  GET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_ALL_CREATE_EARTHQUAKE_NON_INMATES_REGISTRATION,
  SET_ALL_EARTHQUAKE_NON_INMATES_REGISTRATION,
  SET_ALL_UPDATE_EARTHQUAKE_NON_INMATES_REGISTRATION,
  SET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_EARTHQUAKE_NON_INMATES_REGISTRATION,
  NON_CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
} from "redux/constants/EarthquakeNonInmatesRegistration";

// Import the constants you defined

// Action to fetch the Non Camp Inmates Registration
export const earthquakeGetAllNonInmatesRegistration = (data) => {
  return {
    type: GET_ALL_EARTHQUAKE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const earthquakeSetAllNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_EARTHQUAKE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Non Camp Inmates Registration
export const earthquakeGetAllCreateNonInmatesRegistration = (data) => {
  return {
    type: GET_ALL_CREATE_EARTHQUAKE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const earthquakeSetAllCreateNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_CREATE_EARTHQUAKE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Non Camp Inmates Registration
export const earthquakeGetAllUpdateNonInmatesRegistration = (data, id) => {
  return {
    type: GET_ALL_UPDATE_EARTHQUAKE_NON_INMATES_REGISTRATION,
    payload: data,
    id: id,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const earthquakeSetAllUpdateNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_UPDATE_EARTHQUAKE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for setting the Earthquake Non Inmates Registration parameters
 * @param {any} data Earthquake Data
 * @returns Json Object
 */
export const earthquakeSetNonInmatesRegistrationTableParam = (data) => {
  return {
    type: SET_EARTHQUAKE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for Non Camp Inmates Registration import
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const earthquakeNonCampInmatesRegistrationImport = (data) => {
  return {
    type: NON_CAMP_INMATES_REGISTRATION_IMPORT,
    payload: data,
  };
};

/**
 * Method for Non Camp Inmates Registration import excel
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const earthquakeNonCampInmatesRegistrationImportExcel = (data) => {
  return {
    type: NON_CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
    payload: data,
  };
};

/**
 * Method for getting the export file of Non Camp Inmates Registration
 * @returns Json Object
 */
export const earthquakeGetExportNonInmatesRegistration = (data) => {
  return {
    type: GET_EXPORT_NON_CAMP_INMATES_DATA,
    payload: data,
  };
};

/**
 * Method for setting the reliefCamp export data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const earthquakeSetExportNonInmatesRegistration = (data) => {
  return {
    type: SET_EXPORT_NON_CAMP_INMATES_DATA,
    payload: data,
  };
};
