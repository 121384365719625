import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_CAMP_INMATES_DATA,
  GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  SET_ALL_CREATE_STORM_INMATES_REGISTRATION,
  SET_ALL_STORM_INMATES_REGISTRATION,
  SET_ALL_UPDATE_STORM_INMATES_REGISTRATION,
  SET_EXPORT_CAMP_INMATES_DATA,
  SET_STORM_INMATES_REGISTRATION,
  SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
} from "redux/constants/StormInmatesRegistration";

// Define an initial state
const initialState = {
  stormInmatesRegistrationData: [],
  stormIsInmatesRegistrationDataFetched: false,
  stormInmatesRegistrationListingParam: {},
  stormCampInmatesRegistrationExportedData: {},
  stormCampInmatesRegistrationImportTemplateData: {},
  token: localStorage.getItem(AUTH_TOKEN),
};

// Reducer function
const stormInmatesRegistration = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_STORM_INMATES_REGISTRATION:
      return {
        ...state,
        stormInmatesRegistrationData: action.payload,
        stormIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_CREATE_STORM_INMATES_REGISTRATION:
      // Handle the logic to create a new report here
      // You can update the state accordingly
      // For example, if you want to store the new report data, you can do something like this:

      return {
        ...state,
        stormInmatesRegistrationData: action.payload,
        stormIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_UPDATE_STORM_INMATES_REGISTRATION:
      return {
        ...state,
        stormInmatesRegistrationData: action.payload,
        stormIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_STORM_INMATES_REGISTRATION:
      return {
        ...state,
        stormInmatesRegistrationListingParam: action.payload,
      };

    case GET_EXPORT_CAMP_INMATES_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_CAMP_INMATES_DATA:
      return {
        ...state,
        stormCampInmatesRegistrationExportedData: action.payload,
      };

    case GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA:
      return {
        ...state,
        payload: action.payload,
      };

    case SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA:
      return {
        ...state,
        stormCampInmatesRegistrationImportTemplateData: action.payload,
      };

    default:
      return state;
  }
};

export default stormInmatesRegistration;
