/**
 * The below file contains the urbanflood reports state objects
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */

import {
  BasicSection,
  CampInmatesSection,
  DiscardButton,
  ErodedSection,
  HllSection,
  HouseDamagedSection,
  InfDamageOtherSection,
  InfDamageSection,
  LiveStockSection,
  PopAffectedSection,
  ReliefCampSection,
  ReliefDistributionSection,
  RemarkSection,
  RescueOperationSection,
  SubmitButton,
} from "constants/ApplicationConstant";
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_URBANFLOOD_DATA,
  URBANFLOOD_ADD_BASIC_SECTION,
  URBANFLOOD_ADD_INFDAMAGEOTHER_SECTION,
  URBANFLOOD_ADD_INFDAMAGE_SECTION,
  URBANFLOOD_ADD_REMARK_SECTION,
  URBANFLOOD_EDIT_BASIC_SECTION,
  URBANFLOOD_REPORT_DISCARD,
  URBANFLOOD_REPORT_SUBMIT,
  URBANFLOOD_RESET_BUTTON_LOADING,
  URBANFLOOD_SET_ALL_SECTION,
  URBANFLOOD_SET_DATA_SAVED,
  URBANFLOOD_SET_NIL_REPORTING,
  URBANFLOOD_SET_PARENT_ID,
  URBANFLOOD_SET_REDIRECT_TO_LISTING,
  SET_EXPORT_URBANFLOOD_DATA,
  SET_URBANFLOOD_LISTING,
  SET_URBANFLOOD_LISTING_PARAM,
  URBANFLOOD_ADD_POPAFFECTED_SECTION,
  URBANFLOOD_ADD_ERODED_SECTION,
  URBANFLOOD_ADD_CAMPINMATES_SECTION,
  URBANFLOOD_ADD_RELIEFCAMP_SECTION,
  URBANFLOOD_ADD_HLL_SECTION,
  URBANFLOOD_ADD_LIVESTOCK_SECTION,
  URBANFLOOD_ADD_HOUSEDAMAGED_SECTION,
  URBANFLOOD_ADD_RELIEFDISTRIBUTION_SECTION,
  URBANFLOOD_ADD_RESCUEOPERATION_SECTION,
  SET_URBANFLOOD_DISTRICT_REVIEW_LIST,
  SET_URBANFLOOD_STATE_REVIEW_LIST,
  URBANFLOOD_DISTRICT_REPORT_APPROVE,
  URBANFLOOD_DISTRICT_REPORT_DISAPPROVE,
  URBANFLOOD_DISTRICT_REPORT_VERIFY,
  URBANFLOOD_STATE_REPORT_APPROVE,
  URBANFLOOD_STATE_REPORT_DISAPPROVE,
  SET_EXPORT_FO_LEVEL_URBANFLOOD_REPORT,
  SET_STATE_CUMULATIVE_URBANFLOOD_REPORT,
  SET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT,
  URBANFLOOD_REVENUE_REPORT_DISAPPROVE,
  URBANFLOOD_REVENUE_REPORT_VERIFY,
} from "redux/constants/UrbanFlood";
import { NonCampInmatesSection } from "../../constants/ApplicationConstant";
import {
  URBANFLOOD_ADD_NONCAMPINMATES_SECTION,
  URBANFLOOD_SET_RELIEF_CENTER_SECTION,
  FREEZE_UNFREEZE_URBANFLOOD_RANGE_REPORT,
} from "../constants/UrbanFlood";

//Initial state
const initState = {
  //Keys for listing
  urbanfloodList: {},
  isUrbanFloodDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  urbanfloodListingParam: {},
  urbanfloodExportedData: {},
  exportFoLevelUrbanFloodReport: {
    report: "",
    format: "",
  },
  exportStateCumulativeUrbanFloodReport: {
    report: "",
    format: "",
  },
  exportDistrictCumulativeUrbanFloodReport: {
    report: "",
    format: "",
  },
  //Keys for listing

  //Keys for All Section Data
  urbanfloodParentId: "",
  urbanfloodAllSectionData: {},
  urbanfloodReliefCenterData: [],
  urbanfloodButtonLoading: {
    basicSection: false,
    popAffectedSection: false,
    erodedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    nonCampInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
    submitButton: false,
    discardButton: false,
    approveButton: false,
    disapproveButton: false,
    verifyButton: false,
  },
  urbanfloodDataSaved: {
    basicSection: false,
    popAffectedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    nonCampInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
  },
  urbanfloodIsNilReporting: false,
  urbanfloodRedirectToListing: false,
  //Keys for All Section Data

  //Keys for Approval Disapproval
  urbanfloodDistrictReviewList: {},
  urbanfloodStateReviewList: {},
  //Keys for Approval Disapproval

  //keys for UrbanFlood Form Freeze Report Range
  urbanfloodFreezeReportRange: {},
  //keys for UrbanFlood Form Freeze Report Range
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const urbanflood = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_URBANFLOOD_LISTING:
      return {
        ...state,
        urbanfloodList: action.payload,
        isUrbanFloodDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_URBANFLOOD_LISTING_PARAM:
      return {
        ...state,
        urbanfloodListingParam: action.payload,
      };

    case GET_EXPORT_URBANFLOOD_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_URBANFLOOD_DATA:
      return {
        ...state,
        urbanfloodExportedData: action.payload,
      };
    //Cases for listing

    //Cases for All Section
    case URBANFLOOD_SET_ALL_SECTION:
      return {
        ...state,
        urbanfloodAllSectionData: action.payload,
      };

    case URBANFLOOD_SET_RELIEF_CENTER_SECTION:
      return {
        ...state,
        urbanfloodReliefCenterData: action.payload,
      };

    case URBANFLOOD_RESET_BUTTON_LOADING:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            basicSection: false,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            popAffectedSection: false,
          },
        };
      } else if (action.payload.section === ErodedSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            erodedSection: false,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            reliefCampSection: false,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            campInmatesSection: false,
          },
        };
      } else if (action.payload.section === NonCampInmatesSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            nonCampInmatesSection: false,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            hLLSection: false,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            liveStockSection: false,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            houseDamagedSection: false,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            rescueOperationSection: false,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            reliefDistributionSection: false,
          },
        };
      } else if (action.payload.section === InfDamageSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            infDamageSection: false,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            infDamageOtherSection: false,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            remarkSection: false,
          },
        };
      } else if (action.payload.section === SubmitButton) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            submitButton: false,
          },
        };
      } else if (action.payload.section === DiscardButton) {
        return {
          ...state,
          urbanfloodButtonLoading: {
            ...state.urbanfloodButtonLoading,
            discardButton: false,
          },
        };
      } else {
        return {
          ...state,
          urbanfloodButtonLoading: {
            basicSection: false,
            popAffectedSection: false,
            erodedSection: false,
            reliefCampSection: false,
            campInmatesSection: false,
            nonCampInmatesSection: false,
            hLLSection: false,
            liveStockSection: false,
            houseDamagedSection: false,
            rescueOperationSection: false,
            reliefDistributionSection: false,
            infDamageSection: false,
            infDamageOtherSection: false,
            remarkSection: false,
            submitButton: false,
            discardButton: false,
            approveButton: false,
            disapproveButton: false,
            verify: false,
          },
        };
      }

    case URBANFLOOD_SET_PARENT_ID:
      return {
        ...state,
        urbanfloodParentId: action.payload,
      };

    case URBANFLOOD_SET_REDIRECT_TO_LISTING:
      return {
        ...state,
        urbanfloodRedirectToListing: action.payload,
      };

    case URBANFLOOD_SET_DATA_SAVED:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            basicSection: action.payload.value,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            popAffectedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ErodedSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            erodedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            reliefCampSection: action.payload.value,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            campInmatesSection: action.payload.value,
          },
        };
      } else if (action.payload.section === NonCampInmatesSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            nonCampInmatesSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            hLLSection: action.payload.value,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            liveStockSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            houseDamagedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            rescueOperationSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            reliefDistributionSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            infDamageSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            infDamageOtherSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          urbanfloodDataSaved: {
            ...state.urbanfloodDataSaved,
            remarkSection: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case URBANFLOOD_REPORT_SUBMIT:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          submitButton: true,
        },
      };

    case URBANFLOOD_REPORT_DISCARD:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          approveButton: true,
        },
      };

    case URBANFLOOD_REVENUE_REPORT_DISAPPROVE:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          disapproveButton: true,
        },
      };

    case URBANFLOOD_REVENUE_REPORT_VERIFY:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          verifyButton: true,
        },
      };
    //#endregion

    case URBANFLOOD_REPORT_DISCARD:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          discardButton: true,
        },
      };

    case URBANFLOOD_DISTRICT_REPORT_APPROVE:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          approveButton: true,
        },
      };

    case URBANFLOOD_DISTRICT_REPORT_DISAPPROVE:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          disapproveButton: true,
        },
      };

    case URBANFLOOD_DISTRICT_REPORT_VERIFY:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          verifyButton: true,
        },
      };

    case URBANFLOOD_STATE_REPORT_APPROVE:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          approveButton: true,
        },
      };

    case URBANFLOOD_STATE_REPORT_DISAPPROVE:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          disapproveButton: true,
        },
      };
    //Cases for All Section

    //Cases for Basic Section
    case URBANFLOOD_ADD_BASIC_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          basicSection: true,
        },
      };

    case URBANFLOOD_EDIT_BASIC_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          basicSection: true,
        },
      };

    case URBANFLOOD_SET_NIL_REPORTING:
      return {
        ...state,
        urbanfloodIsNilReporting: action.payload,
      };
    //Cases for Basic Section

    //Cases for InfDamage Section
    case URBANFLOOD_ADD_INFDAMAGE_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          infDamageSection: true,
        },
      };
    //Cases for InfDamage Section

    //Cases for InfDamageOther Section
    case URBANFLOOD_ADD_INFDAMAGEOTHER_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          infDamageOtherSection: true,
        },
      };

    //Cases for Remark Section
    case URBANFLOOD_ADD_REMARK_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          remarkSection: true,
        },
      };

    //Cases for UrbanFlood Pop Affected Section
    case URBANFLOOD_ADD_POPAFFECTED_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          popAffectedSection: true,
        },
      };
    //Cases for UrbanFlood Pop Affected Section

    //Cases for UrbanFlood Eroded Section
    case URBANFLOOD_ADD_ERODED_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          erodedSection: true,
        },
      };
    //Cases for UrbanFlood Eroded Section

    //Cases for UrbanFlood Relief Camp Section
    case URBANFLOOD_ADD_RELIEFCAMP_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          reliefCampSection: true,
        },
      };
    //Cases for UrbanFlood Relief Camp Section

    //Cases for UrbanFlood Relief Camp Section
    case URBANFLOOD_ADD_CAMPINMATES_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          campInmatesSection: true,
        },
      };
    //Cases for UrbanFlood Relief Camp Section

    //Cases for UrbanFlood Non Camp Inmates Section
    case URBANFLOOD_ADD_NONCAMPINMATES_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          nonCampInmatesSection: true,
        },
      };
    //Cases for UrbanFlood Non Camp Inmates

    //Cases for UrbanFlood Hll Section
    case URBANFLOOD_ADD_HLL_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          hLLSection: true,
        },
      };
    //Cases for UrbanFlood Hll Section

    //Cases for UrbanFlood LiveStock Section
    case URBANFLOOD_ADD_LIVESTOCK_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          liveStockSection: true,
        },
      };
    //Cases for UrbanFlood LiveStock Section

    //Cases for UrbanFlood House Damaged Section
    case URBANFLOOD_ADD_HOUSEDAMAGED_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          houseDamagedSection: true,
        },
      };
    //Cases for UrbanFlood House Damaged Section

    //Cases for UrbanFlood Rescue Operation Section
    case URBANFLOOD_ADD_RESCUEOPERATION_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          rescueOperationSection: true,
        },
      };
    //Cases for UrbanFlood Rescue Operation Section

    //Cases for UrbanFlood Relief Distribution Section
    case URBANFLOOD_ADD_RELIEFDISTRIBUTION_SECTION:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          reliefDistributionSection: true,
        },
      };
    //Cases for UrbanFlood Relief Distribution Section

    // Cases for UrbanFlood form PDF download section
    case SET_EXPORT_FO_LEVEL_URBANFLOOD_REPORT:
      return {
        ...state,
        exportFoLevelUrbanFloodReport: {
          report: action.payload.url,
          format: action.payload.format,
        },
      };
    // Cases for UrbanFlood form export file section

    //Cases for Approval and Disapproval
    case SET_URBANFLOOD_DISTRICT_REVIEW_LIST:
      return {
        ...state,
        urbanfloodDistrictReviewList: action.payload,
      };

    case SET_URBANFLOOD_STATE_REVIEW_LIST:
      return {
        ...state,
        urbanfloodStateReviewList: action.payload,
      };
    //Cases for Approval and Disapproval

    // Cases for UrbanFlood state cumulative report
    case SET_STATE_CUMULATIVE_URBANFLOOD_REPORT:
      return {
        ...state,
        exportStateCumulativeUrbanFloodReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for UrbanFlood state cumulative report

    // Cases for UrbanFlood district cumulative report
    case SET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT:
      return {
        ...state,
        exportDistrictCumulativeUrbanFloodReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for UrbanFlood district cumulative report

    // Cases for UrbanFlood Freeze report for a selected range
    case FREEZE_UNFREEZE_URBANFLOOD_RANGE_REPORT:
      return {
        ...state,
        urbanfloodButtonLoading: {
          ...state.urbanfloodButtonLoading,
          urbanfloodFreezeButton: true,
        },
        urbanfloodFreezeReportRange: action.payload,
      };
    // Cases for UrbanFlood Freeze report for a selected range

    default:
      return state;
  }
};

export default urbanflood;
