/**
 * The below file contains the actions used in the Camp Inmates Registration.
 * @author: vishal.mishra@velsof.com
 * @date :06-02-2025
 */

import {
  CAMP_INMATES_REGISTRATION_IMPORT,
  GET_ALL_CREATE_EARTHQUAKE_INMATES_REGISTRATION,
  GET_ALL_EARTHQUAKE_INMATES_REGISTRATION,
  GET_ALL_UPDATE_EARTHQUAKE_INMATES_REGISTRATION,
  GET_EXPORT_CAMP_INMATES_DATA,
  GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  SET_ALL_CREATE_EARTHQUAKE_INMATES_REGISTRATION,
  SET_ALL_EARTHQUAKE_INMATES_REGISTRATION,
  SET_ALL_UPDATE_EARTHQUAKE_INMATES_REGISTRATION,
  SET_EXPORT_CAMP_INMATES_DATA,
  SET_EARTHQUAKE_INMATES_REGISTRATION,
  SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
} from "redux/constants/EarthquakeInmatesRegistration";

// Import the constants you defined

// Action to fetch the Camp Inmates Registration
export const earthquakeGetAllInmatesRegistration = (data) => {
  return {
    type: GET_ALL_EARTHQUAKE_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Camp Inmates Registration
export const earthquakeSetAllInmatesRegistration = (data) => {
  return {
    type: SET_ALL_EARTHQUAKE_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Camp Inmates Registration
export const earthquakeGetAllCreateInmatesRegistration = (data) => {
  return {
    type: GET_ALL_CREATE_EARTHQUAKE_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Camp Inmates Registration
export const earthquakeSetAllCreateInmatesRegistration = (data) => {
  return {
    type: SET_ALL_CREATE_EARTHQUAKE_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Camp Inmates Registration
export const earthquakeGetAllUpdateInmatesRegistration = (data, id) => {
  return {
    type: GET_ALL_UPDATE_EARTHQUAKE_INMATES_REGISTRATION,
    payload: data,
    id: id,
  };
};

// Action to fetch a new Camp Inmates Registration
export const earthquakeSetAllUpdateInmatesRegistration = (data) => {
  return {
    type: SET_ALL_UPDATE_EARTHQUAKE_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for setting the Earthquake Inmates Registration parameters
 * @param {any} data Earthquake Data
 * @returns Json Object
 */
export const earthquakeSetInmatesRegistrationTableParam = (data) => {
  return {
    type: SET_EARTHQUAKE_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for Camp Inmates Registration import
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const earthquakeCampInmatesRegistrationImport = (data) => {
  return {
    type: CAMP_INMATES_REGISTRATION_IMPORT,
    payload: data,
  };
};

/**
 * Method for Non Camp Inmates Registration import excel
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const earthquakeCampInmatesRegistrationImportExcel = (data) => {
  return {
    type: CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
    payload: data,
  };
};

/**
 * Method for getting the export file of Camp Inmates Registration
 * @returns Json Object
 */
export const earthquakeGetExportInmatesRegistration = (data) => {
  return {
    type: GET_EXPORT_CAMP_INMATES_DATA,
    payload: data,
  };
};

/**
 * Method for setting the reliefCamp export data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const earthquakeSetExportInmatesRegistration = (data) => {
  return {
    type: SET_EXPORT_CAMP_INMATES_DATA,
    payload: data,
  };
};
/**
 * Method for getting the import template of Camp Inmates Registration
 * @returns Json Object
 */
export const earthquakeGetImportTemplateInmatesRegistration = (data) => {
  return {
    type: GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
    payload: data,
  };
};

/**
 * Method for setting the import template data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const earthquakeSetImportTemplateInmatesRegistration = (data) => {
  return {
    type: SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
    payload: data,
  };
};
