/**
 * The below file contains the actions used in the Camp Inmates Registration.
 * @author: vishal.mishra@velsof.com
 * @date :11-10-2023
 */

import {
  CAMP_INMATES_REGISTRATION_IMPORT,
  GET_ALL_CREATE_STORM_INMATES_REGISTRATION,
  GET_ALL_STORM_INMATES_REGISTRATION,
  GET_ALL_UPDATE_STORM_INMATES_REGISTRATION,
  GET_EXPORT_CAMP_INMATES_DATA,
  GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  SET_ALL_CREATE_STORM_INMATES_REGISTRATION,
  SET_ALL_STORM_INMATES_REGISTRATION,
  SET_ALL_UPDATE_STORM_INMATES_REGISTRATION,
  SET_EXPORT_CAMP_INMATES_DATA,
  SET_STORM_INMATES_REGISTRATION,
  SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
} from "redux/constants/StormInmatesRegistration";

// Import the constants you defined

// Action to fetch the Camp Inmates Registration
export const stormGetAllInmatesRegistration = (data) => {
  return {
    type: GET_ALL_STORM_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Camp Inmates Registration
export const stormSetAllInmatesRegistration = (data) => {
  return {
    type: SET_ALL_STORM_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Camp Inmates Registration
export const stormGetAllCreateInmatesRegistration = (data) => {
  return {
    type: GET_ALL_CREATE_STORM_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Camp Inmates Registration
export const stormSetAllCreateInmatesRegistration = (data) => {
  return {
    type: SET_ALL_CREATE_STORM_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Camp Inmates Registration
export const stormGetAllUpdateInmatesRegistration = (data, id) => {
  return {
    type: GET_ALL_UPDATE_STORM_INMATES_REGISTRATION,
    payload: data,
    id: id,
  };
};

// Action to fetch a new Camp Inmates Registration
export const stormSetAllUpdateInmatesRegistration = (data) => {
  return {
    type: SET_ALL_UPDATE_STORM_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for setting the Storm Inmates Registration parameters
 * @param {any} data Storm Data
 * @returns Json Object
 */
export const stormSetInmatesRegistrationTableParam = (data) => {
  return {
    type: SET_STORM_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for Camp Inmates Registration import
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const stormCampInmatesRegistrationImport = (data) => {
  return {
    type: CAMP_INMATES_REGISTRATION_IMPORT,
    payload: data,
  };
};

/**
 * Method for Non Camp Inmates Registration import excel
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const stormCampInmatesRegistrationImportExcel = (data) => {
  return {
    type: CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
    payload: data,
  };
};

/**
 * Method for getting the export file of Camp Inmates Registration
 * @returns Json Object
 */
export const stormGetExportInmatesRegistration = (data) => {
  return {
    type: GET_EXPORT_CAMP_INMATES_DATA,
    payload: data,
  };
};

/**
 * Method for setting the reliefCamp export data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const stormSetExportInmatesRegistration = (data) => {
  return {
    type: SET_EXPORT_CAMP_INMATES_DATA,
    payload: data,
  };
};
/**
 * Method for getting the import template of Camp Inmates Registration
 * @returns Json Object
 */
export const stormGetImportTemplateInmatesRegistration = (data) => {
  return {
    type: GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
    payload: data,
  };
};

/**
 * Method for setting the import template data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const stormSetImportTemplateInmatesRegistration = (data) => {
  return {
    type: SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
    payload: data,
  };
};
