/**
 * The below JS file contains all the constants of API
 * @author: neeraj.singh.rawat@velsof.com
 * @date :14-10-2022
 */

/**Constants related to URL */
// export const BASE_URL = "https://drims.veldev.com";
// export const API_BASE_URL_STAGING = "https://drims.veldev.com/api";
// export const API_BASE_URL_PRODUCTION = "https://drims.veldev.com/api";
// export const FRIMS_URL = "http://frims.veldev.com";

export const BASE_URL = "https://drimstest.veldev.com";
export const API_BASE_URL_STAGING = "https://drimstest.veldev.com/api";
export const API_BASE_URL_PRODUCTION = "https://drimstest.veldev.com/api";
export const FRIMS_URL = "http://frimstesting.veldev.com";

// export const BASE_URL = "http://localhost:1337";
// export const API_BASE_URL_STAGING = "http://localhost:1337/api";
// export const API_BASE_URL_PRODUCTION = "http://localhost:1337/api";
// export const FRIMS_URL = "https://localhost:44351";

// export const BASE_URL = "http://localhost:1339";
// export const API_BASE_URL_STAGING = "http://localhost:1339/api";
// export const API_BASE_URL_PRODUCTION = "http://localhost:1339/api";
// export const FRIMS_URL = "https://localhost:44351";

/**Constants related to Apache Superset API */
export const Superset_Base_URL = "https://drims-superset.veldev.com";
export const Superset_Login = "/api/v1/security/login";
export const Superset_Guest_Token = "/api/v1/security/guest_token/";

/**Constants related to API Status */
export const Status = "status";
export const Success = "success";
export const Failure = "failure";
export const Error = "error";

/**Constants related to API Paramters */
export const Application_Json = "application/json";
export const Authorization_Token = "Bearer {0}";
export const Multipart_Form_Data = "multipart/form-data";
export const Blob = "blob";

/**Constants related to Login */
export const Login_Url = "/auth/local";
export const Get_Login_User = "/users/me";

/**Constants related to District */
export const Get_All_District = "/districts";
export const Add_District = "/districts";
export const Edit_District = "/districts/{0}";
export const Get_District = "/districts/{0}";
export const Get_Export_District = "/districts/export";
export const Change_District_Status = "/districts/changeStatus";
export const District_Import = "/districts/import";

/**Constants related to Blocks */
export const Get_All_Block = "/blocks";
export const Add_Block = "/blocks";
export const Edit_Block = "/blocks/{0}";
export const Get_Block = "/blocks/{0}";
export const Get_Export_Block = "/blocks/export";
export const Change_Block_Status = "/blocks/changeStatus";
export const Block_Import = "/blocks/import";

/**Constant related to Village */
export const Get_All_Village = "/villages";
export const Add_Village = "/villages";
export const Edit_Village = "/villages/{0}";
export const Get_Village = "/villages/{0}";
export const Get_Export_Village = "/villages/export";
export const Change_Village_Status = "/villages/changeStatus";
export const Village_Import = "/villages/import";

/**Constant related to Relief Camp */
export const Get_All_ReliefCamp = "/relief-camps";
export const Add_ReliefCamp = "/relief-camps";
export const Edit_ReliefCamp = "/relief-camps/{0}";
export const Get_ReliefCamp = "/relief-camps/{0}";
export const Get_Export_ReliefCamp = "/relief-camps/export";
export const Change_ReliefCamp_Status = "/relief-camps/changeStatus";
export const ReliefCamp_Import = "/relief-camps/import";

/**Constant related to Infrastructure Department */
export const Get_All_InfDepartment = "/infrastructure-departments";
export const Add_InfDepartment = "/infrastructure-departments";
export const Edit_InfDepartment = "/infrastructure-departments/{0}";
export const Get_InfDepartment = "/infrastructure-departments/{0}";
export const Get_Export_InfDepartment = "/infrastructure-departments/export";
export const Change_InfDepartment_Status =
  "/infrastructure-departments/changeStatus";
export const InfDepartment_Import = "/infrastructure-departments/import";

/**Constant for the import template paths */
export const District_Import_Template =
  BASE_URL + "/data/formats/import/ImportDistrictsTemplate.csv";
export const Block_Import_Template =
  BASE_URL + "/data/formats/import/ImportBlocksTemplate.csv";
export const Village_Import_Template =
  BASE_URL + "/data/formats/import/ImportVillagesTemplate.csv";
export const ReliefCamp_Import_Template =
  BASE_URL + "/data/formats/import/ImportReliefCampsTemplate.csv";
export const InfDepartment_Import_Template =
  BASE_URL + "/data/formats/import/ImportInfrastructureDamagesTemplate.csv";
export const User_Import_Template =
  BASE_URL + "/data/formats/import/ImportUsersTemplate.csv";
export const Cwc_Import_Template =
  BASE_URL + "/data/formats/import/ImportCWCTemplate.csv";

/**Constant related to Template of Camp Inmates Registration
 * @author: vishal.mishra@velsof.com
 * @date : 11-03-2024
 */
export const Camp_Inmates_Registration_Template =
  BASE_URL + "/data/formats/import/ImportCampInmatesRegistrationTemplate.csv";
export const Non_Camp_Inmates_Registration_Template =
  BASE_URL +
  "/data/formats/import/Non_Camp_Inmates_Template_registration_V02.xlsx";

/**Constant related to Disaster page */
export const Get_Disaster_Listing = "/DisasterListing";

//#region Infrastructure Damage Report
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-11-2022

/**Constant related to Inf damage reports */
export const Get_All_InfDamage = "/inf-damage-forms";
export const Get_Export_InfDamage = "/inf-damage-forms/export";
export const InfDamage_Import = "/inf-damage-forms/import";

/**Constant for storing the API to get all section data */
export const InfDamage_Get_All_Section = "/inf-damage-forms/{0}";

/**Constants for Basic section */
export const InfDamage_Add_Basic_Section = "/inf-damages/basicSection";
export const InfDamage_Edit_Basic_Section = "/inf-damages/basicSection/{0}";

/**Constants for InfDamage section */
export const InfDamage_Add_InfDamage_Section = "/inf-damages/infDamageSection";

/**Constants for InfDamageOther section */
export const InfDamage_Add_InfDamageOther_Section =
  "/inf-damages/othersDamageSection";

/**Constants for Remark section */
export const InfDamage_Add_Remark_Section = "/inf-damages/remarks";

/**Constant for submit report */
export const InfDamage_Submit_Report = "/inf-damages/submit/{0}";
/**Constant for discard report */
export const InfDamage_Discard_Report = "/inf-damages/discard/{0}";
//#endregion Infrastructure Damage Report

/**Constant related to Infrastructure Other Damages
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-11-2022
 */
export const Get_All_InfOtherDamage = "/other-damages";
export const Add_InfOtherDamage = "/other-damages";
export const Edit_InfOtherDamage = "/other-damages/{0}";
export const Get_InfOtherDamage = "/other-damages/{0}";
export const Get_Export_InfOtherDamage = "/other-damages/export";
export const Change_InfOtherDamage_Status = "/other-damages/changeStatus";
export const InfOtherDamage_Import = "/other-damages/import";

//#region Erosion Report
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**Constant related to erosion reports */
export const Get_All_Erosion = "/erosion-forms";
export const Get_Export_Erosion = "/erosion-forms/export";
export const Erosion_Import = "/erosion-forms/import";

/**Constant for storing the API to get all section data */
export const Erosion_Get_All_Section = "/erosion-forms/{0}";

/**Constants for Basic section */
export const Erosion_Add_Basic_Section = "/erosions/basicSection";
export const Erosion_Edit_Basic_Section = "/erosions/basicSection/{0}";

/**Constants for PopAffected section */
export const Erosion_Add_PopAffected_Section = "/erosions/popAffectedSection";

/**Constants for InfDamage section */
export const Erosion_Add_InfDamage_Section = "/erosions/infDamageSection";

/**Constants for InfDamageOther section */
export const Erosion_Add_InfDamageOther_Section =
  "/erosions/othersDamageSection";

/**Constants for Remark section */
export const Erosion_Add_Remark_Section = "/erosions/remarks";

/**Constant for submit report */
export const Erosion_Submit_Report = "/erosions/submit/{0}";
/**Constant for discard report */
export const Erosion_Discard_Report = "/erosions/discard/{0}";

/**Constants for Eroded section */
export const Erosion_Add_Eroded_Section = "/erosions/erodedDetailSection";

/**Constants for Relief Camp section */
export const Erosion_Add_ReliefCamp_Section = "/erosions/reliefCampsSection";

/**Constants for Camp Inmates section */
export const Erosion_Add_CampInmates_Section = "/erosions/campInmatesSection";

/**Constants for Non Camp Inmates section */
export const Erosion_Add_NonCampInmates_Section =
  "/erosions/nonCampInmatesSection";

/**Constants for Human Live Lost section */
export const Erosion_Add_HLL_Section = "/erosions/hllSection";

/**Constants for LiveStock section */
export const Erosion_Add_LiveStock_Section = "/erosions/livestocksSection";

/**Constants for House Damaged section */
export const Erosion_Add_HouseDamaged_Section = "/erosions/houseDamagedSection";

/**Constants for Rescue Operation section */
export const Erosion_Add_RescueOperation_Section =
  "/erosions/rescueOperationsSection";

/**Constants for Relief Distribution section */
export const Erosion_Add_ReliefDistribution_Section =
  "/erosions/reliefDistributionSection";

/**
 * Added for report export url
 * @author: shivam.sharma2@velsof.com
 * @date : 12-12-2022
 */

export const Erosion_Fo_Level_Report = "/reports/erosion/exportFoLevelReport";
export const Erosion_Cumulative_State_Report =
  "/reports/erosion/exportStateCumulativeData";
export const Erosion_Cumulative_District_Report =
  "/reports/erosion/exportDistrictCumulativeData";

export const Erosion_Report_Status = "/erosion/updateStatus";
//#region For Approval and Disapproval

/**
 * Adding the constants for approve, disapprove and reviewed
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 19-12-2022
 */
/**
 * Added for the Approve & disapprove
 * @author: shivam.sharma2@velsof.com
 * @date : 10-12-2022
 */
export const Erosion_Revenue_Report_Approve =
  "/reviews/erosion/approveRevenueReport";
export const Erosion_Revenue_Report_Disapprove =
  "/reviews/erosion/rejectRevenueReport";
export const Erosion_Revenue_Report_Verify =
  "/reviews/erosion/verifyRevenueReport";

export const Erosion_District_Report_Approve =
  "/reviews/erosion/approveDistrictReport";
export const Erosion_District_Report_Disapprove =
  "/reviews/erosion/rejectDistrictReport";
export const Erosion_District_Report_Verify =
  "/reviews/erosion/verifyDistrictReport";

export const Erosion_State_Report_Approve =
  "/reviews/erosion/approveStateReport";
export const Erosion_State_Report_Disapprove =
  "/reviews/erosion/rejectStateReport";

export const Erosion_State_Freeze_Unfreeze_Report_Range =
  "/erosion-freeze-state-report/bulkFreeze";

/**Constants for district and state review list
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 15-12-2022
 */
export const Erosion_Get_District_Review_List =
  "/reviews/erosion/getDistrictReviewList";
export const Erosion_Get_State_Review_List =
  "/reviews/erosion/getStateReviewList";

/**
 * Adding the constants for Registratin of Inmates in Erosion Relief Camps
 * @author: vishal.mishra@velsof.com
 * @date : 08-03-2024
 */
export const Erosion_Create_Inmates_Registration =
  "erosion-inmates-registrations";
export const Erosion_Update_Inmates_Registration =
  "erosion-inmates-registrations/:id";
export const Erosion_Import_Inmates_Registration =
  "erosion-inmates-registrations/import";
export const Erosion_Import_Inmates_Registration_Xlsx =
  "erosion-inmates-registrations/importxlsx";
export const Erosion_Export_Inmates_Registration =
  "erosion-inmates-registrations/export";
export const Erosion_Export_Template_Inmates_Registration =
  "erosion-inmates-registrations/downloadTemplate";

/**
 * Adding the constants for Registration of Non-Inmates in Erosion Relief Distribution center
 * @author: vishal.mishra@velsof.com
 * @date : 01-04-2024
 */
export const Erosion_Create_Non_Inmates_Registration =
  "erosion-non-inmates-registrations";
export const Erosion_Update_Non_Inmates_Registration =
  "erosion-non-inmates-registrations/:id";
export const Erosion_Import_Non_Inmates_Registration =
  "erosion-non-inmates-registrations/import";
export const Erosion_Import_Non_Inmates_Registration_Xlsx =
  "erosion-non-inmates-registrations/importxlsx";
export const Erosion_Export_Non_Inmates_Registration =
  "erosion-non-inmates-registrations/export";

//#endregion For Approval and Disapproval
//#endregion Erosion Damage Report

/**Constants related to River */
export const Get_All_River = "/rivers";
export const Add_River = "/rivers";
export const Edit_River = "/rivers/{0}";
export const Get_River = "/rivers/{0}";
export const Get_Export_River = "/rivers/export";
export const Change_River_Status = "/rivers/changeStatus";
export const River_Import = "/rivers/import";

/**Constants related to Agency */
export const Get_All_Agency = "/agencies";
export const Add_Agency = "/agencies";
export const Edit_Agency = "/agencies/{0}";
export const Get_Agency = "/agencies/{0}";
export const Get_Export_Agency = "/agencies/export";
export const Change_Agency_Status = "/agencies/changeStatus";
export const Agency_Import = "/agencies/import";

/**Constants related to RescueEquipment */
export const Get_All_RescueEquipment = "/rescue-equipments";
export const Add_RescueEquipment = "/rescue-equipments";
export const Edit_RescueEquipment = "/rescue-equipments/{0}";
export const Get_RescueEquipment = "/rescue-equipments/{0}";
export const Get_Export_RescueEquipment = "/rescue-equipments/export";
export const Change_RescueEquipment_Status = "/rescue-equipments/changeStatus";
export const RescueEquipment_Import = "/rescue-equipments/import";

/**Constants related to ReliefOtherItem */
export const Get_All_ReliefOtherItem = "/relief-other-items";
export const Add_ReliefOtherItem = "/relief-other-items";
export const Edit_ReliefOtherItem = "/relief-other-items/{0}";
export const Get_ReliefOtherItem = "/relief-other-items/{0}";
export const Get_Export_ReliefOtherItem = "/relief-other-items/export";
export const Change_ReliefOtherItem_Status = "/relief-other-items/changeStatus";
export const ReliefOtherItem_Import = "/relief-other-items/import";

/**Constants related to Unit */
export const Get_All_Unit = "/units";
export const Add_Unit = "/units";
export const Edit_Unit = "/units/{0}";
export const Get_Unit = "/units/{0}";
export const Get_Export_Unit = "/units/export";
export const Change_Unit_Status = "/units/changeStatus";
export const Unit_Import = "/units/import";

//#region Landslide Report
//@author: shivam.sharma2@velsof.com
//@date : 09-12-2022

/**Constant related to landslide reports */
export const Get_All_Landslide = "/landslide-forms";
export const Get_Export_Landslide = "/landslide-forms/export";
export const Landslide_Import = "/landslide-forms/import";

/**Constant for storing the API to get all section data */
export const Landslide_Get_All_Section = "/landslide-forms/{0}";

/**Constants for Basic section */
export const Landslide_Add_Basic_Section = "/landslides/basicSection";
export const Landslide_Edit_Basic_Section = "/landslides/basicSection/{0}";

/**Constants for PopAffected section */
export const Landslide_Add_PopAffected_Section =
  "/landslides/popAffectedSection";

/**Constants for InfDamage section */
export const Landslide_Add_InfDamage_Section = "/landslides/infDamageSection";

/**Constants for InfDamageOther section */
export const Landslide_Add_InfDamageOther_Section =
  "/landslides/othersDamageSection";

/**Constants for Remark section */
export const Landslide_Add_Remark_Section = "/landslides/remarks";

/**Constant for submit report */
export const Landslide_Submit_Report = "/landslides/submit/{0}";
/**Constant for discard report */
export const Landslide_Discard_Report = "/landslides/discard/{0}";

/**Constants for Eroded section */
export const Landslide_Add_Eroded_Section = "/landslides/erodedDetailSection";

/**Constants for Relief Camp section */
export const Landslide_Add_ReliefCamp_Section =
  "/landslides/reliefCampsSection";

/**Constants for Camp Inmates section */
export const Landslide_Add_CampInmates_Section =
  "/landslides/campInmatesSection";

/**Constants for Non Camp Inmates section */
export const Landslide_Add_NonCampInmates_Section =
  "/landslides/nonCampInmatesSection";

/**Constants for Human Live Lost section */
export const Landslide_Add_HLL_Section = "/landslides/hllSection";

/**Constants for LiveStock section */
export const Landslide_Add_LiveStock_Section = "/landslides/livestocksSection";

/**Constants for House Damaged section */
export const Landslide_Add_HouseDamaged_Section =
  "/landslides/houseDamagedSection";

/**Constants for Rescue Operation section */
export const Landslide_Add_RescueOperation_Section =
  "/landslides/rescueOperationsSection";

/**Constants for Relief Distribution section */
export const Landslide_Add_ReliefDistribution_Section =
  "/landslides/reliefDistributionSection";

/**
 * Adding Reports and Approval and Disapproval Module.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
//#region Reports and Approval Disapproval Module.

//#region Reports
export const Landslide_Fo_Level_Report =
  "/reports/landslide/exportFoLevelReport";
export const Landslide_Cumulative_State_Report =
  "/reports/landslide/exportStateCumulativeData";
export const Landslide_Cumulative_District_Report =
  "/reports/landslide/exportDistrictCumulativeData";
//#endregion Reports

//#region For Approval and Disapproval

/**
 * Adding the constants for approve, disapprove and reviewed
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export const Landslide_Revenue_Report_Approve =
  "/reviews/landslide/approveRevenueReport";
export const Landslide_Revenue_Report_Disapprove =
  "/reviews/landslide/rejectRevenueReport";
export const Landslide_Revenue_Report_Verify =
  "/reviews/landslide/verifyRevenueReport";

export const Landslide_District_Report_Approve =
  "/reviews/landslide/approveDistrictReport";
export const Landslide_District_Report_Disapprove =
  "/reviews/landslide/rejectDistrictReport";
export const Landslide_District_Report_Verify =
  "/reviews/landslide/verifyDistrictReport";

export const Landslide_State_Report_Approve =
  "/reviews/landslide/approveStateReport";
export const Landslide_State_Report_Disapprove =
  "/reviews/landslide/rejectStateReport";

export const Landslide_State_Freeze_Unfreeze_Report_Range =
  "/landslide-freeze-state-report/bulkFreeze";

export const Landslide_Get_District_Review_List =
  "/reviews/landslide/getDistrictReviewList";
export const Landslide_Get_State_Review_List =
  "/reviews/landslide/getStateReviewList";

/**
 * Adding the constants for Registratin of Inmates in Landslide Relief Camps
 * @author: vishal.mishra@velsof.com
 * @date : 22-01-2025
 */
export const Landslide_Create_Inmates_Registration =
  "landslide-inmates-registrations";
export const Landslide_Update_Inmates_Registration =
  "landslide-inmates-registrations/:id";
export const Landslide_Import_Inmates_Registration =
  "landslide-inmates-registrations/import";
export const Landslide_Import_Inmates_Registration_Xlsx =
  "landslide-inmates-registrations/importxlsx";
export const Landslide_Export_Inmates_Registration =
  "landslide-inmates-registrations/export";
export const Landslide_Export_Template_Inmates_Registration =
  "landslide-inmates-registrations/downloadTemplate";

/**
 * Adding the constants for Registration of Non-Inmates in Landslide Relief Distribution center
 * @author: vishal.mishra@velsof.com
 * @date : 22-01-2025
 */
export const Landslide_Create_Non_Inmates_Registration =
  "landslide-non-inmates-registrations";
export const Landslide_Update_Non_Inmates_Registration =
  "landslide-non-inmates-registrations/:id";
export const Landslide_Import_Non_Inmates_Registration =
  "landslide-non-inmates-registrations/import";
export const Landslide_Import_Non_Inmates_Registration_Xlsx =
  "landslide-non-inmates-registrations/importxlsx";
export const Landslide_Export_Non_Inmates_Registration =
  "landslide-non-inmates-registrations/export";

//#endregion For Approval and Disapproval
//#endregion Reports and Approval Disapproval Module.

//#endregion Landslide Damage Report

//#region Urban Flood Report
/**
 * @author: shivam.sharma2@velsof.com
 * @date : 23-12-2022
 */

/**Constant related to Urban Flood reports */
export const Get_All_UrbanFlood = "/urbanflood-forms";
export const Get_Export_UrbanFlood = "/urbanflood-forms/export";
export const UrbanFlood_Import = "/urbanflood-forms/import";

/**Constant for storing the API to get all section data */
export const UrbanFlood_Get_All_Section = "/urbanflood-forms/{0}";

/**Constants for Basic section */
export const UrbanFlood_Add_Basic_Section = "/urbanfloods/basicSection";
export const UrbanFlood_Edit_Basic_Section = "/urbanfloods/basicSection/{0}";

/**Constants for PopAffected section */
export const UrbanFlood_Add_PopAffected_Section =
  "/urbanfloods/popAffectedSection";

/**Constants for InfDamage section */
export const UrbanFlood_Add_InfDamage_Section = "/urbanfloods/infDamageSection";

/**Constants for InfDamageOther section */
export const UrbanFlood_Add_InfDamageOther_Section =
  "/urbanfloods/othersDamageSection";

/**Constants for Remark section */
export const UrbanFlood_Add_Remark_Section = "/urbanfloods/remarks";

/**Constant for submit report */
export const UrbanFlood_Submit_Report = "/urbanfloods/submit/{0}";
/**Constant for discard report */
export const UrbanFlood_Discard_Report = "/urbanfloods/discard/{0}";

//#region
/**
 * Added for the Approve & disapprove
 * @author: shivam.sharma2@velsof.com
 */

export const UrbanFlood_Report_Status = "/urbanfloods/updateStatus";

//#endregion

/**Constants for Eroded section */
export const UrbanFlood_Add_Eroded_Section = "/urbanfloods/erodedDetailSection";

/**Constants for Relief Camp section */
export const UrbanFlood_Add_ReliefCamp_Section =
  "/urbanfloods/reliefCampsSection";

/**Constants for Camp Inmates section */
export const UrbanFlood_Add_CampInmates_Section =
  "/urbanfloods/campInmatesSection";

/**Constants for Non Camp Inmates section */
export const UrbanFlood_Add_NonCampInmates_Section =
  "/urbanfloods/nonCampInmatesSection";

/**Constants for Human Live Lost section */
export const UrbanFlood_Add_HLL_Section = "/urbanfloods/hllSection";

/**Constants for LiveStock section */
export const UrbanFlood_Add_LiveStock_Section =
  "/urbanfloods/livestocksSection";

/**Constants for House Damaged section */
export const UrbanFlood_Add_HouseDamaged_Section =
  "/urbanfloods/houseDamagedSection";

/**Constants for Rescue Operation section */
export const UrbanFlood_Add_RescueOperation_Section =
  "/urbanfloods/rescueOperationsSection";

/**Constants for Relief Distribution section */
export const UrbanFlood_Add_ReliefDistribution_Section =
  "/urbanfloods/reliefDistributionSection";

/**
 * Adding Reports and Approval and Disapproval Module.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 */
//#region Reports and Approval Disapproval Module.

//#region Reports
export const UrbanFlood_Fo_Level_Report =
  "/reports/urbanflood/exportFoLevelReport";
export const UrbanFlood_Cumulative_State_Report =
  "/reports/urbanflood/exportStateCumulativeData";
export const UrbanFlood_Cumulative_District_Report =
  "/reports/urbanflood/exportDistrictCumulativeData";
//#endregion Reports

//#region For Approval and Disapproval

/**
 * Adding the constants for approve, disapprove and reviewed
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-01-2023
 */
export const UrbanFlood_Revenue_Report_Approve =
  "/reviews/urbanflood/approveRevenueReport";
export const UrbanFlood_Revenue_Report_Disapprove =
  "/reviews/urbanflood/rejectRevenueReport";
export const UrbanFlood_Revenue_Report_Verify =
  "/reviews/urbanflood/verifyRevenueReport";

export const UrbanFlood_District_Report_Approve =
  "/reviews/urbanflood/approveDistrictReport";
export const UrbanFlood_District_Report_Disapprove =
  "/reviews/urbanflood/rejectDistrictReport";
export const UrbanFlood_District_Report_Verify =
  "/reviews/urbanflood/verifyDistrictReport";

export const UrbanFlood_State_Report_Approve =
  "/reviews/urbanflood/approveStateReport";
export const UrbanFlood_State_Report_Disapprove =
  "/reviews/urbanflood/rejectStateReport";

export const UrbanFlood_State_Freeze_Unfreeze_Report_Range =
  "/urbanflood-freeze-state-report/bulkFreeze";

export const UrbanFlood_Get_District_Review_List =
  "/reviews/urbanflood/getDistrictReviewList";
export const UrbanFlood_Get_State_Review_List =
  "/reviews/urbanflood/getStateReviewList";

/**
 * Adding the constants for Registratin of Inmates in UrbanFlood Relief Camps
 * @author: vishal.mishra@velsof.com
 * @date : 10-02-2025
 */
export const UrbanFlood_Create_Inmates_Registration =
  "urbanflood-inmates-registrations";
export const UrbanFlood_Update_Inmates_Registration =
  "urbanflood-inmates-registrations/:id";
export const UrbanFlood_Import_Inmates_Registration =
  "urbanflood-inmates-registrations/import";
export const UrbanFlood_Import_Inmates_Registration_Xlsx =
  "urbanflood-inmates-registrations/importxlsx";
export const UrbanFlood_Export_Inmates_Registration =
  "urbanflood-inmates-registrations/export";
export const UrbanFlood_Export_Template_Inmates_Registration =
  "urbanflood-inmates-registrations/downloadTemplate";

/**
 * Adding the constants for Registration of Non-Inmates in UrbanFlood Relief Distribution center
 * @author: vishal.mishra@velsof.com
 * @date : 06-02-2025
 */
export const UrbanFlood_Create_Non_Inmates_Registration =
  "urbanflood-non-inmates-registrations";
export const UrbanFlood_Update_Non_Inmates_Registration =
  "urbanflood-non-inmates-registrations/:id";
export const UrbanFlood_Import_Non_Inmates_Registration =
  "urbanflood-non-inmates-registrations/import";
export const UrbanFlood_Import_Non_Inmates_Registration_Xlsx =
  "urbanflood-non-inmates-registrations/importxlsx";
export const UrbanFlood_Export_Non_Inmates_Registration =
  "urbanflood-non-inmates-registrations/export";
//#endregion For Approval and Disapproval

//#endregion Urban Flood Damage Report

//#region
/**Constant related to wards */
export const Get_All_Wards = "/wards";
//#endregion
//#region Storm Report
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

//#region Flood Report
/**
 * @author: dhruv.kumar@velsof.com
 * @date : 21-07-2022
 */

/**Constant related to Urban Flood reports */
export const Get_All_Flood = "/flood-forms";
export const Get_Export_Flood = "/flood-forms/export";
export const Flood_Import = "/flood-forms/import";

/**Constant for storing the API to get all section data */
export const Flood_Get_All_Section = "/flood-forms/{0}";

/**Constants for Basic section */
export const Flood_Add_Basic_Section = "/floods/basicSection";
export const Flood_Edit_Basic_Section = "/floods/basicSection/{0}";

/**Constants for PopAffected section */
export const Flood_Add_PopAffected_Section = "/floods/popAffectedSection";

/**Constants for InfDamage section */
export const Flood_Add_InfDamage_Section = "/floods/infDamageSection";

/**Constants for InfDamageOther section */
export const Flood_Add_InfDamageOther_Section = "/floods/othersDamageSection";

/**Constants for Remark section */
export const Flood_Add_Remark_Section = "/floods/remarks";

/**Constant for submit report */
export const Flood_Submit_Report = "/floods/submit/{0}";
/**Constant for discard report */
export const Flood_Discard_Report = "/floods/discard/{0}";

//#region
/**
 * Added for the Approve & disapprove
 * @author: dhruv.kumar@velsof.com
 */

export const Flood_Report_Status = "/floods/updateStatus";

//#endregion

/**Constants for Eroded section */
export const Flood_Add_Eroded_Section = "/floods/erodedDetailSection";

/**Constants for Relief Camp section */
export const Flood_Add_ReliefCamp_Section = "/floods/reliefCampsSection";

/**Constants for Camp Inmates section */
export const Flood_Add_CampInmates_Section = "/floods/campInmatesSection";

/**Constants for Camp Inmates section */
export const Flood_Add_NonCampInmates_Section = "/floods/nonCampInmatesSection";

/**Constants for Human Live Lost section */
export const Flood_Add_HLL_Section = "/floods/hllSection";

/**Constants for Human Live Lost section cause of death */
export const Get_All_Flood_Death_Cause = "/flood-cause-of-deaths";

/**Constants for LiveStock section */
export const Flood_Add_LiveStock_Section = "/floods/livestocksSection";

/**Constants for House Damaged section */
export const Flood_Add_HouseDamaged_Section = "/floods/houseDamagedSection";

/**Constants for Rescue Operation section */
export const Flood_Add_RescueOperation_Section =
  "/floods/rescueOperationsSection";

/**Constants for Relief Distribution section */
export const Flood_Add_ReliefDistribution_Section =
  "/floods/reliefDistributionSection";

/**
 * Adding Reports and Approval and Disapproval Module.
 * @author: dhruv.kumar@velsof.com
 * @date : 21-07-2023
 */
//#region Reports and Approval Disapproval Module.

//#region Reports
export const Flood_Fo_Level_Report = "/reports/flood/exportFoLevelReport";
export const Flood_Cumulative_State_Report =
  "/reports/flood/exportStateCumulativeData";
export const Flood_Cumulative_District_Report =
  "/reports/flood/exportDistrictCumulativeData";
//#endregion Reports

//#region For Approval and Disapproval

/**
 * Adding the constants for approve, disapprove and reviewed
 * @author: dhruv.kumar@velsof.com
 * @date : 21-07-2023
 */
export const Flood_Revenue_Report_Approve =
  "/reviews/flood/approveRevenueReport";
export const Flood_Revenue_Report_Disapprove =
  "/reviews/flood/rejectRevenueReport";
export const Flood_Revenue_Report_Verify = "/reviews/flood/verifyRevenueReport";

export const Flood_District_Report_Approve =
  "/reviews/flood/approveDistrictReport";
export const Flood_District_Report_Disapprove =
  "/reviews/flood/rejectDistrictReport";
export const Flood_District_Report_Verify =
  "/reviews/flood/verifyDistrictReport";

export const Flood_State_Report_Approve = "/reviews/flood/approveStateReport";
export const Flood_State_Report_Disapprove = "/reviews/flood/rejectStateReport";
export const Flood_State_Freeze_Unfreeze_Report_Range =
  "/flood-freeze-state-report/bulkFreeze";

export const Flood_Get_District_Review_List =
  "/reviews/flood/getDistrictReviewList";
export const Flood_Get_State_Review_List = "/reviews/flood/getStateReviewList";

/**
 * Adding the constants for freezing and unfreezing flood state report
 * @author: vishal.mishra@velsof.com
 * @date : 10-10-2023
 */
export const Flood_State_Report_Create_Freeze = "flood-freeze-state-reports";
export const Flood_State_Report_Update_Freeze =
  "flood-freeze-state-reports/:id";

/**
 * Adding the constants for Registratin of Inmates in Flood Relief Camps
 * @author: vishal.mishra@velsof.com
 * @date : 08-03-2024
 */
export const Flood_Create_Inmates_Registration = "inmates-registrations";
export const Flood_Update_Inmates_Registration = "inmates-registrations/:id";
export const Flood_Import_Inmates_Registration = "inmates-registrations/import";
export const Flood_Import_Inmates_Registration_Xlsx = "inmates-registrations/importxlsx";
export const Flood_Export_Inmates_Registration = "inmates-registrations/export";
export const Flood_Export_Template_Inmates_Registration =
  "inmates-registrations/downloadTemplate";

/**
 * Adding the constants for Registration of Non-Inmates in Flood Relief Distribution center
 * @author: vishal.mishra@velsof.com
 * @date : 01-04-2024
 */
export const Flood_Create_Non_Inmates_Registration =
  "non-inmates-registrations";
export const Flood_Update_Non_Inmates_Registration =
  "non-inmates-registrations/:id";
export const Flood_Import_Non_Inmates_Registration =
  "non-inmates-registrations/import";
export const Flood_Import_Non_Inmates_Registration_Xlsx = "non-inmates-registrations/importxlsx";
export const Flood_Export_Non_Inmates_Registration =
  "non-inmates-registrations/export";

//#endregion For Approval and Disapproval
//#endregion Flood Damage Report

/**Constant related to storm reports */
export const Get_All_Storm = "/storm-forms";
export const Get_Export_Storm = "/storm-forms/export";
export const Storm_Import = "/storm-forms/import";

/**Constant for storing the API to get all section data */
export const Storm_Get_All_Section = "/storm-forms/{0}";

/**Constants for Basic section */
export const Storm_Add_Basic_Section = "/storms/basicSection";
export const Storm_Edit_Basic_Section = "/storms/basicSection/{0}";

/**Constants for PopAffected section */
export const Storm_Add_PopAffected_Section = "/storms/popAffectedSection";

/**Constants for InfDamage section */
export const Storm_Add_InfDamage_Section = "/storms/infDamageSection";

/**Constants for InfDamageOther section */
export const Storm_Add_InfDamageOther_Section = "/storms/othersDamageSection";

/**Constants for Remark section */
export const Storm_Add_Remark_Section = "/storms/remarks";

/**Constant for submit report */
export const Storm_Submit_Report = "/storms/submit/{0}";
/**Constant for discard report */
export const Storm_Discard_Report = "/storms/discard/{0}";

/**Constants for Eroded section */
export const Storm_Add_Eroded_Section = "/storms/erodedDetailSection";

/**Constants for Relief Camp section */
export const Storm_Add_ReliefCamp_Section = "/storms/reliefCampsSection";

/**Constants for Camp Inmates section */
export const Storm_Add_CampInmates_Section = "/storms/campInmatesSection";

/**Constants for Non Camp Inmates section */
export const Storm_Add_NonCampInmates_Section = "/storms/nonCampInmatesSection";

/**Constants for Human Live Lost section */
export const Storm_Add_HLL_Section = "/storms/hllSection";

/**Constants for LiveStock section */
export const Storm_Add_LiveStock_Section = "/storms/livestocksSection";

/**Constants for House Damaged section */
export const Storm_Add_HouseDamaged_Section = "/storms/houseDamagedSection";

/**Constants for Rescue Operation section */
export const Storm_Add_RescueOperation_Section =
  "/storms/rescueOperationsSection";

/**Constants for Relief Distribution section */
export const Storm_Add_ReliefDistribution_Section =
  "/storms/reliefDistributionSection";

/**
 * Adding Reports and Approval and Disapproval Module.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
//#region Reports and Approval Disapproval Module.

//#region Reports
export const Storm_Fo_Level_Report = "/reports/storm/exportFoLevelReport";
export const Storm_Cumulative_State_Report =
  "/reports/storm/exportStateCumulativeData";
export const Storm_Cumulative_District_Report =
  "/reports/storm/exportDistrictCumulativeData";
//#endregion Reports

//#region For Approval and Disapproval

/**
 * Adding the constants for approve, disapprove and reviewed
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export const Storm_Revenue_Report_Approve =
  "/reviews/storm/approveRevenueReport";
export const Storm_Revenue_Report_Disapprove =
  "/reviews/storm/rejectRevenueReport";
export const Storm_Revenue_Report_Verify = "/reviews/storm/verifyRevenueReport";

export const Storm_District_Report_Approve =
  "/reviews/storm/approveDistrictReport";
export const Storm_District_Report_Disapprove =
  "/reviews/storm/rejectDistrictReport";
export const Storm_District_Report_Verify =
  "/reviews/storm/verifyDistrictReport";

export const Storm_State_Report_Approve = "/reviews/storm/approveStateReport";
export const Storm_State_Report_Disapprove = "/reviews/storm/rejectStateReport";

export const Storm_State_Freeze_Unfreeze_Report_Range =
  "/storms-freeze-state-report/bulkFreeze";

export const Storm_Get_District_Review_List =
  "/reviews/storm/getDistrictReviewList";
export const Storm_Get_State_Review_List = "/reviews/storm/getStateReviewList";

/**
 * Adding the constants for Registratin of Inmates in Storm Relief Camps
 * @author: vishal.mishra@velsof.com
 * @date : 30-01-2025
 */
export const Storm_Create_Inmates_Registration = "storm-inmates-registrations";
export const Storm_Update_Inmates_Registration =
  "storm-inmates-registrations/:id";
export const Storm_Import_Inmates_Registration =
  "storm-inmates-registrations/import";
export const Storm_Import_Inmates_Registration_Xlsx =
  "storm-inmates-registrations/importxlsx";
export const Storm_Export_Inmates_Registration =
  "storm-inmates-registrations/export";
export const Storm_Export_Template_Inmates_Registration =
  "storm-inmates-registrations/downloadTemplate";

/**
 * Adding the constants for Registration of Non-Inmates in Storm Relief Distribution center
 * @author: vishal.mishra@velsof.com
 * @date : 30-01-2025
 */
export const Storm_Create_Non_Inmates_Registration =
  "storm-non-inmates-registrations";
export const Storm_Update_Non_Inmates_Registration =
  "storm-non-inmates-registrations/:id";
export const Storm_Import_Non_Inmates_Registration =
  "storm-non-inmates-registrations/import";
export const Storm_Import_Non_Inmates_Registration_Xlsx =
  "storm-non-inmates-registrations/importxlsx";
export const Storm_Export_Non_Inmates_Registration =
  "storm-non-inmates-registrations/export";
//#endregion For Approval and Disapproval
//#endregion Reports and Approval Disapproval Module.

//#endregion Storm Damage Report

//#region Lightning Report
//@author: neeraj.singh.rawat@velsof.com
//@date : 26-12-2022

/**Constant related to storm reports */
export const Get_All_Lightning = "/lightning-forms";
export const Get_Export_Lightning = "/lightning-forms/export";
export const Lightning_Import = "/lightning-forms/import";

/**Constant for storing the API to get all section data */
export const Lightning_Get_All_Section = "/lightning-forms/{0}";

/**Constants for Basic section */
export const Lightning_Add_Basic_Section = "/lightnings/basicSection";
export const Lightning_Edit_Basic_Section = "/lightnings/basicSection/{0}";

/**Constants for PopAffected section */
export const Lightning_Add_PopAffected_Section =
  "/lightnings/popAffectedSection";

/**Constants for InfDamage section */
export const Lightning_Add_InfDamage_Section = "/lightnings/infDamageSection";

/**Constants for InfDamageOther section */
export const Lightning_Add_InfDamageOther_Section =
  "/lightnings/othersDamageSection";

/**Constants for Remark section */
export const Lightning_Add_Remark_Section = "/lightnings/remarks";

/**Constant for submit report */
export const Lightning_Submit_Report = "/lightnings/submit/{0}";
/**Constant for discard report */
export const Lightning_Discard_Report = "/lightnings/discard/{0}";

/**Constants for Eroded section */
export const Lightning_Add_Eroded_Section = "/lightnings/erodedDetailSection";

/**Constants for Relief Camp section */
export const Lightning_Add_ReliefCamp_Section =
  "/lightnings/reliefCampsSection";

/**Constants for Camp Inmates section */
export const Lightning_Add_CampInmates_Section =
  "/lightnings/campInmatesSection";

/**Constants for Non Camp Inmates section */
export const Lightning_Add_NonCampInmates_Section =
  "/lightnings/nonCampInmatesSection";

/**Constants for Human Live Lost section */
export const Lightning_Add_HLL_Section = "/lightnings/hllSection";

/**Constants for LiveStock section */
export const Lightning_Add_LiveStock_Section = "/lightnings/livestocksSection";

/**Constants for House Damaged section */
export const Lightning_Add_HouseDamaged_Section =
  "/lightnings/houseDamagedSection";

/**Constants for Rescue Operation section */
export const Lightning_Add_RescueOperation_Section =
  "/lightnings/rescueOperationsSection";

/**Constants for Relief Distribution section */
export const Lightning_Add_ReliefDistribution_Section =
  "/lightnings/reliefDistributionSection";

/**
 * Adding Reports and Approval and Disapproval Module.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
//#region Reports and Approval Disapproval Module.

//#region Reports
export const Lightning_Fo_Level_Report =
  "/reports/lightning/exportFoLevelReport";
export const Lightning_Cumulative_State_Report =
  "/reports/lightning/exportStateCumulativeData";
export const Lightning_Cumulative_District_Report =
  "/reports/lightning/exportDistrictCumulativeData";
//#endregion Reports

//#region For Approval and Disapproval

/**
 * Adding the constants for approve, disapprove and reviewed
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export const Lightning_Revenue_Report_Approve =
  "/reviews/lightning/approveRevenueReport";
export const Lightning_Revenue_Report_Disapprove =
  "/reviews/lightning/rejectRevenueReport";
export const Lightning_Revenue_Report_Verify =
  "/reviews/lightning/verifyRevenueReport";

export const Lightning_District_Report_Approve =
  "/reviews/lightning/approveDistrictReport";
export const Lightning_District_Report_Disapprove =
  "/reviews/lightning/rejectDistrictReport";
export const Lightning_District_Report_Verify =
  "/reviews/lightning/verifyDistrictReport";

export const Lightning_State_Report_Approve =
  "/reviews/lightning/approveStateReport";
export const Lightning_State_Report_Disapprove =
  "/reviews/lightning/rejectStateReport";

export const Lightning_State_Freeze_Unfreeze_Report_Range =
  "/lightning-freeze-state-report/bulkFreeze";

export const Lightning_Get_District_Review_List =
  "/reviews/lightning/getDistrictReviewList";
export const Lightning_Get_State_Review_List =
  "/reviews/lightning/getStateReviewList";

/**
 * Adding the constants for Registratin of Inmates in Lightning Relief Camps
 * @author: vishal.mishra@velsof.com
 * @date : 29-01-2025
 */
export const Lightning_Create_Inmates_Registration =
  "lightning-inmates-registrations";
export const Lightning_Update_Inmates_Registration =
  "lightning-inmates-registrations/:id";
export const Lightning_Import_Inmates_Registration =
  "lightning-inmates-registrations/import";
export const Lightning_Import_Inmates_Registration_Xlsx =
  "lightning-inmates-registrations/importxlsx";
export const Lightning_Export_Inmates_Registration =
  "lightning-inmates-registrations/export";
export const Lightning_Export_Template_Inmates_Registration =
  "lightning-inmates-registrations/downloadTemplate";

/**
 * Adding the constants for Registration of Non-Inmates in Lightning Relief Distribution center
 * @author: vishal.mishra@velsof.com
 * @date : 29-01-2025
 */
export const Lightning_Create_Non_Inmates_Registration =
  "lightning-non-inmates-registrations";
export const Lightning_Update_Non_Inmates_Registration =
  "lightning-non-inmates-registrations/:id";
export const Lightning_Import_Non_Inmates_Registration =
  "lightning-non-inmates-registrations/import";
export const Lightning_Import_Non_Inmates_Registration_Xlsx =
  "lightning-non-inmates-registrations/importxlsx";
export const Lightning_Export_Non_Inmates_Registration =
  "lightning-non-inmates-registrations/export";

//#endregion For Approval and Disapproval
//#endregion Lightning Damage Report

//#region Earthquake Report
//@author: shivam.sharma2@velsof.com
//@date : 19-12-2022

/**Constant related to earthquake reports */
export const Get_All_Earthquake = "/earthquake-forms";
export const Get_Export_Earthquake = "/earthquake-forms/export";
export const Earthquake_Import = "/earthquake-forms/import";

/**Constant for storing the API to get all section data */
export const Earthquake_Get_All_Section = "/earthquake-forms/{0}";

/**Constants for Basic section */
export const Earthquake_Add_Basic_Section = "/earthquakes/basicSection";
export const Earthquake_Edit_Basic_Section = "/earthquakes/basicSection/{0}";

/**Constants for PopAffected section */
export const Earthquake_Add_PopAffected_Section =
  "/earthquakes/popAffectedSection";

/**Constants for InfDamage section */
export const Earthquake_Add_InfDamage_Section = "/earthquakes/infDamageSection";

/**Constants for InfDamageOther section */
export const Earthquake_Add_InfDamageOther_Section =
  "/earthquakes/othersDamageSection";

/**Constants for Remark section */
export const Earthquake_Add_Remark_Section = "/earthquakes/remarks";

/**Constant for submit report */
export const Earthquake_Submit_Report = "/earthquakes/submit/{0}";
/**Constant for discard report */
export const Earthquake_Discard_Report = "/earthquakes/discard/{0}";

/**Constants for Eroded section */
export const Earthquake_Add_Eroded_Section = "/earthquakes/erodedDetailSection";

/**Constants for Relief Camp section */
export const Earthquake_Add_ReliefCamp_Section =
  "/earthquakes/reliefCampsSection";

/**Constants for Camp Inmates section */
export const Earthquake_Add_CampInmates_Section =
  "/earthquakes/campInmatesSection";

/**Constants for Non Camp Inmates section */
export const Earthquake_Add_NonCampInmates_Section =
  "/earthquakes/nonCampInmatesSection";

/**Constants for Human Live Lost section */
export const Earthquake_Add_HLL_Section = "/earthquakes/hllSection";

/**Constants for LiveStock section */
export const Earthquake_Add_LiveStock_Section =
  "/earthquakes/livestocksSection";

/**Constants for House Damaged section */
export const Earthquake_Add_HouseDamaged_Section =
  "/earthquakes/houseDamagedSection";

/**Constants for Rescue Operation section */
export const Earthquake_Add_RescueOperation_Section =
  "/earthquakes/rescueOperationsSection";

/**Constants for Relief Distribution section */
export const Earthquake_Add_ReliefDistribution_Section =
  "/earthquakes/reliefDistributionSection";

/**
 * Adding Reports and Approval and Disapproval Module.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
//#region Reports and Approval Disapproval Module.

//#region Reports
export const Earthquake_Fo_Level_Report =
  "/reports/earthquake/exportFoLevelReport";
export const Earthquake_Cumulative_State_Report =
  "/reports/earthquake/exportStateCumulativeData";
export const Earthquake_Cumulative_District_Report =
  "/reports/earthquake/exportDistrictCumulativeData";
//#endregion Reports

//#region For Approval and Disapproval

/**
 * Adding the constants for approve, disapprove and reviewed
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export const Earthquake_Revenue_Report_Approve =
  "/reviews/earthquake/approveRevenueReport";
export const Earthquake_Revenue_Report_Disapprove =
  "/reviews/earthquake/rejectRevenueReport";
export const Earthquake_Revenue_Report_Verify =
  "/reviews/earthquake/verifyRevenueReport";

export const Earthquake_District_Report_Approve =
  "/reviews/earthquake/approveDistrictReport";
export const Earthquake_District_Report_Disapprove =
  "/reviews/earthquake/rejectDistrictReport";
export const Earthquake_District_Report_Verify =
  "/reviews/earthquake/verifyDistrictReport";

export const Earthquake_State_Report_Approve =
  "/reviews/earthquake/approveStateReport";
export const Earthquake_State_Report_Disapprove =
  "/reviews/earthquake/rejectStateReport";

export const Earthquake_State_Freeze_Unfreeze_Report_Range =
  "/earthquake-freeze-state-report/bulkFreeze";

export const Earthquake_Get_District_Review_List =
  "/reviews/earthquake/getDistrictReviewList";
export const Earthquake_Get_State_Review_List =
  "/reviews/earthquake/getStateReviewList";

/**
 * Adding the constants for Registratin of Inmates in Earthquake Relief Camps
 * @author: vishal.mishra@velsof.com
 * @date : 06-02-2025
 */
export const Earthquake_Create_Inmates_Registration =
  "earthquake-inmates-registrations";
export const Earthquake_Update_Inmates_Registration =
  "earthquake-inmates-registrations/:id";
export const Earthquake_Import_Inmates_Registration =
  "earthquake-inmates-registrations/import";
export const Earthquake_Import_Inmates_Registration_Xlsx =
  "earthquake-inmates-registrations/importxlsx";
export const Earthquake_Export_Inmates_Registration =
  "earthquake-inmates-registrations/export";
export const Earthquake_Export_Template_Inmates_Registration =
  "earthquake-inmates-registrations/downloadTemplate";

/**
 * Adding the constants for Registration of Non-Inmates in Earthquake Relief Distribution center
 * @author: vishal.mishra@velsof.com
 * @date : 06-02-2025
 */
export const Earthquake_Create_Non_Inmates_Registration =
  "earthquake-non-inmates-registrations";
export const Earthquake_Update_Non_Inmates_Registration =
  "earthquake-non-inmates-registrations/:id";
export const Earthquake_Import_Non_Inmates_Registration =
  "earthquake-non-inmates-registrations/import";
export const Earthquake_Import_Non_Inmates_Registration_Xlsx =
  "earthquake-non-inmates-registrations/importxlsx";
export const Earthquake_Export_Non_Inmates_Registration =
  "earthquake-non-inmates-registrations/export";
//#endregion For Approval and Disapproval
//#endregion Earthquake Damage Report

//#region Fire Report
//@author: shivam.sharma2@velsof.com
//@date : 02-01-2023

/**Constant related to fire reports */
export const Get_All_Fire = "/fire-forms";
export const Get_Export_Fire = "/fire-forms/export";
export const Fire_Import = "/fire-forms/import";

/**Constant for storing the API to get all section data */
export const Fire_Get_All_Section = "/fire-forms/{0}";

/**Constants for Basic section */
export const Fire_Add_Basic_Section = "/fires/basicSection";
export const Fire_Edit_Basic_Section = "/fires/basicSection/{0}";

/**Constants for PopAffected section */
export const Fire_Add_PopAffected_Section = "/fires/popAffectedSection";

/**Constants for InfDamage section */
export const Fire_Add_InfDamage_Section = "/fires/infDamageSection";

/**Constants for InfDamageOther section */
export const Fire_Add_InfDamageOther_Section = "/fires/othersDamageSection";

/**Constants for Remark section */
export const Fire_Add_Remark_Section = "/fires/remarks";

/**Constant for submit report */
export const Fire_Submit_Report = "/fires/submit/{0}";
/**Constant for discard report */
export const Fire_Discard_Report = "/fires/discard/{0}";

/**Constants for Eroded section */
export const Fire_Add_Eroded_Section = "/fires/erodedDetailSection";

/**Constants for Relief Camp section */
export const Fire_Add_ReliefCamp_Section = "/fires/reliefCampsSection";

/**Constants for Camp Inmates section */
export const Fire_Add_CampInmates_Section = "/fires/campInmatesSection";

/**Constants for Human Live Lost section */
export const Fire_Add_HLL_Section = "/fires/hllSection";

/**Constants for Emergency Service Personnel Life section */
export const Fire_Add_ESPL_Section = "/fires/esplSection";

/**Constants for LiveStock section */
export const Fire_Add_LiveStock_Section = "/fires/livestocksSection";

/**Constants for House Damaged section */
export const Fire_Add_HouseDamaged_Section = "/fires/houseDamagedSection";

/**Constants for Property Damaged section */
export const Fire_Add_PropertyDamaged_Section = "/fires/propertyDamagedSection";

/**Constants for Caller Details section */
export const Fire_Add_Caller_Details_Section = "/fires/callerDetailsSection";

/**Constants for Turn Out Details section */
export const Fire_Add_Turn_Out_Details_Section = "/fires/turnOutDetailsSection";

/**Constants for Fire Details section */
export const Fire_Add_Fire_Details_Section = "/fires/fireDetailsSection";

/**Constants for Property Involved section */
export const Fire_Add_Property_Involved_Section =
  "/fires/propertyGuttedSection";

/**Constants for Owner of Property section */
export const Fire_Add_Owner_of_Property_Section =
  "/fires/propOwnerDetailsSection";

/**Constants for Rescue Operation section */
export const Fire_Add_RescueOperation_Section =
  "/fires/rescueOperationsSection";

/**Constants for Relief Distribution section */
export const Fire_Add_ReliefDistribution_Section =
  "/fires/reliefDistributionSection";

/**
 * Added for delete the incident images
 * @author: shivam.sharma2@velsof.com
 * @date : 17-01-2023
 */
/**Constant for deleting the image from the remark section */
export const Fire_Delete_Incident_Photo = "/upload/files/{0}";

/**
 * Added for report export url
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */

export const Fire_Fs_Level_Report = "/reports/fire/exportReport";

//#region For Approval and Disapproval

/**
 * Adding the constants for approve, disapprove and reviewed
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
/**
 * Added for the Approve & disapprove
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */

export const Fire_HQCR_Report_Approve = "/reviews/fire/approveByHqcr";
export const Fire_HQCR_Report_Disapprove = "/reviews/fire/rejectByHqcr";

/**Constants for district and state review list
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
export const Fire_Get_District_Review_List =
  "/reviews/fire/getDistrictReviewList";
export const Fire_Get_State_Review_List = "/reviews/fire/getStateReviewList";

//#endregion For Approval and Disapproval
//#endregion Fire Damage Report

/**Constant related to Fire Category */
export const Get_All_Fire_Category = "/fire-categories";

/**Constant related to Fire Cause */
export const Get_All_Fire_Cause = "/fire-causes";

/**Constant related to Fire Class */
export const Get_All_Fire_Class = "/fire-classes";

/**Constant related to Special Call Type */
export const Get_All_Special_Call_Type = "/special-call-types";

/**Constant related to Origin Area */
export const Get_All_Origin_Area = "/fire-origin-areas";

/**Constant related to Fire Detection */
export const Get_All_Fire_Detection = "/fire-detections";

/**Constant related to Fire Detection */
export const Get_All_Fire_Agency = "/fire-agencies";

/**Constant related to Types of Occupancy */
export const Get_All_Types_of_Occupancy = "/occupancy-types";

/**Constant related to Vehicle Categories */
export const Get_All_Vehicle_Categories = "/vehicle-categories";

/**Constant related to Station */
export const Get_All_Station = "/stations";

/**Constant related to Station */
export const Get_All_Livestock_Types = "/livestock-types";
//#region User management
/**Constant related to User */
export const Get_All_User = "/users";
export const Add_User = "/users";
export const Edit_User = "/users/{0}";
export const Get_User = "/users/{0}";
export const Get_Export_User = "/users/export";
export const Change_User_Status = "/users/changeStatus";
export const User_Import = "/users/import";
export const Reset_Password = "/users/{0}";
export const Change_Password = "/auth/change-password";
//#endregion

//#region Notification
export const Notification = "/notifications";
export const NotificationUpdate = "/notifications/{0}";
export const Notification_Mark_All_Read = "/notifications/markReadAll";
//#endregion

//#region
/**
 * For wing level in fire form
 * @author: shivam.sharma2@velsof.com
 * @date : 30-01-2023
 */
export const Fire_Wing_Report_Approve = "/reviews/fire/approveByWing";
export const Fire_Wing_Report_Reject = "/reviews/fire/rejectByWing";
/**
 * For SPFE level in fire form
 * @author: shivam.sharma2@velsof.com
 * @date : 30-01-2023
 */
export const Fire_Spfe_Report_Approve = "/reviews/fire/approveBySpfe";
export const Fire_Spfe_Report_Reject = "/reviews/fire/rejectBySpfe";
//#endregion

//#region
/**distri
 * For DFES level in fire form
 * @author: shivam.sharma2@velsof.com
 * @date : 30-01-2023
 */
export const Fire_Dfes_Report_Approve = "/reviews/fire/approveByDfes";
export const Fire_Dfes_Report_Reject = "/reviews/fire/rejectByDfes";
//#endregion

/**Constants related to Role */
export const Get_All_Role = "/users-permissions/roles";
export const Add_Role = "/users-permissions/roles";
export const Edit_Role = "/users-permissions/roles/{0}";
export const Get_Role = "/users-permissions/roles/{0}";
export const Get_Export_Role = "/users-roles/export";
export const Get_All_Permission = "/users-permissions/permissions";
export const Get_All_Roles_Id = "/users-roles/getUserRoleIdList";

/**Constants related to CWC */
export const Get_All_CWC = "/cwc-details";
export const CWC_Import = "/cwc-details/import";

/** Constant related to Resource Group */
export const Get_All_ResourceGroup = "/resource-groups";
export const Add_ResourceGroup = "/resource-groups";
export const Edit_ResourceGroup = "/resource-groups/{0}";
export const Get_ResourceGroup = "/resource-groups/{0}";

/** Constant related to Resource Document */
export const Get_All_ResourceDocument = "/resource-documents";
export const Add_ResourceDocument = "/resource-documents";
export const Edit_ResourceDocument = "/resource-documents/{0}";
export const Get_ResourceDocument = "/resource-documents/{0}";
