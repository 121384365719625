/**
 * The below file contains the Sagas method for Lightning reports.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 26-12-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  BasicSection,
  DiscardButton,
  InfDamageOtherSection,
  InfDamageSection,
  PopAffectedSection,
  ErodedSection,
  RemarkSection,
  SubmitButton,
  approveButton,
  disapproveButton,
  ReliefCampSection,
  CampInmatesSection,
  HllSection,
  LiveStockSection,
  HouseDamagedSection,
  RescueOperationSection,
  ReliefDistributionSection,
  verifyButton,
} from "constants/ApplicationConstant";
import {
  Lightning_Added_Basic_Section,
  Lightning_Added_CampInmates_Section,
  Lightning_Added_Eroded_Section,
  Lightning_Added_HLL_Section,
  Lightning_Added_HouseDamaged_Section,
  Lightning_Added_InfDamageOther_Section,
  Lightning_Added_InfDamage_Section,
  Lightning_Added_LiveStock_Section,
  Lightning_Added_PopAffected_Section,
  Lightning_Added_ReliefCamp_Section,
  Lightning_Added_ReliefDistribution_Section,
  Lightning_Added_Remark_Section,
  Lightning_Added_RescueOperation_Section,
  Lightning_Imported,
  Lightning_Report_Discard,
  Lightning_Report_Submit,
  Lightning_Report_Approved,
  Lightning_Report_Disapproved,
  Lightning_Updated_Basic_Section,
  Lightning_Report_Verify,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  lightningResetButtonLoading,
  lightningSetAllSection,
  lightningSetDataSaved,
  lightningSetParentId,
  lightningSetRedirectToListing,
  setExportLightning,
  setLightningListing,
  setLightningDistrictReviewList,
  setExportDistrictCumulativeLightningReport,
  setExportStateCumulativeLightningReport,
  setExportFOLevelLightningReport,
  setLightningStateReviewList,
} from "redux/actions/Lightning";
import {
  GET_ALL_LIGHTNING,
  GET_EXPORT_LIGHTNING_DATA,
  LIGHTNING_ADD_BASIC_SECTION,
  LIGHTNING_ADD_INFDAMAGEOTHER_SECTION,
  LIGHTNING_ADD_REMARK_SECTION,
  LIGHTNING_EDIT_BASIC_SECTION,
  LIGHTNING_GET_ALL_SECTION,
  LIGHTNING_IMPORT,
  LIGHTNING_REPORT_DISCARD,
  LIGHTNING_REPORT_SUBMIT,
  LIGHTNING_ADD_POPAFFECTED_SECTION,
  LIGHTNING_ADD_RELIEFCAMP_SECTION,
  LIGHTNING_ADD_CAMPINMATES_SECTION,
  LIGHTNING_ADD_HLL_SECTION,
  LIGHTNING_ADD_LIVESTOCK_SECTION,
  LIGHTNING_ADD_HOUSEDAMAGED_SECTION,
  LIGHTNING_ADD_RESCUEOPERATION_SECTION,
  LIGHTNING_ADD_RELIEFDISTRIBUTION_SECTION,
  GET_LIGHTNING_DISTRICT_REVIEW_LIST,
  GET_LIGHTNING_STATE_REVIEW_LIST,
  EXPORT_FO_LEVEL_LIGHTNING_REPORT,
  LIGHTNING_REVENUE_REPORT_APPROVE,
  LIGHTNING_REVENUE_REPORT_DISAPPROVE,
  LIGHTNING_REVENUE_REPORT_VERIFY,
  LIGHTNING_DISTRICT_REPORT_APPROVE,
  LIGHTNING_DISTRICT_REPORT_DISAPPROVE,
  LIGHTNING_DISTRICT_REPORT_VERIFY,
  LIGHTNING_STATE_REPORT_APPROVE,
  LIGHTNING_STATE_REPORT_DISAPPROVE,
  GET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT,
  GET_STATE_CUMULATIVE_LIGHTNING_REPORT,
} from "redux/constants/Lightning";
import LightningService from "services/LightningService";
import {
  LIGHTNING_ADD_NONCAMPINMATES_SECTION,
  FREEZE_UNFREEZE_LIGHTNING_RANGE_REPORT,
} from "../constants/Lightning";
import {
  freezeButton,
  NonCampInmatesSection,
} from "../../constants/ApplicationConstant";
import {
  Lightning_Added_NonCampInmates_Section,
  Lightning_Freeze_Successfully,
} from "../../constants/MessageConstant";
import { lightningSetReliefCenterSection } from "../actions/Lightning";

//#region Method for listing

/**
 * Method for getting the lightnings
 */
export function* getAllLightning() {
  yield takeEvery(GET_ALL_LIGHTNING, function* ({ payload }) {
    try {
      const lightning = yield call(LightningService.getAllLightning, payload);
      if (lightning.error != null && lightning.error.message) {
        yield put(
          showMessage({ message: lightning.error.message, type: Error })
        );
      } else {
        yield put(setLightningListing(lightning));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the lightning export details
 */
export function* getLightningExport() {
  yield takeEvery(GET_EXPORT_LIGHTNING_DATA, function* () {
    try {
      const lightning = yield call(LightningService.getExportLightningData);
      if (lightning.error != null && lightning.error.message) {
        yield put(
          showMessage({ message: lightning.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(lightning),
        };
        yield put(setExportLightning(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for lightning import page

/**
 * Method for importing the lightning
 */
export function* lightningImport() {
  yield takeEvery(LIGHTNING_IMPORT, function* ({ payload }) {
    try {
      const lightning = yield call(LightningService.lightningImport, payload);
      if (lightning.error != null && lightning.error.message) {
        yield put(
          showMessage({ message: lightning.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: Lightning_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for lightning import page

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for getting the all section data
 */
export function* lightningGetAllSection() {
  yield takeEvery(LIGHTNING_GET_ALL_SECTION, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        LightningService.lightningGetAllSection,
        payload.id,
        payload.param
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(lightningSetAllSection(infDepartment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for submitting the report
 */
export function* lightningReportSubmit() {
  yield takeEvery(LIGHTNING_REPORT_SUBMIT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        LightningService.lightningReportSubmit,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: Lightning_Report_Submit, type: Success })
        );
        yield put(lightningSetRedirectToListing(true));
        yield put(lightningResetButtonLoading({ section: SubmitButton }));
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: SubmitButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for discarding the report
 */
export function* lightningReportDiscard() {
  yield takeEvery(LIGHTNING_REPORT_DISCARD, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        LightningService.lightningReportDiscard,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: Lightning_Report_Discard, type: Success })
        );
        yield put(lightningSetRedirectToListing(true));
        yield put(lightningResetButtonLoading({ section: DiscardButton }));
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: DiscardButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding basic section
 */
export function* lightningAddBasicSection() {
  yield takeEvery(LIGHTNING_ADD_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };

      const lightning = yield call(
        LightningService.lightningAddBasicSection,
        final
      );
      if (lightning.error != null && lightning.error.message) {
        yield put(
          showMessage({ message: lightning.error.message, type: Error })
        );
      } else {
        yield put(lightningSetParentId(lightning.data.id));
        yield put(lightningResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({ message: Lightning_Added_Basic_Section, type: Success })
        );
        yield put(
          lightningSetDataSaved({ section: BasicSection, value: true })
        );
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for editing basic section
 */
export function* lightningEditBasicSection() {
  yield takeEvery(LIGHTNING_EDIT_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const lightning = yield call(
        LightningService.lightningEditBasicSection,
        payload.id,
        final
      );
      if (lightning.error != null && lightning.error.message) {
        yield put(
          showMessage({ message: lightning.error.message, type: Error })
        );
      } else {
        yield put(lightningResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: Lightning_Updated_Basic_Section,
            type: Success,
          })
        );
        yield put(
          lightningSetDataSaved({ section: BasicSection, value: true })
        );
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Basic Section

//#region InfDamageOther Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding infDamageOther section
 */
export function* lightningAddInfDamageOtherSection() {
  yield takeEvery(
    LIGHTNING_ADD_INFDAMAGEOTHER_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const lightning = yield call(
          LightningService.lightningAddInfDamageOtherSection,
          final
        );
        if (lightning.error != null && lightning.error.message) {
          yield put(
            showMessage({ message: lightning.error.message, type: Error })
          );
        } else {
          yield put(
            lightningResetButtonLoading({ section: InfDamageOtherSection })
          );
          yield put(
            showMessage({
              message: Lightning_Added_InfDamageOther_Section,
              type: Success,
            })
          );
          yield put(
            lightningSetDataSaved({
              section: InfDamageOtherSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          lightningResetButtonLoading({ section: InfDamageOtherSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion InfDamageOther Section

//#region Remark Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding Remark section
 */
export function* lightningAddRemarkSection() {
  yield takeEvery(LIGHTNING_ADD_REMARK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const Lightning = yield call(
        LightningService.lightningAddRemarkSection,
        final
      );
      if (Lightning.error != null && Lightning.error.message) {
        yield put(
          showMessage({ message: Lightning.error.message, type: Error })
        );
      } else {
        yield put(lightningResetButtonLoading({ section: RemarkSection }));
        yield put(
          showMessage({
            message: Lightning_Added_Remark_Section,
            type: Success,
          })
        );
        yield put(
          lightningSetDataSaved({ section: RemarkSection, value: true })
        );
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: RemarkSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Remark Section

//#region PopAffected Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding popaffected section
 */
export function* lightningAddPopAffectedSection() {
  yield takeEvery(LIGHTNING_ADD_POPAFFECTED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const lightning = yield call(
        LightningService.lightningAddPopAffectedSection,
        final
      );
      if (lightning.error != null && lightning.error.message) {
        yield put(
          showMessage({ message: lightning.error.message, type: Error })
        );
      } else {
        yield put(lightningResetButtonLoading({ section: PopAffectedSection }));
        yield put(
          showMessage({
            message: Lightning_Added_PopAffected_Section,
            type: Success,
          })
        );
        yield put(
          lightningSetDataSaved({ section: PopAffectedSection, value: true })
        );
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: PopAffectedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion PopAffected Section

//#region Relief Camp Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding relief camp section
 */
export function* lightningAddReliefCampSection() {
  yield takeEvery(LIGHTNING_ADD_RELIEFCAMP_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const lightning = yield call(
        LightningService.lightningAddReliefCampSection,
        final
      );
      if (lightning.error != null && lightning.error.message) {
        yield put(
          showMessage({ message: lightning.error.message, type: Error })
        );
      } else {
        yield put(lightningResetButtonLoading({ section: ReliefCampSection }));
        yield put(
          showMessage({
            message: Lightning_Added_ReliefCamp_Section,
            type: Success,
          })
        );
        yield put(
          lightningSetDataSaved({ section: ReliefCampSection, value: true })
        );
        yield put(
          lightningSetReliefCenterSection(
            lightning?.data?.data?.reliefCenterData
          )
        );
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: ReliefCampSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Relief Camp Section

//#region Camp inmates Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding camp inmates section
 */
export function* lightningAddCampInmatesSection() {
  yield takeEvery(LIGHTNING_ADD_CAMPINMATES_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const lightning = yield call(
        LightningService.lightningAddCampInmatesSection,
        final
      );
      if (lightning.error != null && lightning.error.message) {
        yield put(
          showMessage({ message: lightning.error.message, type: Error })
        );
      } else {
        yield put(lightningResetButtonLoading({ section: CampInmatesSection }));
        yield put(
          showMessage({
            message: Lightning_Added_CampInmates_Section,
            type: Success,
          })
        );
        yield put(
          lightningSetDataSaved({ section: CampInmatesSection, value: true })
        );
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: CampInmatesSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Camp Inmates Section

//#region Non Camp inmates Section
//@author: vishal.mishra@velsof.com
//@date : 29-01-2025

/** Method for adding non camp inmates section
 */
export function* lightningAddNonCampInmatesSection() {
  yield takeEvery(
    LIGHTNING_ADD_NONCAMPINMATES_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const lightning = yield call(
          LightningService.lightningAddNonCampInmatesSection,
          final
        );
        if (lightning.error != null && lightning.error.message) {
          yield put(
            showMessage({ message: lightning.error.message, type: Error })
          );
        } else {
          yield put(
            lightningResetButtonLoading({ section: NonCampInmatesSection })
          );
          yield put(
            showMessage({
              message: Lightning_Added_NonCampInmates_Section,
              type: Success,
            })
          );
          yield put(
            lightningSetDataSaved({
              section: NonCampInmatesSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          lightningResetButtonLoading({ section: NonCampInmatesSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion Non Camp Inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding Human Live Lost section
 */
export function* lightningAddHLLSection() {
  yield takeEvery(LIGHTNING_ADD_HLL_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const lightning = yield call(
        LightningService.lightningAddHLLSection,
        final
      );
      if (lightning.error != null && lightning.error.message) {
        yield put(
          showMessage({ message: lightning.error.message, type: Error })
        );
      } else {
        yield put(lightningResetButtonLoading({ section: HllSection }));
        yield put(
          showMessage({
            message: Lightning_Added_HLL_Section,
            type: Success,
          })
        );
        yield put(lightningSetDataSaved({ section: HllSection, value: true }));
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: HllSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding LiveStock section
 */
export function* lightningAddLiveStockSection() {
  yield takeEvery(LIGHTNING_ADD_LIVESTOCK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const lightning = yield call(
        LightningService.lightningAddLiveStockSection,
        final
      );
      if (lightning.error != null && lightning.error.message) {
        yield put(
          showMessage({ message: lightning.error.message, type: Error })
        );
      } else {
        yield put(lightningResetButtonLoading({ section: LiveStockSection }));
        yield put(
          showMessage({
            message: Lightning_Added_LiveStock_Section,
            type: Success,
          })
        );
        yield put(
          lightningSetDataSaved({ section: LiveStockSection, value: true })
        );
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: LiveStockSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding HouseDamaged section
 */
export function* lightningAddHouseDamagedSection() {
  yield takeEvery(LIGHTNING_ADD_HOUSEDAMAGED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const lightning = yield call(
        LightningService.lightningAddHouseDamagedSection,
        final
      );
      if (lightning.error != null && lightning.error.message) {
        yield put(
          showMessage({ message: lightning.error.message, type: Error })
        );
      } else {
        yield put(
          lightningResetButtonLoading({ section: HouseDamagedSection })
        );
        yield put(
          showMessage({
            message: Lightning_Added_HouseDamaged_Section,
            type: Success,
          })
        );
        yield put(
          lightningSetDataSaved({ section: HouseDamagedSection, value: true })
        );
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: HouseDamagedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding RescueOperation section
 */
export function* lightningAddRescueOperationSection() {
  yield takeEvery(
    LIGHTNING_ADD_RESCUEOPERATION_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const lightning = yield call(
          LightningService.lightningAddRescueOperationSection,
          final
        );
        if (lightning.error != null && lightning.error.message) {
          yield put(
            showMessage({ message: lightning.error.message, type: Error })
          );
        } else {
          yield put(
            lightningResetButtonLoading({ section: RescueOperationSection })
          );
          yield put(
            showMessage({
              message: Lightning_Added_RescueOperation_Section,
              type: Success,
            })
          );
          yield put(
            lightningSetDataSaved({
              section: RescueOperationSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          lightningResetButtonLoading({ section: RescueOperationSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding ReliefDistribution section
 */
export function* lightningAddReliefDistributionSection() {
  yield takeEvery(
    LIGHTNING_ADD_RELIEFDISTRIBUTION_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const lightning = yield call(
          LightningService.lightningAddReliefDistributionSection,
          final
        );
        if (lightning.error != null && lightning.error.message) {
          yield put(
            showMessage({ message: lightning.error.message, type: Error })
          );
        } else {
          yield put(
            lightningResetButtonLoading({ section: ReliefDistributionSection })
          );
          yield put(
            showMessage({
              message: Lightning_Added_ReliefDistribution_Section,
              type: Success,
            })
          );
          yield put(
            lightningSetDataSaved({
              section: ReliefDistributionSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          lightningResetButtonLoading({ section: ReliefDistributionSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion ReliefDistribution Section

/**
 * The below method is for exporting the report in lightning view form
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */

/**
 * Method for getting the district export details
 */
export function* getExportFoLevelLightningReport() {
  yield takeEvery(EXPORT_FO_LEVEL_LIGHTNING_REPORT, function* ({ payload }) {
    try {
      const exportedReport = yield call(
        LightningService.getExportFoLevelLightningReport,
        payload
      );

      if (exportedReport.error != null && exportedReport.error.message) {
        yield put(
          showMessage({ message: exportedReport.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(exportedReport),
          format: payload.format,
        };
        yield put(setExportFOLevelLightningReport(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#region Method for Approval and Disapproval
/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for approving the revenue report
 */
export function* lightningRevenueReportApprove() {
  yield takeEvery(LIGHTNING_REVENUE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const lightningReportStatus = yield call(
        LightningService.lightningRevenueReportApprove,
        payload
      );
      if (
        lightningReportStatus.error != null &&
        lightningReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: lightningReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Lightning_Report_Approved, type: Success })
        );
        yield put(lightningSetRedirectToListing(true));
        yield put(lightningResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the revenue report
 */
export function* lightningRevenueReportDisapprove() {
  yield takeEvery(LIGHTNING_REVENUE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const lightningReportStatus = yield call(
        LightningService.lightningRevenueReportDisapprove,
        payload
      );
      if (
        lightningReportStatus.error != null &&
        lightningReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: lightningReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Lightning_Report_Disapproved, type: Success })
        );
        yield put(lightningSetRedirectToListing(true));
        yield put(lightningResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: disapproveButton }));
    }
  });
}

/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for verify the revenue report
 */
export function* lightningRevenueReportVerify() {
  yield takeEvery(LIGHTNING_REVENUE_REPORT_VERIFY, function* ({ payload }) {
    try {
      const lightningReportStatus = yield call(
        LightningService.lightningRevenueReportVerify,
        payload
      );
      if (
        lightningReportStatus.error != null &&
        lightningReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: lightningReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Lightning_Report_Verify, type: Success })
        );
        yield put(lightningSetRedirectToListing(true));
        yield put(lightningResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* lightningDistrictReportApprove() {
  yield takeEvery(LIGHTNING_DISTRICT_REPORT_APPROVE, function* ({ payload }) {
    try {
      const lightningReportStatus = yield call(
        LightningService.lightningDistrictReportApprove,
        payload
      );
      if (
        lightningReportStatus.error != null &&
        lightningReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: lightningReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Lightning_Report_Approved, type: Success })
        );
        yield put(lightningSetRedirectToListing(true));
        yield put(lightningResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* lightningDistrictReportDisapprove() {
  yield takeEvery(
    LIGHTNING_DISTRICT_REPORT_DISAPPROVE,
    function* ({ payload }) {
      try {
        const lightningReportStatus = yield call(
          LightningService.lightningDistrictReportDisapprove,
          payload
        );
        if (
          lightningReportStatus.error != null &&
          lightningReportStatus.error.message
        ) {
          yield put(
            showMessage({
              message: lightningReportStatus.error.message,
              type: Error,
            })
          );
        } else {
          yield put(
            showMessage({
              message: Lightning_Report_Disapproved,
              type: Success,
            })
          );
          yield put(lightningSetRedirectToListing(true));
          yield put(lightningResetButtonLoading({ section: disapproveButton }));
        }
      } catch (err) {
        yield put(lightningResetButtonLoading({ section: disapproveButton }));
      }
    }
  );
}

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* lightningDistrictReportVerify() {
  yield takeEvery(LIGHTNING_DISTRICT_REPORT_VERIFY, function* ({ payload }) {
    try {
      const lightningReportStatus = yield call(
        LightningService.lightningDistrictReportVerify,
        payload
      );
      if (
        lightningReportStatus.error != null &&
        lightningReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: lightningReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Lightning_Report_Verify, type: Success })
        );
        yield put(lightningSetRedirectToListing(true));
        yield put(lightningResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* lightningStateReportApprove() {
  yield takeEvery(LIGHTNING_STATE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const lightningReportStatus = yield call(
        LightningService.lightningStateReportApprove,
        payload
      );
      if (
        lightningReportStatus.error != null &&
        lightningReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: lightningReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Lightning_Report_Approved, type: Success })
        );
        yield put(lightningSetRedirectToListing(true));
        yield put(lightningResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* lightningStateReportDisapprove() {
  yield takeEvery(LIGHTNING_STATE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const lightningReportStatus = yield call(
        LightningService.lightningStateReportDisapprove,
        payload
      );
      if (
        lightningReportStatus.error != null &&
        lightningReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: lightningReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Lightning_Report_Disapproved, type: Success })
        );
        yield put(lightningSetRedirectToListing(true));
        yield put(lightningResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(lightningResetButtonLoading({ section: disapproveButton }));
    }
  });
}
//#endregion Method for Approval and Disapproval

//#region
/**
 * The below method is for exporting the report in lightning cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */

/**
 * Method for getting the cumulative state export details
 */
export function* getExportStateCumulativeLightningReport() {
  yield takeEvery(
    GET_STATE_CUMULATIVE_LIGHTNING_REPORT,
    function* ({ payload }) {
      try {
        const exportedReport = yield call(
          LightningService.getExportStateCumulativeLightningReport,
          payload
        );
        if (exportedReport.error != null && exportedReport.error.message) {
          yield put(
            showMessage({ message: exportedReport.error.message, type: Error })
          );
        } else {
          if (payload?.sendEmail) {
            // Convert the Blob response to text
            const textResponse = yield exportedReport?.text();
            const parsedResponse = JSON.parse(textResponse);
            yield put(
              showMessage({
                message: parsedResponse?.message,
                type: Success,
              })
            );
          } else {
            let exportData = {
              reportData:
                payload.format === "html"
                  ? exportedReport
                  : window.URL.createObjectURL(exportedReport),
              format: payload.format,
            };
            yield put(setExportStateCumulativeLightningReport(exportData));
          }
        }
      } catch (err) {
        let errPromise = yield err.response.data.text();
        yield put(
          showMessage({
            message: JSON.parse(errPromise).error.message,
            type: Error,
          })
        );
      }
    }
  );
}
//#endregion

/**
 * Method for getting the cumulative district export details
 */
export function* getExportDistrictCumulativeLightningReport() {
  yield takeEvery(
    GET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT,
    function* ({ payload }) {
      try {
        const exportedReport = yield call(
          LightningService.getExportDistrictCumulativeLightningReport,
          payload
        );
        if (exportedReport.error != null && exportedReport.error.message) {
          yield put(
            showMessage({ message: exportedReport.error.message, type: Error })
          );
        } else {
          let exportData = {
            reportData:
              payload.format === "html"
                ? exportedReport
                : window.URL.createObjectURL(exportedReport),
            format: payload.format,
          };
          yield put(setExportDistrictCumulativeLightningReport(exportData));
        }
      } catch (err) {
        let errPromise = yield err.response.data.text();
        yield put(
          showMessage({
            message: JSON.parse(errPromise).error.message,
            type: Error,
          })
        );
      }
    }
  );
}
//#endregion

/**
 * Method for getting the district review list
 */
export function* getLightningDistrictReviewList() {
  yield takeEvery(GET_LIGHTNING_DISTRICT_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        LightningService.getLightningDistrictReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setLightningDistrictReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the state review list
 */
export function* getLightningStateReviewList() {
  yield takeEvery(GET_LIGHTNING_STATE_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        LightningService.getLightningStateReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setLightningStateReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for Freezing and unfreezing lightning report for a selected date range
 * @author: vishal.mishra@velsof.com
 * @date : 29-01-2024
 */
export function* lightningFreezeReportRange() {
  yield takeEvery(
    FREEZE_UNFREEZE_LIGHTNING_RANGE_REPORT,
    function* ({ payload }) {
      try {
        const lightningReportStatus = yield call(
          LightningService.lightningFreezeReportRange,
          payload
        );
        if (
          lightningReportStatus.error != null &&
          lightningReportStatus.error.message
        ) {
          yield put(
            showMessage({
              message: lightningReportStatus.error.message,
              type: Error,
            })
          );
        } else {
          yield put(
            showMessage({
              message: Lightning_Freeze_Successfully,
              type: Success,
            })
          );
          yield put(lightningResetButtonLoading({ section: freezeButton }));
        }
      } catch (err) {
        yield put(lightningResetButtonLoading({ section: freezeButton }));
      }
    }
  );
}

//#endregion Method for Approval and Disapproval

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllLightning),
    fork(getLightningExport),
    fork(lightningImport),
    fork(lightningAddBasicSection),
    fork(lightningEditBasicSection),
    fork(lightningGetAllSection),
    fork(lightningAddInfDamageOtherSection),
    fork(lightningAddRemarkSection),
    fork(lightningReportSubmit),
    fork(lightningReportDiscard),
    fork(lightningAddPopAffectedSection),
    fork(lightningAddReliefCampSection),
    fork(lightningAddCampInmatesSection),
    fork(lightningAddNonCampInmatesSection),
    fork(lightningAddHLLSection),
    fork(lightningAddLiveStockSection),
    fork(lightningAddHouseDamagedSection),
    fork(lightningAddRescueOperationSection),
    fork(lightningAddReliefDistributionSection),
    fork(lightningRevenueReportApprove),
    fork(lightningRevenueReportDisapprove),
    fork(lightningRevenueReportVerify),
    fork(lightningDistrictReportApprove),
    fork(lightningDistrictReportDisapprove),
    fork(lightningDistrictReportVerify),
    fork(lightningStateReportApprove),
    fork(lightningStateReportDisapprove),
    fork(getExportFoLevelLightningReport),
    fork(getLightningDistrictReviewList),
    fork(getLightningStateReviewList),
    fork(getExportStateCumulativeLightningReport),
    fork(getExportDistrictCumulativeLightningReport),
    fork(lightningFreezeReportRange),
  ]);
}
