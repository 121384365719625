/**
 * The below file contains the Sagas method for Earthquake Inmates Registration.
 * @author: vishal.mishra@velsof.com
 * @date : 07-03-2024
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Status_Registration_Changed,
  Earthquake_Camp_Inmates_Imported,
} from "constants/MessageConstant";
import {
  all,
  takeEvery,
  put,
  fork,
  call,
  takeLatest,
} from "redux-saga/effects";
import {
  earthquakeSetAllCreateInmatesRegistration,
  earthquakeSetAllInmatesRegistration,
  earthquakeSetAllUpdateInmatesRegistration,
  earthquakeSetExportInmatesRegistration,
  earthquakeSetImportTemplateInmatesRegistration,
} from "redux/actions";

import { showMessage } from "redux/actions/Common";
import {
  GET_ALL_EARTHQUAKE_INMATES_REGISTRATION,
  GET_ALL_CREATE_EARTHQUAKE_INMATES_REGISTRATION,
  GET_ALL_UPDATE_EARTHQUAKE_INMATES_REGISTRATION,
  CAMP_INMATES_REGISTRATION_IMPORT,
  GET_EXPORT_CAMP_INMATES_DATA,
  GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
} from "redux/constants/EarthquakeInmatesRegistration";
import EarthquakeInmatesRegistrationServices from "services/EarthquakeInmatesRegistrationServices";

export function* getAllInmatesRegistration() {
  yield takeEvery(
    GET_ALL_EARTHQUAKE_INMATES_REGISTRATION,
    function* ({ payload }) {
      try {
        const inmatesData = yield call(
          EarthquakeInmatesRegistrationServices.getAll,
          payload
        );
        if (inmatesData.error != null && inmatesData.error.message) {
          yield put(
            showMessage({ message: inmatesData.error.message, type: Error })
          );
        } else {
          yield put(earthquakeSetAllInmatesRegistration(inmatesData));
        }
      } catch (error) {
        yield put(
          showMessage({
            message: error.response.data.error.message,
            type: Error,
          })
        );
      }
    }
  );
}

// Saga for creating a new Freeze Unfreeze report
export function* getAllCreateInmatesRegistration() {
  yield takeEvery(
    GET_ALL_CREATE_EARTHQUAKE_INMATES_REGISTRATION,
    function* ({ payload }) {
      try {
        const inmatesData = yield call(
          EarthquakeInmatesRegistrationServices.create,
          payload
        );
        if (inmatesData.error != null && inmatesData.error.message) {
          yield put(
            showMessage({ message: inmatesData.error.message, type: Error })
          );
        } else {
          yield put(earthquakeSetAllCreateInmatesRegistration(inmatesData));
          yield put(
            showMessage({
              message: Status_Registration_Changed,
              type: Success,
            })
          );
        }
      } catch (error) {
        yield put(
          showMessage({
            message: error.response.data.error.message,
            type: Error,
          })
        );
      }
    }
  );
}

// Saga for updating an existing Freeze Unfreeze report
export function* getAllUpdateInmatesRegistration() {
  yield takeEvery(
    GET_ALL_UPDATE_EARTHQUAKE_INMATES_REGISTRATION,
    function* ({ payload, id }) {
      try {
        const inmatesData = yield call(
          EarthquakeInmatesRegistrationServices.update,
          payload,
          id
        );
        if (inmatesData.error != null && inmatesData.error.message) {
          yield put(
            showMessage({ message: inmatesData.error.message, type: Error })
          );
        } else {
          yield put(earthquakeSetAllUpdateInmatesRegistration(inmatesData));
          yield put(
            showMessage({
              message: Status_Registration_Changed,
              type: Success,
            })
          );
        }
      } catch (error) {
        yield put(showMessage({ message: error.message, type: Error }));
      }
    }
  );
}

//#endregion Method for listing

/**
 * Method for importing the reliefCamp
 */
export function* campInmatesRegistrationImport() {
  yield takeEvery(CAMP_INMATES_REGISTRATION_IMPORT, function* ({ payload }) {
    try {
      const reliefCamp = yield call(
        EarthquakeInmatesRegistrationServices.campInmatesRegistrationImport,
        payload
      );
      if (reliefCamp.error != null && reliefCamp.error.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({
            message: Earthquake_Camp_Inmates_Imported,
            type: Success,
          })
        );
      }
    } catch (err) {
      try {
        yield put(
          showMessage({
            message: JSON.parse(err.response.data.error.message),
            type: Error,
          })
        );
      } catch (error) {
        yield put(
          showMessage({
            message: err.response.data.error.message,
            type: Error,
          })
        );
      }
    }
  });
}

/**
 * Method for importing the reliefCamp
 */
export function* campInmatesRegistrationImportExcel() {
  yield takeEvery(
    CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
    function* ({ payload }) {
      try {
        const reliefCamp = yield call(
          EarthquakeInmatesRegistrationServices.campInmatesRegistrationImportExcel,
          payload
        );
        if (reliefCamp.error != null && reliefCamp.error.message) {
          yield put(
            showMessage({ message: reliefCamp.error.message, type: Error })
          );
        } else {
          yield put(
            showMessage({
              message: Earthquake_Camp_Inmates_Imported,
              type: Success,
            })
          );
        }
      } catch (err) {
        let message = [];
        try {
          message = JSON.parse(err?.response?.data?.message);
        } catch (e) {
          message.push([err?.response?.data?.message]);
        }
        yield put(
          showMessage({
            message: message,
            type: Error,
          })
        );
        // Check if the error contains the base64 file
        if (
          err?.response?.data?.details &&
          err?.response?.data?.details?.errorFileBase64
        ) {
          const base64Data = err.response.data.details.errorFileBase64;
          const fileName = "error_file.xlsx";
          const linkSource = `data:application/octet-stream;base64,${base64Data}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    }
  );
}

/**
 * Method for getting the reliefCamp export details
 */
export function* getReliefCampExport() {
  yield takeEvery(GET_EXPORT_CAMP_INMATES_DATA, function* ({ payload }) {
    try {
      const filter = {};
      filter.district = payload["filters[block][district][id][$eq]"];
      filter.block = payload["filters[block][id][$eq]"];
      filter.startDate = payload["filters[date][$gte]"];
      filter.endDate = payload["filters[date][$lte]"];

      const reliefCamp = yield call(
        EarthquakeInmatesRegistrationServices.getExportInmatesRegistrationData,
        filter
      );
      if (reliefCamp?.error != null && reliefCamp?.error?.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(reliefCamp),
        };
        yield put(earthquakeSetExportInmatesRegistration(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: err?.response?.data?.error?.message,
          type: Error,
        })
      );
    }
  });
}

/**
 * Method for getting the Registration Import details
 */
export function* getImportTemplateCampExport() {
  yield takeEvery(
    GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
    function* ({ payload }) {
      try {
        const filter = {};
        filter.district = payload.district;
        filter.block = payload.block;

        const reliefCamp = yield call(
          EarthquakeInmatesRegistrationServices.getImportTemplateInmatesRegistrationData,
          filter
        );
        if (reliefCamp?.error != null && reliefCamp?.error?.message) {
          yield put(
            showMessage({ message: reliefCamp.error.message, type: Error })
          );
        } else {
          let exportData = {
            url: window.URL.createObjectURL(reliefCamp),
          };
          yield put(earthquakeSetImportTemplateInmatesRegistration(exportData));
        }
      } catch (err) {
        yield put(
          showMessage({
            message: err?.response?.data?.error?.message,
            type: Error,
          })
        );
      }
    }
  );
}

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllInmatesRegistration),
    fork(getAllCreateInmatesRegistration),
    fork(getAllUpdateInmatesRegistration),
    fork(campInmatesRegistrationImport),
    fork(getReliefCampExport),
    fork(getImportTemplateCampExport),
    fork(campInmatesRegistrationImportExcel),
  ]);
}
