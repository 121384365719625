import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_CAMP_INMATES_DATA,
  GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  SET_ALL_CREATE_LANDSLIDE_INMATES_REGISTRATION,
  SET_ALL_LANDSLIDE_INMATES_REGISTRATION,
  SET_ALL_UPDATE_LANDSLIDE_INMATES_REGISTRATION,
  SET_EXPORT_CAMP_INMATES_DATA,
  SET_LANDSLIDE_INMATES_REGISTRATION,
  SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
} from "redux/constants/LandslideInmatesRegistration";

// Define an initial state
const initialState = {
  landslideInmatesRegistrationData: [],
  landslideIsInmatesRegistrationDataFetched: false,
  landslideInmatesRegistrationListingParam: {},
  landslideCampInmatesRegistrationExportedData: {},
  landslideCampInmatesRegistrationImportTemplateData: {},
  token: localStorage.getItem(AUTH_TOKEN),
};

// Reducer function
const landslideInmatesRegistration = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_LANDSLIDE_INMATES_REGISTRATION:
      return {
        ...state,
        landslideInmatesRegistrationData: action.payload,
        landslideIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_CREATE_LANDSLIDE_INMATES_REGISTRATION:
      // Handle the logic to create a new report here
      // You can update the state accordingly
      // For example, if you want to store the new report data, you can do something like this:

      return {
        ...state,
        landslideInmatesRegistrationData: action.payload,
        landslideIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_UPDATE_LANDSLIDE_INMATES_REGISTRATION:
      return {
        ...state,
        landslideInmatesRegistrationData: action.payload,
        landslideIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_LANDSLIDE_INMATES_REGISTRATION:
      return {
        ...state,
        landslideInmatesRegistrationListingParam: action.payload,
      };

    case GET_EXPORT_CAMP_INMATES_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_CAMP_INMATES_DATA:
      return {
        ...state,
        landslideCampInmatesRegistrationExportedData: action.payload,
      };

    case GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA:
      return {
        ...state,
        payload: action.payload,
      };

    case SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA:
      return {
        ...state,
        landslideCampInmatesRegistrationImportTemplateData: action.payload,
      };

    default:
      return state;
  }
};

export default landslideInmatesRegistration;
