import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import District from "./District";
import Block from "./Block";
import Village from "./Village";
import Common from "./Common";
import ReliefCamp from "./ReliefCamp";
import InfDepartment from "./InfDepartment";
import Disaster from "./Disaster";
import InfDamage from "./InfDamage";
import InfOtherDamage from "./InfOtherDamage";
import Erosion from "./Erosion";
import River from "./River";
import Agency from "./Agency";
import RescueEquipment from "./RescueEquipment";
import ReliefOtherItem from "./ReliefOtherItem";
import Unit from "./Unit";
import UrbanFlood from "./UrbanFlood";
import Flood from "./Flood";
import Wards from "./Wards";
import Landslide from "./Landslide";
import Storm from "./Storm";
import Lightning from "./Lightning";
import Earthquake from "./Earthquake";
import user from "./User";
import Notification from "./Notification";
import Fire from "./Fire";
import FireCategory from "./FireCategory";
import FireCause from "./FireCause";
import FloodDeathCause from "./FloodDeathCause";
import FireClass from "./FireClass";
import SpecialCallType from "./SpecialCallType";
import OriginArea from "./OriginArea";
import FireDetection from "./FireDetection";
import FireAgency from "./FireAgency";
import TypesOfOccupancy from "./TypesOfOccupancy";
import VehicleCategory from "./VehicleCategory";
import Station from "./Station";
import LivestockTypes from "./LivestockTypes";
import { RESET_REDUCER } from "redux/constants/Root";
import Role from "./Role";
import ApacheSuperset from "./ApacheSuperset";
import ResourceGroup from "./ResourceGroup";
import ResourceDocument from "./ResourceDocument";
import cwc from "./Cwc";
import floodFreezeReport from "./FloodFreezeReport";
import inmatesRegistration from "./FloodInmatesRegistration";
import nonInmatesRegistration from "./FloodNonInmatesRegistration";
import erosionInmatesRegistration from "./ErosionInmatesRegistration";
import erosionNonInmatesRegistration from "./ErosionNonInmatesRegistration";
import landslideNonInmatesRegistration from "./LandslideNonInmatesRegistration";
import landslideInmatesRegistration from "./LandslideInmatesRegistration";
import lightningNonInmatesRegistration from "./LightningNonInmatesRegistration";
import lightningInmatesRegistration from "./LightningInmatesRegistration";
import stormNonInmatesRegistration from "./StormNonInmatesRegistration";
import stormInmatesRegistration from "./StormInmatesRegistration";
import earthquakeNonInmatesRegistration from "./EarthquakeNonInmatesRegistration";
import earthquakeInmatesRegistration from "./EarthquakeInmatesRegistration";
import urbanfloodNonInmatesRegistration from "./UrbanFloodNonInmatesRegistration";
import urbanfloodInmatesRegistration from "./UrbanFloodInmatesRegistration";

const appReducers = combineReducers({
  cwc: cwc,
  theme: Theme,
  auth: Auth,
  district: District,
  block: Block,
  village: Village,
  common: Common,
  reliefCamp: ReliefCamp,
  infDepartment: InfDepartment,
  disaster: Disaster,
  infDamage: InfDamage,
  infOtherDamage: InfOtherDamage,
  erosion: Erosion,
  river: River,
  agency: Agency,
  rescueEquipment: RescueEquipment,
  reliefOtherItem: ReliefOtherItem,
  unit: Unit,
  urbanflood: UrbanFlood,
  flood: Flood,
  wards: Wards,
  landslide: Landslide,
  storm: Storm,
  lightning: Lightning,
  earthquake: Earthquake,
  user: user,
  notification: Notification,
  fire: Fire,
  fireCategory: FireCategory,
  fireCause: FireCause,
  floodDeathCause: FloodDeathCause,
  fireClass: FireClass,
  specialCallType: SpecialCallType,
  originArea: OriginArea,
  fireDetection: FireDetection,
  fireAgency: FireAgency,
  typesOfOccupancy: TypesOfOccupancy,
  vehicleCategory: VehicleCategory,
  station: Station,
  livestockTypes: LivestockTypes,
  role: Role,
  apacheSuperset: ApacheSuperset,
  resourceGroup: ResourceGroup,
  resourceDocument: ResourceDocument,
  floodFreezeReport: floodFreezeReport,
  inmatesRegistration: inmatesRegistration,
  nonInmatesRegistration: nonInmatesRegistration,
  erosionNonInmatesRegistration: erosionNonInmatesRegistration,
  erosionInmatesRegistration: erosionInmatesRegistration,
  landslideNonInmatesRegistration: landslideNonInmatesRegistration,
  landslideInmatesRegistration: landslideInmatesRegistration,
  lightningNonInmatesRegistration: lightningNonInmatesRegistration,
  lightningInmatesRegistration: lightningInmatesRegistration,
  stormNonInmatesRegistration: stormNonInmatesRegistration,
  stormInmatesRegistration: stormInmatesRegistration,
  earthquakeNonInmatesRegistration: earthquakeNonInmatesRegistration,
  earthquakeInmatesRegistration: earthquakeInmatesRegistration,
  urbanfloodNonInmatesRegistration: urbanfloodNonInmatesRegistration,
  urbanfloodInmatesRegistration: urbanfloodInmatesRegistration,
});

const reducers = (state, action) => {
  if (action.type === RESET_REDUCER) {
    const { auth, common, theme, notification } = state;
    state = { auth, common, theme, notification };
  }
  return appReducers(state, action);
};

export default reducers;
