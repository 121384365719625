/**
 * The below file contains the Sagas method for Storm Non Inmates Registration.
 * @author: vishal.mishra@velsof.com
 * @date : 29-01-2025
 */

import { Error, Success } from "constants/ApiConstant";
import {
  Status_Registration_Changed,
  Storm_Non_Camp_Inmates_Imported,
} from "constants/MessageConstant";
import {
  all,
  takeEvery,
  put,
  fork,
  call,
  takeLatest,
} from "redux-saga/effects";
import {
  stormSetAllCreateNonInmatesRegistration,
  stormSetAllNonInmatesRegistration,
  stormSetAllUpdateNonInmatesRegistration,
  stormSetExportNonInmatesRegistration,
} from "redux/actions";

import { showMessage } from "redux/actions/Common";
import {
  GET_ALL_STORM_NON_INMATES_REGISTRATION,
  GET_ALL_CREATE_STORM_NON_INMATES_REGISTRATION,
  GET_ALL_UPDATE_STORM_NON_INMATES_REGISTRATION,
  NON_CAMP_INMATES_REGISTRATION_IMPORT,
  GET_EXPORT_NON_CAMP_INMATES_DATA,
  NON_CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
} from "redux/constants/StormNonInmatesRegistration";
import StormNonInmatesRegistrationServices from "services/StormNonInmatesRegistrationServices";

export function* getAllNonInmatesRegistration() {
  yield takeEvery(
    GET_ALL_STORM_NON_INMATES_REGISTRATION,
    function* ({ payload }) {
      try {
        const inmatesData = yield call(
          StormNonInmatesRegistrationServices.getAll,
          payload
        );
        if (inmatesData.error != null && inmatesData.error.message) {
          yield put(
            showMessage({ message: inmatesData.error.message, type: Error })
          );
        } else {
          yield put(stormSetAllNonInmatesRegistration(inmatesData));
        }
      } catch (error) {
        yield put(
          showMessage({
            message: error.response.data.error.message,
            type: Error,
          })
        );
      }
    }
  );
}

// Saga for creating a new Freeze Unfreeze report
export function* getAllCreateNonInmatesRegistration() {
  yield takeEvery(
    GET_ALL_CREATE_STORM_NON_INMATES_REGISTRATION,
    function* ({ payload }) {
      try {
        const inmatesData = yield call(
          StormNonInmatesRegistrationServices.create,
          payload
        );
        if (inmatesData.error != null && inmatesData.error.message) {
          yield put(
            showMessage({ message: inmatesData.error.message, type: Error })
          );
        } else {
          yield put(stormSetAllCreateNonInmatesRegistration(inmatesData));
          yield put(
            showMessage({
              message: Status_Registration_Changed,
              type: Success,
            })
          );
        }
      } catch (error) {
        yield put(
          showMessage({
            message: error.response.data.error.message,
            type: Error,
          })
        );
      }
    }
  );
}

// Saga for updating an existing Freeze Unfreeze report
export function* getAllUpdateNonInmatesRegistration() {
  yield takeEvery(
    GET_ALL_UPDATE_STORM_NON_INMATES_REGISTRATION,
    function* ({ payload, id }) {
      try {
        const inmatesData = yield call(
          StormNonInmatesRegistrationServices.update,
          payload,
          id
        );
        if (inmatesData.error != null && inmatesData.error.message) {
          yield put(
            showMessage({ message: inmatesData.error.message, type: Error })
          );
        } else {
          yield put(stormSetAllUpdateNonInmatesRegistration(inmatesData));
          yield put(
            showMessage({
              message: Status_Registration_Changed,
              type: Success,
            })
          );
        }
      } catch (error) {
        yield put(showMessage({ message: error.message, type: Error }));
      }
    }
  );
}

//#endregion Method for listing

/**
 * Method for importing the reliefCamp
 */
export function* nonCampInmatesRegistrationImport() {
  yield takeEvery(
    NON_CAMP_INMATES_REGISTRATION_IMPORT,
    function* ({ payload }) {
      try {
        const reliefCamp = yield call(
          StormNonInmatesRegistrationServices.nonCampInmatesRegistrationImport,
          payload
        );
        if (reliefCamp.error != null && reliefCamp.error.message) {
          yield put(
            showMessage({ message: reliefCamp.error.message, type: Error })
          );
        } else {
          yield put(
            showMessage({
              message: Storm_Non_Camp_Inmates_Imported,
              type: Success,
            })
          );
        }
      } catch (err) {
        yield put(
          showMessage({
            message: JSON.parse(err.response.data.error.message),
            type: Error,
          })
        );
      }
    }
  );
}

/**
 * Method for importing the reliefCamp
 */
export function* nonCampInmatesRegistrationImportExcel() {
  yield takeEvery(
    NON_CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
    function* ({ payload }) {
      try {
        const reliefCamp = yield call(
          StormNonInmatesRegistrationServices.nonCampInmatesRegistrationImportExcel,
          payload
        );
        if (reliefCamp.error != null && reliefCamp.error.message) {
          yield put(
            showMessage({ message: reliefCamp.error.message, type: Error })
          );
        } else {
          yield put(
            showMessage({
              message: Storm_Non_Camp_Inmates_Imported,
              type: Success,
            })
          );
        }
      } catch (err) {
        let message = [];
        try {
          message = JSON.parse(err?.response?.data?.message);
        } catch (e) {
          message.push([err?.response?.data?.message]);
        }
        yield put(
          showMessage({
            message: message,
            type: Error,
          })
        );
        // Check if the error contains the base64 file
        if (
          err?.response?.data?.details &&
          err?.response?.data?.details?.errorFileBase64
        ) {
          const base64Data = err.response.data.details.errorFileBase64;
          const fileName = "error_file.xlsx";
          const linkSource = `data:application/octet-stream;base64,${base64Data}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    }
  );
}

/**
 * Method for getting the reliefCamp export details
 */
export function* getReliefNonCampExport() {
  yield takeEvery(GET_EXPORT_NON_CAMP_INMATES_DATA, function* ({ payload }) {
    try {
      const filter = {};
      filter.district = payload["filters[block][district][id][$eq]"];
      filter.block = payload["filters[block][id][$eq]"];
      filter.startDate = payload["filters[date][$gte]"];
      filter.endDate = payload["filters[date][$lte]"];

      const reliefCamp = yield call(
        StormNonInmatesRegistrationServices.getExportNonInmatesRegistrationData,
        filter
      );
      if (reliefCamp?.error != null && reliefCamp?.error?.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(reliefCamp),
        };
        yield put(stormSetExportNonInmatesRegistration(exportData));
      }
    } catch (err) {
      try {
        yield put(
          showMessage({
            message: JSON.parse(err.response.data.error.message),
            type: Error,
          })
        );
      } catch (error) {
        yield put(
          showMessage({
            message: err.response.data.error.message,
            type: Error,
          })
        );
      }
    }
  });
}

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllNonInmatesRegistration),
    fork(getAllCreateNonInmatesRegistration),
    fork(getAllUpdateNonInmatesRegistration),
    fork(nonCampInmatesRegistrationImport),
    fork(nonCampInmatesRegistrationImportExcel),
    fork(getReliefNonCampExport),
  ]);
}
