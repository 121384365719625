/**
 * The below file contains the actions used in the Non Camp Inmates Registration.
 * @author: vishal.mishra@velsof.com
 * @date :01-04-2024
 */

import {
  NON_CAMP_INMATES_REGISTRATION_IMPORT,
  GET_ALL_CREATE_LANDSLIDE_NON_INMATES_REGISTRATION,
  GET_ALL_LANDSLIDE_NON_INMATES_REGISTRATION,
  GET_ALL_UPDATE_LANDSLIDE_NON_INMATES_REGISTRATION,
  GET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_ALL_CREATE_LANDSLIDE_NON_INMATES_REGISTRATION,
  SET_ALL_LANDSLIDE_NON_INMATES_REGISTRATION,
  SET_ALL_UPDATE_LANDSLIDE_NON_INMATES_REGISTRATION,
  SET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_LANDSLIDE_NON_INMATES_REGISTRATION,
  NON_CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
} from "redux/constants/LandslideNonInmatesRegistration";

// Import the constants you defined

// Action to fetch the Non Camp Inmates Registration
export const landslideGetAllNonInmatesRegistration = (data) => {
  return {
    type: GET_ALL_LANDSLIDE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const landslideSetAllNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_LANDSLIDE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Non Camp Inmates Registration
export const landslideGetAllCreateNonInmatesRegistration = (data) => {
  return {
    type: GET_ALL_CREATE_LANDSLIDE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const landslideSetAllCreateNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_CREATE_LANDSLIDE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Non Camp Inmates Registration
export const landslideGetAllUpdateNonInmatesRegistration = (data, id) => {
  return {
    type: GET_ALL_UPDATE_LANDSLIDE_NON_INMATES_REGISTRATION,
    payload: data,
    id: id,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const landslideSetAllUpdateNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_UPDATE_LANDSLIDE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for setting the Landslide Non Inmates Registration parameters
 * @param {any} data Landslide Data
 * @returns Json Object
 */
export const landslideSetNonInmatesRegistrationTableParam = (data) => {
  return {
    type: SET_LANDSLIDE_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for Non Camp Inmates Registration import
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const landslideNonCampInmatesRegistrationImport = (data) => {
  return {
    type: NON_CAMP_INMATES_REGISTRATION_IMPORT,
    payload: data,
  };
};

/**
 * Method for Non Camp Inmates Registration import excel
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const landslideNonCampInmatesRegistrationImportExcel = (data) => {
  return {
    type: NON_CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
    payload: data,
  };
};

/**
 * Method for getting the export file of Non Camp Inmates Registration
 * @returns Json Object
 */
export const landslideGetExportNonInmatesRegistration = (data) => {
  return {
    type: GET_EXPORT_NON_CAMP_INMATES_DATA,
    payload: data,
  };
};

/**
 * Method for setting the reliefCamp export data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const landslideSetExportNonInmatesRegistration = (data) => {
  return {
    type: SET_EXPORT_NON_CAMP_INMATES_DATA,
    payload: data,
  };
};
