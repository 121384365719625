import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_ALL_CREATE_URBANFLOOD_NON_INMATES_REGISTRATION,
  SET_ALL_URBANFLOOD_NON_INMATES_REGISTRATION,
  SET_ALL_UPDATE_URBANFLOOD_NON_INMATES_REGISTRATION,
  SET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_URBANFLOOD_NON_INMATES_REGISTRATION,
} from "redux/constants/UrbanFloodNonInmatesRegistration";

// Define an initial state
const initialState = {
  urbanfloodNonInmatesRegistrationData: [],
  urbanfloodIsNonInmatesRegistrationDataFetched: false,
  urbanfloodNonInmatesRegistrationListingParam: {},
  urbanfloodNonCampInmatesRegistrationExportedData: {},
  token: localStorage.getItem(AUTH_TOKEN),
};

// Reducer function
const urbanfloodNonInmatesRegistration = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_URBANFLOOD_NON_INMATES_REGISTRATION:
      return {
        ...state,
        urbanfloodNonInmatesRegistrationData: action.payload,
        urbanfloodIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_CREATE_URBANFLOOD_NON_INMATES_REGISTRATION:
      // Handle the logic to create a new report here
      // You can update the state accordingly
      // For example, if you want to store the new report data, you can do something like this:

      return {
        ...state,
        urbanfloodNonInmatesRegistrationData: action.payload,
        urbanfloodIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_UPDATE_URBANFLOOD_NON_INMATES_REGISTRATION:
      return {
        ...state,
        urbanfloodNonInmatesRegistrationData: action.payload,
        urbanfloodIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_URBANFLOOD_NON_INMATES_REGISTRATION:
      return {
        ...state,
        urbanfloodNonInmatesRegistrationListingParam: action.payload,
      };

    case GET_EXPORT_NON_CAMP_INMATES_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_NON_CAMP_INMATES_DATA:
      return {
        ...state,
        urbanfloodNonCampInmatesRegistrationExportedData: action.payload,
      };

    default:
      return state;
  }
};

export default urbanfloodNonInmatesRegistration;
