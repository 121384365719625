/**
 * The below file contains the constants used in the Landslide Inmates Registration
 * @author: vishal.mishra@velsof.com
 * @date :22-01-2025
 */

export const GET_ALL_LANDSLIDE_INMATES_REGISTRATION =
  "GET_ALL_LANDSLIDE_INMATES_REGISTRATION";
export const SET_ALL_LANDSLIDE_INMATES_REGISTRATION =
  "SET_ALL_LANDSLIDE_INMATES_REGISTRATION";

export const REPORT_DATE_PARAM = "reportDate";
export const STATUS_PARAM = "status";
export const GET_ALL_CREATE_LANDSLIDE_INMATES_REGISTRATION =
  "GET_ALL_CREATE_LANDSLIDE_INMATES_REGISTRATION";
export const SET_ALL_CREATE_LANDSLIDE_INMATES_REGISTRATION =
  "SET_ALL_CREATE_LANDSLIDE_INMATES_REGISTRATION";

export const GET_ALL_UPDATE_LANDSLIDE_INMATES_REGISTRATION =
  "GET_ALL_UPDATE_LANDSLIDE_INMATES_REGISTRATION";
export const SET_ALL_UPDATE_LANDSLIDE_INMATES_REGISTRATION =
  "SET_ALL_UPDATE_LANDSLIDE_INMATES_REGISTRATION";

export const SET_LANDSLIDE_INMATES_REGISTRATION =
  "SET_LANDSLIDE_INMATES_REGISTRATION";

/**Constants for relief camp Import page */
export const CAMP_INMATES_REGISTRATION_IMPORT =
  "LANDSLIDE_CAMP_INMATES_REGISTRATION_IMPORT";
export const CAMP_INMATES_REGISTRATION_IMPORT_EXCEL =
  "LANDSLIDE_CAMP_INMATES_REGISTRATION_IMPORT_EXCEL";
/**Constants for relief camp Import page */

/**Constants for camp inmates registration export */
export const GET_EXPORT_CAMP_INMATES_DATA =
  "LANDSLIDE_GET_EXPORT_CAMP_INMATES_DATA";
export const SET_EXPORT_CAMP_INMATES_DATA =
  "LANDSLIDE_SET_EXPORT_CAMP_INMATES_DATA";
/**Constants for camp inmates registration import template */
export const GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA =
  "LANDSLIDE_GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA";
export const SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA =
  "LANDSLIDE_SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA";
