import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_ALL_CREATE_LANDSLIDE_NON_INMATES_REGISTRATION,
  SET_ALL_LANDSLIDE_NON_INMATES_REGISTRATION,
  SET_ALL_UPDATE_LANDSLIDE_NON_INMATES_REGISTRATION,
  SET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_LANDSLIDE_NON_INMATES_REGISTRATION,
} from "redux/constants/LandslideNonInmatesRegistration";

// Define an initial state
const initialState = {
  landslideNonInmatesRegistrationData: [],
  landslideIsNonInmatesRegistrationDataFetched: false,
  landslideNonInmatesRegistrationListingParam: {},
  landslideNonCampInmatesRegistrationExportedData: {},
  token: localStorage.getItem(AUTH_TOKEN),
};

// Reducer function
const landslideNonInmatesRegistration = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_LANDSLIDE_NON_INMATES_REGISTRATION:
      return {
        ...state,
        landslideNonInmatesRegistrationData: action.payload,
        landslideIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_CREATE_LANDSLIDE_NON_INMATES_REGISTRATION:
      // Handle the logic to create a new report here
      // You can update the state accordingly
      // For example, if you want to store the new report data, you can do something like this:

      return {
        ...state,
        landslideNonInmatesRegistrationData: action.payload,
        landslideIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_UPDATE_LANDSLIDE_NON_INMATES_REGISTRATION:
      return {
        ...state,
        landslideNonInmatesRegistrationData: action.payload,
        landslideIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_LANDSLIDE_NON_INMATES_REGISTRATION:
      return {
        ...state,
        landslideNonInmatesRegistrationListingParam: action.payload,
      };

    case GET_EXPORT_NON_CAMP_INMATES_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_NON_CAMP_INMATES_DATA:
      return {
        ...state,
        landslideNonCampInmatesRegistrationExportedData: action.payload,
      };

    default:
      return state;
  }
};

export default landslideNonInmatesRegistration;
