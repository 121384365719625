/**
 * The below file is the earthquake service which contains method for doing the API calls.
 * @author: shivam.sharma2@velsof.com
 * @date : 21-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Blob,
  Get_All_Earthquake,
  Get_Export_Earthquake,
  Multipart_Form_Data,
  Earthquake_Import,
  Earthquake_Add_Basic_Section,
  Earthquake_Edit_Basic_Section,
  Earthquake_Get_All_Section,
  Earthquake_Add_InfDamage_Section,
  Earthquake_Add_InfDamageOther_Section,
  Earthquake_Add_Remark_Section,
  Earthquake_Submit_Report,
  Earthquake_Discard_Report,
  Earthquake_Add_PopAffected_Section,
  Earthquake_Add_Eroded_Section,
  Earthquake_Add_ReliefCamp_Section,
  Earthquake_Add_CampInmates_Section,
  Earthquake_Add_HLL_Section,
  Earthquake_Add_LiveStock_Section,
  Earthquake_Add_HouseDamaged_Section,
  Earthquake_Add_RescueOperation_Section,
  Earthquake_Add_ReliefDistribution_Section,
  Earthquake_Fo_Level_Report,
  Earthquake_Cumulative_State_Report,
  Earthquake_Cumulative_District_Report,
  Earthquake_Get_District_Review_List,
  Earthquake_Revenue_Report_Verify,
  Earthquake_Revenue_Report_Disapprove,
  Earthquake_Revenue_Report_Approve,
  Earthquake_Get_State_Review_List,
  Earthquake_District_Report_Approve,
  Earthquake_District_Report_Disapprove,
  Earthquake_District_Report_Verify,
  Earthquake_State_Report_Approve,
  Earthquake_State_Report_Disapprove,
  Earthquake_Add_NonCampInmates_Section,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { GET_EARTHQUAKE_DISTRICT_REVIEW_LIST } from "redux/constants/Earthquake";
import { Earthquake_State_Freeze_Unfreeze_Report_Range } from "../constants/ApiConstant";

const EarthquakeService = {};

//#region Method for listing page.

/**
 * Method for getting the earthquake listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
EarthquakeService.getAllEarthquake = function (param) {
  return fetch({
    url: Get_All_Earthquake,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the earthquake export data
 * @returns Fetch Method
 */
EarthquakeService.getExportEarthquakeData = function () {
  return fetch({
    url: Get_Export_Earthquake,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};
//#endregion Method for listing page.

//#region Method for earthquake import page.

/**
 * Method for importing the earthquake
 * @param {any} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeImport = function (data) {
  return fetch({
    url: Earthquake_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for earthquake import page.

//#region All Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**Method for getting all section data
 * @param {number} id Form Id
 * @param {object} param Param object
 * @returns Fetch Method
 */
EarthquakeService.earthquakeGetAllSection = function (id, param) {
  return fetch({
    url: format(Earthquake_Get_All_Section, id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**Method for submitting the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
EarthquakeService.earthquakeReportSubmit = function (id) {
  return fetch({
    url: format(Earthquake_Submit_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

/**Method for discarding the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
EarthquakeService.earthquakeReportDiscard = function (id) {
  return fetch({
    url: format(Earthquake_Discard_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion All Section

//#region Basic Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for adding the basic section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddBasicSection = function (data) {
  return fetch({
    url: Earthquake_Add_Basic_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for editing the basic section
 * @param {number} id Form Id
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeEditBasicSection = function (id, data) {
  return fetch({
    url: format(Earthquake_Edit_Basic_Section, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Basic Section

//#region InfDamage Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for adding the infDamage section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddInfDamageSection = function (data) {
  return fetch({
    url: Earthquake_Add_InfDamage_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for adding the infDamageOther section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddInfDamageOtherSection = function (data) {
  return fetch({
    url: Earthquake_Add_InfDamageOther_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamageOther Section

//#region Remark Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for adding the remark section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddRemarkSection = function (data) {
  return fetch({
    url: Earthquake_Add_Remark_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Remark Section

//#region Popaffected Section
//@author: shivam.sharma2@velsof.com
//@date : 21-11-2022

/**
 * Method for adding the popaffected section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddPopAffectedSection = function (data) {
  return fetch({
    url: Earthquake_Add_PopAffected_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Popaffected Section

//#region Eroded Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for adding the eroded section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddErodedSection = function (data) {
  return fetch({
    url: Earthquake_Add_Eroded_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion eroded Section

//#region Relief Camp Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022

/**
 * Method for adding the relief camp section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddReliefCampSection = function (data) {
  return fetch({
    url: Earthquake_Add_ReliefCamp_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion relief camp Section

//#region Camp inmates Section
/**
 * Method for adding the camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddCampInmatesSection = function (data) {
  return fetch({
    url: Earthquake_Add_CampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion camp inmates Section

//#region Non Camp inmates Section
//@author: vishal.mishra@velsof.com
//@date : 06-02-2025
/**
 * Method for adding the non camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddNonCampInmatesSection = function (data) {
  return fetch({
    url: Earthquake_Add_NonCampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion non camp inmates Section

//#region Human Live Lost Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022
/**
 * Method for adding the Human Live Lost section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddHLLSection = function (data) {
  return fetch({
    url: Earthquake_Add_HLL_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022
/**
 * Method for adding the LiveStock section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddLiveStockSection = function (data) {
  return fetch({
    url: Earthquake_Add_LiveStock_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion LiveStock Section

//#region House Damaged Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022
/**
 * Method for adding the House Damaged section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddHouseDamagedSection = function (data) {
  return fetch({
    url: Earthquake_Add_HouseDamaged_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion House Damaged Section

//#region Rescue Operation Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022
/**
 * Method for adding the Rescue Operation section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddRescueOperationSection = function (data) {
  return fetch({
    url: Earthquake_Add_RescueOperation_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Rescue Operation Section

//#region Relief Distribution Section
//@author: shivam.sharma2@velsof.com
//@date : 21-12-2022
/**
 * Method for adding the Relief Distribution section
 * @param {object} data API data
 * @returns Fetch Method
 */
EarthquakeService.earthquakeAddReliefDistributionSection = function (data) {
  return fetch({
    url: Earthquake_Add_ReliefDistribution_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Relief Distribution Section

//#region Method for Approve and Disapprove
/**Method for approving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {number} id Form Id
 * @returns Fetch Method
 */
EarthquakeService.earthquakeRevenueReportApprove = function (payload) {
  return fetch({
    url: Earthquake_Revenue_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
EarthquakeService.earthquakeRevenueReportDisapprove = function (payload) {
  return fetch({
    url: Earthquake_Revenue_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
EarthquakeService.earthquakeRevenueReportVerify = function (payload) {
  return fetch({
    url: Earthquake_Revenue_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};
/**
 * Method to approve the district approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} payload Payload
 * @returns Fetch Method
 */
EarthquakeService.earthquakeDistrictReportApprove = function (payload) {
  return fetch({
    url: Earthquake_District_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
EarthquakeService.earthquakeDistrictReportDisapprove = function (payload) {
  return fetch({
    url: Earthquake_District_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
EarthquakeService.earthquakeDistrictReportVerify = function (payload) {
  return fetch({
    url: Earthquake_District_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method to approve the state approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} payload Payload
 * @returns Fetch Method
 */
EarthquakeService.earthquakeStateReportApprove = function (payload) {
  return fetch({
    url: Earthquake_State_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
EarthquakeService.earthquakeStateReportDisapprove = function (payload) {
  return fetch({
    url: Earthquake_State_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for getting the district review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
EarthquakeService.getEarthquakeDistrictReviewList = function (payload) {
  return fetch({
    url: Earthquake_Get_District_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};

/**
 * Method for getting the state review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
EarthquakeService.getEarthquakeStateReviewList = function (payload) {
  return fetch({
    url: Earthquake_Get_State_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};
//#endregion Method for Approve and Disapprove

//#region
/**
 * Below method is for downloading the exported file for viewEarthquake.js
 */
EarthquakeService.getExportFoLevelEarthquakeReport = function (data) {
  return fetch({
    url: Earthquake_Fo_Level_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
EarthquakeService.getExportStateCumulativeEarthquakeReport = function (data) {
  return fetch({
    url: Earthquake_Cumulative_State_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
EarthquakeService.getExportDistrictCumulativeEarthquakeReport = function (
  data
) {
  return fetch({
    url: Earthquake_Cumulative_District_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Method for Freezing/Unfreezing earthquake report for a selected date range
 * @author: vishal.mishra@velsof.com
 * @date : 06-02-2025
 * @param {any} payload Payload
 * @returns Fetch Method
 */
EarthquakeService.earthquakeFreezeReportRange = function (payload) {
  return fetch({
    url: Earthquake_State_Freeze_Unfreeze_Report_Range,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};
//#endregion

export default EarthquakeService;
