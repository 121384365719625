/**The below file contains the common application constants.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 08-11-2022
 */

/**The below constant is for computing whether to show the disaster menu in the page or not.
 * The value will compare in the page url.
 */
export const Disaster_Url_Slugs = {
  InfDamage: "disasters/inf-damage",
  Erosion: "disasters/erosion",
  Landslide: "disasters/landslide",
  UrbanFlood: "disasters/urbanflood",
  Storm: "disasters/storm",
  Lightning: "disasters/lightning",
  Earthquake: "disasters/earthquake",
  Fire: "disasters/fire",
  Flood: "disasters/flood",
};

/**The below constants are used in the Infrastructure damage reporting
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 10-11-2022
 */
export const EmbBreached = "embBreached";
export const EmbAffected = "embAffected";
export const RoadAffected = "road";
export const BridgeAffected = "bridge";

export const BasicSection = "basicSection";
export const PopAffectedSection = "popAffectedSection";
export const ErodedSection = "erodedSection";
export const ReliefCampSection = "reliefCampSection";
export const CampInmatesSection = "campInmatesSection";
export const NonCampInmatesSection = "nonCampInmatesSection";
export const HllSection = "hllSection";
export const LiveStockSection = "liveStockSection";
export const HouseDamagedSection = "houseDamagedSection";
export const RescueOperationSection = "rescueOperationSection";
export const ReliefDistributionSection = "reliefDistributionSection";
export const InfDamageSection = "infDamageSection";
export const InfDamageOtherSection = "infDamageOtherSection";
export const RemarkSection = "remarkSection";
export const SubmitButton = "submitButton";
export const DiscardButton = "discardButton";
export const approveButton = "approveButton";
export const disapproveButton = "disapproveButton";
export const verifyButton = "verifyButton";
export const freezeButton = "freezeButton";

/**
 * @author: vishal.mishra@velsof.com
 *  @date : 10-10-2023
 * Constant for Freeze Status of Report
 */
export const FREEZE_STATUS = {
  TRUE: true,
  FALSE: false,
};

/**
 * Constant for Review Status of Report
 */
export const APPROVAL_STATUS = {
  //Revenue circle level
  RC_PENDING: 1,
  RC_SUBMIT: 2,
  RC_APPROVED: 3,
  RC_REJECTED: 4,

  //District level
  D_REJECTED: 5,
  D_REVIEWED: 6,
  D_APPROVED: 7,

  //State level
  S_REJECTED: 8,
  S_REVIEWED: 9,
  S_APPROVED: 10,

  PENDING_TEXT: "Pending",
  REVIEWED_TEXT: "Reviewed",
  APPROVED_TEXT: "Approved",
  REJECTED_TEXT: "Rejected",
  DRAFT_TEXT: "Draft",

  PENDING_AT_RC_TEXT: "Pending At RC",
  APPROVED_BY_RC_TEXT: "Approved By RC",
  REJECTED_BY_RC_TEXT: "Rejected By RC",

  REVIEWED_BY_DISTRICT_TEXT: "Reviewed By District",
  APPROVED_BY_DISTRICT_TEXT: "Approved By District",
  REJECTED_BY_DISTRICT_TEXT: "Rejected By District",

  REVIEWED_BY_STATE_TEXT: "Reviewed By State",
  APPROVED_BY_STATE_TEXT: "Approved By State",
  REJECTED_BY_STATE_TEXT: "Rejected By State",

  /**
   * @author: shivam.sharma2@velsof.com
   * @date : 10-12-2022
   */
  STATUS_APPROVED: "approved",
  STATUS_DISAPPROVED: "rejected",
  STATUS_VERIFIED: "reviewed",
};

/**
 * Constant for Review levels of Report added by shivam.sharma2@velsof.com
 */
export const APPROVAL_LEVEL = {
  // APPROVAL LEVELS
  LEVEL_RC: "rc",
  LEVEL_DISTRICT: "district",
  LEVEL_STATE: "state",
};

export const LEVEL = {
  FO_LEVEL: "FO_LEVEL",
  CO_LEVEL: "CO_LEVEL",
  DEOC_LEVEL: "DEOC_LEVEL",
  DDMA_LEVEL: "DDMA_LEVEL",
  SEOC_LEVEL: "SEOC_LEVEL",
  CEOASDMA_LEVEL: "CEOASDMA_LEVEL",
  FS_LEVEL: "FS_LEVEL",
  HQCR_LEVEL: "HQCR_LEVEL",
  WING_LEVEL: "WING_LEVEL",
  SPFE_LEVEL: "SPFE_LEVEL",
  DFES_LEVEL: "DFES_LEVEL",
};

//#region CSS Constansts

/**adding constants for filters layout */
export const responsiveColumn = {
  xl: 6,
  lg: 6,
  md: 12,
  sm: 24,
  xs: 24,
};

export const responsive2Column = {
  xl: 12,
  lg: 12,
  md: 12,
  sm: 24,
  xs: 24,
};

export const gutterRow = [12, 12];
//#endregion

/**The below constants are used in the Human Live Lost reporting
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-11-2022
 */
export const Confirmed = "confirmed";
export const Injured = "injured";
export const Missing = "missing";

/**The below constants is used for Sex
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-11-2022
 */
export const Sex = {
  Male_Value: "Male",
  Female_Value: "Female",
  Other_Value: "Other",
  Male_Key: "male",
  Female_Key: "female",
  Other_Key: "other",
};

export const FloodDeath = {
  Flood_Death_Value: "Flood Death",
  General_Drowning_Value: "General Drowning (Non Flood)",
  Flood_Death_Key: "Flood Death",
  General_Drowning_Key: "General Drowning (Non Flood)",
};

/**The below constants is used for Relief Distribution
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 02-12-2022
 */
export const ReliefDistribution = {
  Rice: "Rice",
  Dal: "Dal",
  Salt: "Salt",
  MOil: "M. Oil",
  RiceUnit: "Q",
  DalUnit: "Q",
  SaltUnit: "Q",
  MOilUnit: "L",
  BabyFoodLiquid: "Baby Food Liquid",
  BabyFoodSolid: "Baby Food Solid",
  GreenFooder: "Green Fooder",
  WheatBran: "Wheat Bran",
  RiceBran: "Rice Bran",
  GreenFooderUnit: "Q",
  WheatBranUnit: "Q",
  RiceBranUnit: "Q",
};

/**
 * The below constants are for the units.
 */
export const Units = {
  Bigha: "Bigha",
  Katha: "Katha",
  Lecha: "Lecha",
};

/**
 * Below is the constants for roles.
 */
export const Roles = {
  ADMIN: [3],
  FO: [4],
  CO: [5],
  DEOC: [6],
  DDMA: [7],
  SEOC: [8],
  CEOASDMA: [9],
  FS: [10],
  HQCR: [11],
  WING: [12],
  SPFE: [13],
  DFES: [14],
};

/** For storing the page type to a method defined in commonMethod class
 * @author: shivam.sharma2@velsof.com
 * @date : 14-12-2022
 */

export const PAGE_TYPE = {
  RC: "rc",
  DISTRICT_RC: "district_rc",
  DISTRICT: "district",
  STATE_DISTRICT: "state_district",
  STATE: "state",
};
/**
 * Constants for pagination
 */
export const Pagination = {
  PageSize: 10,
};

//#region For user roles dropdown
/**
 * @author: shivam.sharma2@velsof.com
 * @date : 05-01-2023
 */

export const USER_ROLES = [
  {
    value: 3,
    label: "Administrator",
  },
  {
    value: 4,
    label: "Field Officer",
  },
  {
    value: 5,
    label: "Circle Officer",
  },
  {
    value: 6,
    label: "DEOC District Emergency Operation Center",
  },
  {
    value: 7,
    label: "DC/CEO DDMA",
  },
  {
    value: 8,
    label: "State EOC",
  },
  {
    value: 9,
    label: "CEO ASDMA",
  },
  {
    value: 10,
    label: "Fire Station",
  },
  {
    value: 11,
    label: "HQ Control Room",
  },
  {
    value: 12,
    label: "Wing",
  },
  {
    value: 13,
    label: "SP Fire Emergency",
  },
  {
    value: 14,
    label: "Director Fire Emergency Services (FES)",
  },
];
//#endregion

/**The below constants are used in the Infrastructure damage reporting
 * @author: shivam.sharma2@velsof.com
 * @date : 03-01-2023
 */
export const NoOfOwners = "noOfOwner";
export const NoOfOccupant = "noOfOccupant";

/**The below constants are used in the Fire reporting
 * @author: shivam.sharma2@velsof.com
 * @date : 10-01-2023
 */

export const CallerDetailsSection = "callerDetailsSection";
export const TurnOutDetailsSection = "turnOutDetailsSection";
export const FireDetailsSection = "fireDetailsSection";
export const PropertyInvolvedSection = "propertyInvolvedSection";
export const OwnerOfPropertySection = "ownerOfPropertySection";
export const ESPLSection = "ESPLSection";

/**
 * Fire Category names
 */
export const smallFire = "Small Fire";
export const mediumFire = "Medium Fire";
export const seriousFire = "Serious Fire";
export const majorFire = "Major Fire";

/**
 * For call type in fire foem
 */
export const fireCall = "Fire Call";
export const specialCall = "Special Call";

/**The below constants are used in the Name & Address of Owner of the property & Occupant
 * @author: shivam.sharma2@velsof.com
 * @date : 13-01-2023
 */
export const numberOfOwner = "numberOfOwner";
export const numberOfOccupant = "numberOfOccupant";

//#region
/**
 * @author: shivam.sharma2@gmail.com
 * @date : 28-01-2023
 */
/**
 * Constant for Review Status of Fire Report
 */
export const FIRE_APPROVAL_STATUS = {
  //Fire Station level
  FS_PENDING: 1,
  FS_SUBMIT: 2,

  //HQ Control Room level
  HQCR_REJECTED: 3,
  HQCR_APPROVED: 4,

  //WING level
  WING_REJECTED: 5,
  WING_APPROVED: 6,

  //SP Fire Emergency level
  SFE_REJECTED: 7,
  SFE_APPROVED: 8,

  //Director Fire Emergency Services (FES) level
  DFES_REJECTED: 9,
  DFES_APPROVED: 10,

  PENDING_TEXT: "Pending",
  APPROVED_TEXT: "Approved",
  REJECTED_TEXT: "Rejected",
  DRAFT_TEXT: "Draft",

  PENDING_AT_FS_TEXT: "Pending At Fire Station",

  PENDING_AT_HQCR_TEXT: "Pending At HQ Control Room",
  APPROVED_BY_HQCR_TEXT: "Approved By HQ Control Room",
  REJECTED_BY_HQCR_TEXT: "Rejected By HQ Control Room",

  APPROVED_BY_WING_TEXT: "Approved By Wing",
  REJECTED_BY_WING_TEXT: "Rejected By Wing",

  APPROVED_BY_SFE_TEXT: "Approved By SP Fire Emergency",
  REJECTED_BY_SFE_TEXT: "Rejected By SP Fire Emergency",

  APPROVED_BY_DFES_TEXT: "Approved By Director Fire Emergency Services (FES)",
  REJECTED_BY_DFES_TEXT: "Rejected By Director Fire Emergency Services (FES)",
};
//#endregion

/**
 * The below constants are for the disaster type.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 04-02-2023
 */
export const Disaster_Type = {
  Erosion: "erosion",
  Landslide: "landslide",
  Storm: "storm",
  Lightning: "lightning",
  UrbanFlood: "urbanflood",
  Earthquake: "earthquake",
  Fire: "fire",
  Flood: "flood",
};

/**
 * The below constants are the type of the earthquake report.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-02-2023
 */
export const EarthquakeReportType = {
  New: "new",
  Existing: "existing",
  Aftershock: "aftershock",
};

/**
 * Constant for AES Encryption
 */
export const AES_Encryption = {
  Secret_Key:
    "1390baf9aa039a805526408905039f7503f79f5fd94f9eab0d3fa21d663e04b6",
  Key: "8080808080808080",
  IV: "8080808080808080",
};

/**
 * Constant for the Inmates Registration.
 */
export const Inmates_Registration = {
  Invalid:
    "Data is incorrect, Please correct the data in the highlighted cells.",
  Revenue_Inmates_Header: [
    "Sl. No.",
    "Name of the Relief Camp",
    "Relief Camp Code",
    "Date*",
    "Applicant Phone Number*",
    "Name*",
    "Gender*",
    "Is Physically Disabled (Yes/No)",
    "Is Pregnant Woman (Yes/No)",
    "Is Lactating Mother (Yes/No)",
    "Date of Birth (DD/MM/YYYY) AGE *",
    "Adult/Children *",
    "Document Type (Aadhar/ Voter ID/ PAN)",
    "Document Number",
    "Fathers/Husband Name",
    "Account Holder Name",
    "Account Number",
    "IFSC Code",
    "Bank Name",
    "Bank Branch",
    "Address",
    "House Damage Type",
    "Type Of House",
    "LandSituated_Housing_TreeSurvey",
  ],
  Revenue_Inmates_Document_Type: ["Aadhar", "Voter", "PAN"],
  Revenue_Inmates_Type: ["Male", "Female"],
  Revenue_Is_Physically_Disabled: ["Yes", "No"],
  Revenue_Is_Pregnant_Women: ["Yes", "No"],
  Revenue_Is_Lactating_Mother: ["Yes", "No"],
  Revenue_Is_Children: ["Adult", "Child"],
  Revenue_House_Damage_Type: [
    "Damaged Houses Fully",
    "Damaged Houses Partially",
    "Damaged Hut",
    "Damaged Cattle Shed",
  ],
  Revenue_Type_Of_House: ["Pakka House", "Kachcha House"],
  Revenue_LandSituated_Housing_TreeSurvey: ["Plain Area", "Hilly Area"],
};

/**
 * Constant for the Non Inmates Registration.
 */
export const Non_Inmates_Registration = {
  Invalid:
    "Data is incorrect, Please correct the data in the highlighted cells.",
  Revenue_Inmates_Header: [
    "Sl. No.",
    "Relief Distribution Center Name*",
    "Date*",
    "Applicant Phone Number*",
    "Name*",
    "Gender*",
    "Is Physically Disabled (Yes/No)",
    "Is Pregnant Woman (Yes/No)",
    "Is Lactating Mother (Yes/No)",
    "Date of Birth (DD/MM/YYYY) AGE *",
    "Adult/Children *",
    "Document Type (Aadhar/ Voter ID/ PAN)",
    "Document Number",
    "Fathers/Husband Name",
    "Account Holder Name",
    "Account Number",
    "IFSC Code",
    "Bank Name",
    "Bank Branch",
    "Address",
    "House Damage Type",
    "Type Of House",
    "LandSituated_Housing_TreeSurvey",
  ],
  Revenue_Inmates_Document_Type: ["Aadhar", "Voter", "PAN"],
  Revenue_Inmates_Type: ["Male", "Female"],
  Revenue_Is_Physically_Disabled: ["Yes", "No"],
  Revenue_Is_Pregnant_Women: ["Yes", "No"],
  Revenue_Is_Lactating_Mother: ["Yes", "No"],
  Revenue_Is_Children: ["Adult", "Child"],
  Revenue_House_Damage_Type: [
    "Damaged Houses Fully",
    "Damaged Houses Partially",
    "Damaged Hut",
    "Damaged Cattle Shed",
  ],
  Revenue_Type_Of_House: ["Pakka House", "Kachcha House"],
  Revenue_LandSituated_Housing_TreeSurvey: ["Plain Area", "Hilly Area"],
};

/**
 * Constant for NIL village name
 */
export const NIL_VILLAGE_VALUE = "NIL";
