/**
 * The below file contains the constants used in the urbanflood report.
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */

/**Constants for urbanflood Listing page */
export const GET_ALL_URBANFLOOD = "GET_ALL_URBANFLOOD";
export const SET_URBANFLOOD_LISTING = "SET_URBANFLOOD_LISTING";
export const SET_URBANFLOOD_LISTING_PARAM = "SET_URBANFLOOD_LISTING_PARAM";
export const GET_EXPORT_URBANFLOOD_DATA = "GET_EXPORT_URBANFLOOD_DATA";
export const SET_EXPORT_URBANFLOOD_DATA = "SET_EXPORT_URBANFLOOD_DATA";
/**Constants for urbanflood Listing page */

/**Constants for urbanflood Import page */
export const URBANFLOOD_IMPORT = "URBANFLOOD_IMPORT";
/**Constants for urbanflood Import page */

//#region All Section
/**Constants for all section details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_GET_ALL_SECTION = "URBANFLOOD_GET_ALL_SECTION";
export const URBANFLOOD_SET_ALL_SECTION = "URBANFLOOD_SET_ALL_SECTION";
export const URBANFLOOD_SET_RELIEF_CENTER_SECTION =
  "URBANFLOOD_SET_RELIEF_CENTER_SECTION";
export const URBANFLOOD_RESET_BUTTON_LOADING =
  "URBANFLOOD_RESET_BUTTON_LOADING";
export const URBANFLOOD_SET_PARENT_ID = "URBANFLOOD_SET_PARENT_ID";
export const URBANFLOOD_REPORT_SUBMIT = "URBANFLOOD_REPORT_SUBMIT";
export const URBANFLOOD_REPORT_DISCARD = "URBANFLOOD_REPORT_DISCARD";
export const URBANFLOOD_SET_REDIRECT_TO_LISTING =
  "URBANFLOOD_SET_REDIRECT_TO_LISTING";
export const URBANFLOOD_SET_DATA_SAVED = "URBANFLOOD_SET_DATA_SAVED";
//#endregion All Section

//#region Basic Section
/**Constants for adding the basic section details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_BASIC_SECTION = "URBANFLOOD_ADD_BASIC_SECTION";
export const URBANFLOOD_EDIT_BASIC_SECTION = "URBANFLOOD_EDIT_BASIC_SECTION";
export const URBANFLOOD_SET_NIL_REPORTING = "URBANFLOOD_SET_NIL_REPORTING";
//#endregion Basic Section

//#region InfDamage Section
/**Constants for adding the infrastructure damage details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_INFDAMAGE_SECTION =
  "URBANFLOOD_ADD_INFDAMAGE_SECTION";
//#endregion InfDamage Section

//#region InfDamageOther Section
/**Constants for adding the infrastructure others damage details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_INFDAMAGEOTHER_SECTION =
  "URBANFLOOD_ADD_INFDAMAGEOTHER_SECTION";
//#endregion InfDamageOther Section

//#region Remark Section
/**Constants for adding the Remark details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_REMARK_SECTION = "URBANFLOOD_ADD_REMARK_SECTION";
//#endregion Remark Section

//#region PopAffected Section
/**Constants for adding the population affected details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_POPAFFECTED_SECTION =
  "URBANFLOOD_ADD_POPAFFECTED_SECTION";
//#endregion PopAffected Section

//#region Eroded Section
/**Constants for adding the eroded details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_ERODED_SECTION = "URBANFLOOD_ADD_ERODED_SECTION";
//#endregion Eroded Section

//#region Relief Camp Section
/**Constants for adding the relief camp details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_RELIEFCAMP_SECTION =
  "URBANFLOOD_ADD_RELIEFCAMP_SECTION";
//#endregion relief camp Section

//#region Camp Inmates Section
/**Constants for adding the camp Inmates details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_CAMPINMATES_SECTION =
  "URBANFLOOD_ADD_CAMPINMATES_SECTION";
//#endregion Camp Inmates Section

//#region Camp Non Inmates Section
/**Constants for adding the non camp Inmates details
 * @author: vishal.mishra@velsof.com
 * @date : 10-02-2025
 */
export const URBANFLOOD_ADD_NONCAMPINMATES_SECTION =
  "URBANFLOOD_ADD_NONCAMPINMATES_SECTION";
//#endregion Camp Inmates Section

//#region Human Live Lost Section
/**Constants for adding the Human Live Lost details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_HLL_SECTION = "URBANFLOOD_ADD_HLL_SECTION";
//#endregion Human Live Lost Section

//#region LiveStock Section
/**Constants for adding the LiveStock details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_LIVESTOCK_SECTION =
  "URBANFLOOD_ADD_LIVESTOCK_SECTION";
//#endregion LiveStock Section

//#region HouseDamaged Section
/**Constants for adding the HouseDamaged details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_HOUSEDAMAGED_SECTION =
  "URBANFLOOD_ADD_HOUSEDAMAGED_SECTION";
//#endregion HouseDamaged Section

//#region RescueOperation Section
/**Constants for adding the RescueOperation details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_RESCUEOPERATION_SECTION =
  "URBANFLOOD_ADD_RESCUEOPERATION_SECTION";
//#endregion RescueOperation Section

//#region ReliefDistribution Section
/**Constants for adding the ReliefDistribution details
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
export const URBANFLOOD_ADD_RELIEFDISTRIBUTION_SECTION =
  "URBANFLOOD_ADD_RELIEFDISTRIBUTION_SECTION";
//#endregion ReliefDistribution Section

/**Constants for adding the approve & disapprove constants
 * @author: shivam.sharma2@velsof.com
 * @date : 28-12-2022
 */
//#region Report and Approval and Disapproval
export const URBANFLOOD_REVENUE_REPORT_APPROVE =
  "URBANFLOOD_REVENUE_REPORT_APPROVE";
export const URBANFLOOD_REVENUE_REPORT_DISAPPROVE =
  "URBANFLOOD_REVENUE_REPORT_DISAPPROVE";
export const URBANFLOOD_REVENUE_REPORT_VERIFY =
  "URBANFLOOD_REVENUE_REPORT_VERIFY";

export const EXPORT_FO_LEVEL_URBANFLOOD_REPORT =
  "EXPORT_FO_LEVEL_URBANFLOOD_REPORT";
export const SET_EXPORT_FO_LEVEL_URBANFLOOD_REPORT =
  "SET_EXPORT_FO_LEVEL_URBANFLOOD_REPORT";

export const URBANFLOOD_DISTRICT_REPORT_APPROVE =
  "URBANFLOOD_DISTRICT_REPORT_APPROVE";
export const URBANFLOOD_DISTRICT_REPORT_DISAPPROVE =
  "URBANFLOOD_DISTRICT_REPORT_DISAPPROVE";
export const URBANFLOOD_DISTRICT_REPORT_VERIFY =
  "URBANFLOOD_DISTRICT_REPORT_VERIFY";

export const URBANFLOOD_STATE_REPORT_APPROVE =
  "URBANFLOOD_STATE_REPORT_APPROVE";
export const URBANFLOOD_STATE_REPORT_DISAPPROVE =
  "URBANFLOOD_STATE_REPORT_DISAPPROVE";

export const GET_URBANFLOOD_DISTRICT_REVIEW_LIST =
  "GET_URBANFLOOD_DISTRICT_REVIEW_LIST";
export const SET_URBANFLOOD_DISTRICT_REVIEW_LIST =
  "SET_URBANFLOOD_DISTRICT_REVIEW_LIST";
export const GET_URBANFLOOD_STATE_REVIEW_LIST =
  "GET_URBANFLOOD_STATE_REVIEW_LIST";
export const SET_URBANFLOOD_STATE_REVIEW_LIST =
  "SET_URBANFLOOD_STATE_REVIEW_LIST";

export const SET_DISTRICT_URBANFLOOD_REPORT = "SET_DISTRICT_URBANFLOOD_REPORT";
export const GET_DISTRICT_URBANFLOOD_REPORT = "GET_DISTRICT_URBANFLOOD_REPORT";
export const GET_STATE_CUMULATIVE_URBANFLOOD_REPORT =
  "GET_STATE_CUMULATIVE_URBANFLOOD_REPORT";
export const SET_STATE_CUMULATIVE_URBANFLOOD_REPORT =
  "SET_STATE_CUMULATIVE_URBANFLOOD_REPORT";
export const GET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT =
  "GET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT";
export const SET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT =
  "SET_DISTRICT_CUMULATIVE_URBANFLOOD_REPORT";

//#region for storm form freeze unfreeze report for a selected Range
export const FREEZE_UNFREEZE_URBANFLOOD_RANGE_REPORT =
  "FREEZE_UNFREEZE_URBANFLOOD_RANGE_REPORT";
//#endregion Report and Approval and Disapproval
