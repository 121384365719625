/**
 * The below file is the lightning service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 26-12-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Blob,
  Get_All_Lightning,
  Get_Export_Lightning,
  Multipart_Form_Data,
  Lightning_Import,
  Lightning_Add_Basic_Section,
  Lightning_Edit_Basic_Section,
  Lightning_Get_All_Section,
  Lightning_Add_InfDamage_Section,
  Lightning_Add_InfDamageOther_Section,
  Lightning_Add_Remark_Section,
  Lightning_Submit_Report,
  Lightning_Discard_Report,
  Lightning_Add_PopAffected_Section,
  Lightning_Add_Eroded_Section,
  Lightning_Add_ReliefCamp_Section,
  Lightning_Add_CampInmates_Section,
  Lightning_Add_HLL_Section,
  Lightning_Add_LiveStock_Section,
  Lightning_Add_HouseDamaged_Section,
  Lightning_Add_RescueOperation_Section,
  Lightning_Add_ReliefDistribution_Section,
  Lightning_Fo_Level_Report,
  Lightning_Cumulative_State_Report,
  Lightning_Cumulative_District_Report,
  Lightning_Get_District_Review_List,
  Lightning_Revenue_Report_Verify,
  Lightning_Revenue_Report_Disapprove,
  Lightning_Revenue_Report_Approve,
  Lightning_Get_State_Review_List,
  Lightning_District_Report_Approve,
  Lightning_District_Report_Disapprove,
  Lightning_District_Report_Verify,
  Lightning_State_Report_Approve,
  Lightning_State_Report_Disapprove,
  Lightning_Add_NonCampInmates_Section,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { Lightning_State_Freeze_Unfreeze_Report_Range } from "../constants/ApiConstant";

const LightningService = {};

//#region Method for listing page.

/**
 * Method for getting the lightning listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
LightningService.getAllLightning = function (param) {
  return fetch({
    url: Get_All_Lightning,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the lightning export data
 * @returns Fetch Method
 */
LightningService.getExportLightningData = function () {
  return fetch({
    url: Get_Export_Lightning,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};
//#endregion Method for listing page.

//#region Method for lightning import page.

/**
 * Method for importing the lightning
 * @param {any} data API data
 * @returns Fetch Method
 */
LightningService.lightningImport = function (data) {
  return fetch({
    url: Lightning_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for lightning import page.

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**Method for getting all section data
 * @param {number} id Form Id
 * @param {object} param Param object
 * @returns Fetch Method
 */
LightningService.lightningGetAllSection = function (id, param) {
  return fetch({
    url: format(Lightning_Get_All_Section, id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**Method for submitting the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
LightningService.lightningReportSubmit = function (id) {
  return fetch({
    url: format(Lightning_Submit_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

/**Method for discarding the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
LightningService.lightningReportDiscard = function (id) {
  return fetch({
    url: format(Lightning_Discard_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the basic section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddBasicSection = function (data) {
  return fetch({
    url: Lightning_Add_Basic_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for editing the basic section
 * @param {number} id Form Id
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningEditBasicSection = function (id, data) {
  return fetch({
    url: format(Lightning_Edit_Basic_Section, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Basic Section

//#region InfDamage Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the infDamage section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddInfDamageSection = function (data) {
  return fetch({
    url: Lightning_Add_InfDamage_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the infDamageOther section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddInfDamageOtherSection = function (data) {
  return fetch({
    url: Lightning_Add_InfDamageOther_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamageOther Section

//#region Remark Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the remark section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddRemarkSection = function (data) {
  return fetch({
    url: Lightning_Add_Remark_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Remark Section

//#region Popaffected Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the popaffected section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddPopAffectedSection = function (data) {
  return fetch({
    url: Lightning_Add_PopAffected_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Popaffected Section

//#region Eroded Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the eroded section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddErodedSection = function (data) {
  return fetch({
    url: Lightning_Add_Eroded_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion eroded Section

//#region Relief Camp Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for adding the relief camp section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddReliefCampSection = function (data) {
  return fetch({
    url: Lightning_Add_ReliefCamp_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion relief camp Section

//#region Camp inmates Section
/**
 * Method for adding the camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddCampInmatesSection = function (data) {
  return fetch({
    url: Lightning_Add_CampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion camp inmates Section

//#region Non Camp inmates Section
//@author: vishal.mishra@velsof.com
//@date : 29-01-2025
/**
 * Method for adding the non camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddNonCampInmatesSection = function (data) {
  return fetch({
    url: Lightning_Add_NonCampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion non camp inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the Human Live Lost section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddHLLSection = function (data) {
  return fetch({
    url: Lightning_Add_HLL_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the LiveStock section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddLiveStockSection = function (data) {
  return fetch({
    url: Lightning_Add_LiveStock_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion LiveStock Section

//#region House Damaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the House Damaged section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddHouseDamagedSection = function (data) {
  return fetch({
    url: Lightning_Add_HouseDamaged_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion House Damaged Section

//#region Rescue Operation Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the Rescue Operation section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddRescueOperationSection = function (data) {
  return fetch({
    url: Lightning_Add_RescueOperation_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Rescue Operation Section

//#region Relief Distribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022
/**
 * Method for adding the Relief Distribution section
 * @param {object} data API data
 * @returns Fetch Method
 */
LightningService.lightningAddReliefDistributionSection = function (data) {
  return fetch({
    url: Lightning_Add_ReliefDistribution_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Relief Distribution Section

//#region Method for Approve and Disapprove
/**Method for approving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {number} id Form Id
 * @returns Fetch Method
 */
LightningService.lightningRevenueReportApprove = function (payload) {
  return fetch({
    url: Lightning_Revenue_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
LightningService.lightningRevenueReportDisapprove = function (payload) {
  return fetch({
    url: Lightning_Revenue_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
LightningService.lightningRevenueReportVerify = function (payload) {
  return fetch({
    url: Lightning_Revenue_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};
/**
 * Method to approve the district approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} payload Payload
 * @returns Fetch Method
 */
LightningService.lightningDistrictReportApprove = function (payload) {
  return fetch({
    url: Lightning_District_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
LightningService.lightningDistrictReportDisapprove = function (payload) {
  return fetch({
    url: Lightning_District_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
LightningService.lightningDistrictReportVerify = function (payload) {
  return fetch({
    url: Lightning_District_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method to approve the state approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {object} payload Payload
 * @returns Fetch Method
 */
LightningService.lightningStateReportApprove = function (payload) {
  return fetch({
    url: Lightning_State_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
LightningService.lightningStateReportDisapprove = function (payload) {
  return fetch({
    url: Lightning_State_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for getting the district review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
LightningService.getLightningDistrictReviewList = function (payload) {
  return fetch({
    url: Lightning_Get_District_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};

/**
 * Method for getting the state review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
LightningService.getLightningStateReviewList = function (payload) {
  return fetch({
    url: Lightning_Get_State_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};
//#endregion Method for Approve and Disapprove

//#region
/**
 * Below method is for downloading the exported file for viewLightning.js
 */
LightningService.getExportFoLevelLightningReport = function (data) {
  return fetch({
    url: Lightning_Fo_Level_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
LightningService.getExportStateCumulativeLightningReport = function (data) {
  return fetch({
    url: Lightning_Cumulative_State_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
LightningService.getExportDistrictCumulativeLightningReport = function (data) {
  return fetch({
    url: Lightning_Cumulative_District_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Method for Freezing/Unfreezing flood report for a selected date range
 * @author: vishal.mishra@velsof.com
 * @date : 03-04-2024
 * @param {any} payload Payload
 * @returns Fetch Method
 */
LightningService.lightningFreezeReportRange = function (payload) {
  return fetch({
    url: Lightning_State_Freeze_Unfreeze_Report_Range,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};
//#endregion

export default LightningService;
