/**
 * The below file is the Erosion Non Inmates Registration service which contains method for doing the API calls.
 * @author: vishal.mishra@velsof.com
 * @date : 01-04-2024
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Erosion_Create_Non_Inmates_Registration,
  Erosion_Export_Non_Inmates_Registration,
  Erosion_Import_Non_Inmates_Registration,
  Multipart_Form_Data,
  Blob,
  Erosion_Import_Non_Inmates_Registration_Xlsx,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const ErosionNonInmatesRegistrationServices = {};

//#region Method for Erosion Freeze Report listing page.

/**
 * Method for getting the Erosion Freeze Report listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
ErosionNonInmatesRegistrationServices.getAll = function (param) {
  return fetch({
    url: Erosion_Create_Non_Inmates_Registration,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

//#region Method for Erosion Freeze Report .

/**
 * Method for importing the Erosion Freeze Report
 * @param {any} data API Data
 * @returns Fetch Method
 */
ErosionNonInmatesRegistrationServices.create = function (data) {
  return fetch({
    url: Erosion_Create_Non_Inmates_Registration,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for Erosion Freeze Report .

/**
 * Method for importing the Erosion Freeze Report
 * @param {any} data API Data
 * @returns Fetch Method
 */
ErosionNonInmatesRegistrationServices.update = function (data, id) {
  return fetch({
    url: Erosion_Create_Non_Inmates_Registration + `/${id}`,
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for Erosion Freeze Report .

/**
 * Method for importing the Non Camp Inmates Registration
 * @param {any} data API data
 * @returns Fetch Method
 */
ErosionNonInmatesRegistrationServices.nonCampInmatesRegistrationImport =
  function (data) {
    return fetch({
      url: Erosion_Import_Non_Inmates_Registration,
      method: "post",
      headers: {
        "Content-Type": Application_Json,
        Authorization: format(
          Authorization_Token,
          localStorage.getItem(AUTH_TOKEN)
        ),
      },
      data: data,
    });
  };

/**
 * Method for importing the Non Camp Inmates Registration Excel
 * @param {any} data API data
 * @returns Fetch Method
 */
ErosionNonInmatesRegistrationServices.nonCampInmatesRegistrationImportExcel =
  function (data) {
    return fetch({
      url: Erosion_Import_Non_Inmates_Registration_Xlsx,
      method: "post",
      headers: {
        "Content-Type": Application_Json,
        Authorization: format(
          Authorization_Token,
          localStorage.getItem(AUTH_TOKEN)
        ),
      },
      data: data,
    });
  };

/**
 * Method for getting the Non Camp Inmates Registration export data
 * @returns Fetch Method
 */
ErosionNonInmatesRegistrationServices.getExportNonInmatesRegistrationData =
  function (payload) {
    return fetch({
      url: Erosion_Export_Non_Inmates_Registration,
      method: "post",
      headers: {
        "Content-Type": Application_Json,
        Authorization: format(
          Authorization_Token,
          localStorage.getItem(AUTH_TOKEN)
        ),
      },
      data: payload,
      responseType: Blob,
    });
  };

export default ErosionNonInmatesRegistrationServices;
