/**
 * The below file contains the landslide reports state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 07-12-2022
 */

import {
  BasicSection,
  CampInmatesSection,
  DiscardButton,
  ErodedSection,
  HllSection,
  HouseDamagedSection,
  InfDamageOtherSection,
  InfDamageSection,
  LiveStockSection,
  PopAffectedSection,
  ReliefCampSection,
  ReliefDistributionSection,
  RemarkSection,
  RescueOperationSection,
  SubmitButton,
} from "constants/ApplicationConstant";
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_LANDSLIDE_DATA,
  LANDSLIDE_ADD_BASIC_SECTION,
  LANDSLIDE_ADD_INFDAMAGEOTHER_SECTION,
  LANDSLIDE_ADD_INFDAMAGE_SECTION,
  LANDSLIDE_ADD_REMARK_SECTION,
  LANDSLIDE_EDIT_BASIC_SECTION,
  LANDSLIDE_REPORT_DISCARD,
  LANDSLIDE_REPORT_SUBMIT,
  LANDSLIDE_RESET_BUTTON_LOADING,
  LANDSLIDE_SET_ALL_SECTION,
  LANDSLIDE_SET_DATA_SAVED,
  LANDSLIDE_SET_NIL_REPORTING,
  LANDSLIDE_SET_PARENT_ID,
  LANDSLIDE_SET_REDIRECT_TO_LISTING,
  SET_EXPORT_LANDSLIDE_DATA,
  SET_LANDSLIDE_LISTING,
  SET_LANDSLIDE_LISTING_PARAM,
  LANDSLIDE_ADD_POPAFFECTED_SECTION,
  LANDSLIDE_ADD_ERODED_SECTION,
  LANDSLIDE_ADD_CAMPINMATES_SECTION,
  LANDSLIDE_ADD_RELIEFCAMP_SECTION,
  LANDSLIDE_ADD_HLL_SECTION,
  LANDSLIDE_ADD_LIVESTOCK_SECTION,
  LANDSLIDE_ADD_HOUSEDAMAGED_SECTION,
  LANDSLIDE_ADD_RELIEFDISTRIBUTION_SECTION,
  LANDSLIDE_ADD_RESCUEOPERATION_SECTION,
  SET_LANDSLIDE_DISTRICT_REVIEW_LIST,
  SET_LANDSLIDE_STATE_REVIEW_LIST,
  LANDSLIDE_REVENUE_REPORT_APPROVE,
  LANDSLIDE_REVENUE_REPORT_DISAPPROVE,
  LANDSLIDE_REVENUE_REPORT_VERIFY,
  LANDSLIDE_DISTRICT_REPORT_APPROVE,
  LANDSLIDE_DISTRICT_REPORT_DISAPPROVE,
  LANDSLIDE_DISTRICT_REPORT_VERIFY,
  LANDSLIDE_STATE_REPORT_APPROVE,
  LANDSLIDE_STATE_REPORT_DISAPPROVE,
  SET_EXPORT_FO_LEVEL_LANDSLIDE_REPORT,
  SET_STATE_CUMULATIVE_LANDSLIDE_REPORT,
  SET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT,
} from "redux/constants/Landslide";
import { NonCampInmatesSection } from "../../constants/ApplicationConstant";
import {
  LANDSLIDE_ADD_NONCAMPINMATES_SECTION,
  LANDSLIDE_SET_RELIEF_CENTER_SECTION,
  FREEZE_UNFREEZE_LANDSLIDE_RANGE_REPORT,
} from "../constants/Landslide";

//Initial state
const initState = {
  //Keys for listing
  landslideList: {},
  isLandslideDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  landslideListingParam: {},
  landslideExportedData: {},
  exportFoLevelLandslideReport: {
    report: "",
    format: "",
  },
  exportStateCumulativeLandslideReport: {
    report: "",
    format: "",
  },
  exportDistrictCumulativeLandslideReport: {
    report: "",
    format: "",
  },
  //Keys for listing

  //Keys for All Section Data
  landslideParentId: "",
  landslideAllSectionData: {},
  landslideReliefCenterData: [],
  landslideButtonLoading: {
    basicSection: false,
    popAffectedSection: false,
    erodedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    nonCampInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
    submitButton: false,
    discardButton: false,
    approveButton: false,
    disapproveButton: false,
    verifyButton: false,
  },
  landslideDataSaved: {
    basicSection: false,
    popAffectedSection: false,
    erodedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    nonCampInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
  },
  landslideIsNilReporting: false,
  landslideRedirectToListing: false,
  //Keys for All Section Data

  //Keys for Approval Disapproval
  landslideDistrictReviewList: {},
  landslideStateReviewList: {},
  //Keys for Approval Disapproval

  //keys for Flood Form Freeze Report Range
  landslideFreezeReportRange: {},
  //keys for Flood Form Freeze Report Range
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const landslide = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_LANDSLIDE_LISTING:
      return {
        ...state,
        landslideList: action.payload,
        isLandslideDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_LANDSLIDE_LISTING_PARAM:
      return {
        ...state,
        landslideListingParam: action.payload,
      };

    case GET_EXPORT_LANDSLIDE_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_LANDSLIDE_DATA:
      return {
        ...state,
        landslideExportedData: action.payload,
      };
    //Cases for listing

    //Cases for All Section
    case LANDSLIDE_SET_ALL_SECTION:
      return {
        ...state,
        landslideAllSectionData: action.payload,
      };

    case LANDSLIDE_SET_RELIEF_CENTER_SECTION:
      return {
        ...state,
        landslideReliefCenterData: action.payload,
      };

    case LANDSLIDE_RESET_BUTTON_LOADING:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            basicSection: false,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            popAffectedSection: false,
          },
        };
      } else if (action.payload.section === ErodedSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            erodedSection: false,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            reliefCampSection: false,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            campInmatesSection: false,
          },
        };
      } else if (action.payload.section === NonCampInmatesSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            nonCampInmatesSection: false,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            hLLSection: false,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            liveStockSection: false,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            houseDamagedSection: false,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            rescueOperationSection: false,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            reliefDistributionSection: false,
          },
        };
      } else if (action.payload.section === InfDamageSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            infDamageSection: false,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            infDamageOtherSection: false,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            remarkSection: false,
          },
        };
      } else if (action.payload.section === SubmitButton) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            submitButton: false,
          },
        };
      } else if (action.payload.section === DiscardButton) {
        return {
          ...state,
          landslideButtonLoading: {
            ...state.landslideButtonLoading,
            discardButton: false,
          },
        };
      } else {
        return {
          ...state,
          landslideButtonLoading: {
            basicSection: false,
            popAffectedSection: false,
            erodedSection: false,
            reliefCampSection: false,
            campInmatesSection: false,
            nonCampInmatesSection: false,
            hLLSection: false,
            liveStockSection: false,
            houseDamagedSection: false,
            rescueOperationSection: false,
            reliefDistributionSection: false,
            infDamageSection: false,
            infDamageOtherSection: false,
            remarkSection: false,
            submitButton: false,
            discardButton: false,
            approveButton: false,
            disapproveButton: false,
            verifyButton: false,
          },
        };
      }

    case LANDSLIDE_SET_PARENT_ID:
      return {
        ...state,
        landslideParentId: action.payload,
      };

    case LANDSLIDE_SET_REDIRECT_TO_LISTING:
      return {
        ...state,
        landslideRedirectToListing: action.payload,
      };

    case LANDSLIDE_SET_DATA_SAVED:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            basicSection: action.payload.value,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            popAffectedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ErodedSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            erodedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            reliefCampSection: action.payload.value,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            campInmatesSection: action.payload.value,
          },
        };
      } else if (action.payload.section === NonCampInmatesSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            nonCampInmatesSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            hLLSection: action.payload.value,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            liveStockSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            houseDamagedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            rescueOperationSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            reliefDistributionSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            infDamageSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            infDamageOtherSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          landslideDataSaved: {
            ...state.landslideDataSaved,
            remarkSection: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case LANDSLIDE_REPORT_SUBMIT:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          submitButton: true,
        },
      };

    case LANDSLIDE_REVENUE_REPORT_APPROVE:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          approveButton: true,
        },
      };

    case LANDSLIDE_REVENUE_REPORT_DISAPPROVE:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          disapproveButton: true,
        },
      };

    case LANDSLIDE_REVENUE_REPORT_VERIFY:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          verifyButton: true,
        },
      };
    //#endregion

    case LANDSLIDE_REPORT_DISCARD:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          discardButton: true,
        },
      };

    case LANDSLIDE_DISTRICT_REPORT_APPROVE:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          approveButton: true,
        },
      };

    case LANDSLIDE_DISTRICT_REPORT_DISAPPROVE:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          disapproveButton: true,
        },
      };

    case LANDSLIDE_DISTRICT_REPORT_VERIFY:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          verifyButton: true,
        },
      };

    case LANDSLIDE_STATE_REPORT_APPROVE:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          approveButton: true,
        },
      };

    case LANDSLIDE_STATE_REPORT_DISAPPROVE:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          disapproveButton: true,
        },
      };
    //Cases for All Section

    //Cases for Basic Section
    case LANDSLIDE_ADD_BASIC_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          basicSection: true,
        },
      };

    case LANDSLIDE_EDIT_BASIC_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          basicSection: true,
        },
      };

    case LANDSLIDE_SET_NIL_REPORTING:
      return {
        ...state,
        landslideIsNilReporting: action.payload,
      };
    //Cases for Basic Section

    //Cases for InfDamage Section
    case LANDSLIDE_ADD_INFDAMAGE_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          infDamageSection: true,
        },
      };
    //Cases for InfDamage Section

    //Cases for InfDamageOther Section
    case LANDSLIDE_ADD_INFDAMAGEOTHER_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          infDamageOtherSection: true,
        },
      };

    //Cases for Remark Section
    case LANDSLIDE_ADD_REMARK_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          remarkSection: true,
        },
      };

    //Cases for Landslide Pop Affected Section
    case LANDSLIDE_ADD_POPAFFECTED_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          popAffectedSection: true,
        },
      };
    //Cases for Landslide Pop Affected Section

    //Cases for Landslide Eroded Section
    case LANDSLIDE_ADD_ERODED_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          erodedSection: true,
        },
      };
    //Cases for Landslide Eroded Section

    //Cases for Landslide Relief Camp Section
    case LANDSLIDE_ADD_RELIEFCAMP_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          reliefCampSection: true,
        },
      };
    //Cases for Landslide Relief Camp Section

    //Cases for Landslide Relief Camp Section
    case LANDSLIDE_ADD_CAMPINMATES_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          campInmatesSection: true,
        },
      };
    //Cases for Landslide Relief Camp Section

    //Cases for Erosion Relief Camp Section
    case LANDSLIDE_ADD_NONCAMPINMATES_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          nonCampInmatesSection: true,
        },
      };
    //Cases for Flood Relief Camp Section

    //Cases for Landslide Hll Section
    case LANDSLIDE_ADD_HLL_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          hLLSection: true,
        },
      };
    //Cases for Landslide Hll Section

    //Cases for Landslide LiveStock Section
    case LANDSLIDE_ADD_LIVESTOCK_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          liveStockSection: true,
        },
      };
    //Cases for Landslide LiveStock Section

    //Cases for Landslide House Damaged Section
    case LANDSLIDE_ADD_HOUSEDAMAGED_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          houseDamagedSection: true,
        },
      };
    //Cases for Landslide House Damaged Section

    //Cases for Landslide Rescue Operation Section
    case LANDSLIDE_ADD_RESCUEOPERATION_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          rescueOperationSection: true,
        },
      };
    //Cases for Landslide Rescue Operation Section

    //Cases for Landslide Relief Distribution Section
    case LANDSLIDE_ADD_RELIEFDISTRIBUTION_SECTION:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          reliefDistributionSection: true,
        },
      };
    //Cases for Landslide Relief Distribution Section

    // Cases for Landslide form PDF download section
    case SET_EXPORT_FO_LEVEL_LANDSLIDE_REPORT:
      return {
        ...state,
        exportFoLevelLandslideReport: {
          report: action.payload.url,
          format: action.payload.format,
        },
      };
    // Cases for Landslide form export file section

    //Cases for Approval and Disapproval
    case SET_LANDSLIDE_DISTRICT_REVIEW_LIST:
      return {
        ...state,
        landslideDistrictReviewList: action.payload,
      };

    case SET_LANDSLIDE_STATE_REVIEW_LIST:
      return {
        ...state,
        landslideStateReviewList: action.payload,
      };
    //Cases for Approval and Disapproval

    // Cases for Landslide state cumulative report
    case SET_STATE_CUMULATIVE_LANDSLIDE_REPORT:
      return {
        ...state,
        exportStateCumulativeLandslideReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Landslide state cumulative report

    // Cases for Landslide district cumulative report
    case SET_DISTRICT_CUMULATIVE_LANDSLIDE_REPORT:
      return {
        ...state,
        exportDistrictCumulativeLandslideReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Landslide district cumulative report

    // Cases for Flood Freeze report for a selected range

    case FREEZE_UNFREEZE_LANDSLIDE_RANGE_REPORT:
      return {
        ...state,
        landslideButtonLoading: {
          ...state.landslideButtonLoading,
          landslideFreezeButton: true,
        },
        landslideFreezeReportRange: action.payload,
      };
    // Cases for Flood Freeze report for a selected range

    default:
      return state;
  }
};

export default landslide;
