import {
  BarsOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  FormOutlined,
  FileDoneOutlined,
  FileOutlined,
  UserOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { Disaster_Url_Slugs, Roles } from "constants/ApplicationConstant";
import { LOGIN_USER } from "redux/constants/Auth";

/**
 * The below menu will be shown first after login
 */
const menuNavTree = [
  {
    key: "app",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.app",
    icon: "",
    breadcrumb: true,
    visible: true,
    submenu: [
      // {
      //   key: "map",
      //   path: `${APP_PREFIX_PATH}/map`,
      //   title: "sidenav.map",
      //   icon: EnvironmentOutlined,
      //   breadcrumb: true,
      //   visible: true,
      //   submenu: [],
      // },
      {
        key: "disasters~listing",
        path: `${APP_PREFIX_PATH}/disasters/listing`,
        title: "sidenav.disasters.listing",
        icon: HomeOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
      {
        key: "masters",
        path: `${APP_PREFIX_PATH}/masters`,
        title: "sidenav.masters",
        icon: BarsOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "masters~district~listing",
            path: `${APP_PREFIX_PATH}/masters/district/listing`,
            title: "sidenav.masters.district.listing",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "masters~block~listing",
            path: `${APP_PREFIX_PATH}/masters/block/listing`,
            title: "sidenav.masters.block.listing",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "masters~village~listing",
            path: `${APP_PREFIX_PATH}/masters/village/listing`,
            title: "sidenav.masters.village.listing",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "masters~relief-camp~listing",
            path: `${APP_PREFIX_PATH}/masters/relief-camp/listing`,
            title: "sidenav.masters.relief-camp.listing",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "masters~inf-department~listing",
            path: `${APP_PREFIX_PATH}/masters/inf-department/listing`,
            title: "sidenav.masters.inf-department.listing",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "user",
        path: `${APP_PREFIX_PATH}/user`,
        title: "sidenav.user",
        icon: UserOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "user~listing",
            path: `${APP_PREFIX_PATH}/masters/user/listing`,
            title: "sidenav.user.listing",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "resource",
        path: `${APP_PREFIX_PATH}/resource`,
        title: "sidenav.resource",
        icon: HomeOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
    ],
  },
];

/**
 * The below array will be used for showing the menu of Inf Damage
 */
const menuInfDamageTree = [
  {
    key: "app",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.app",
    icon: "",
    breadcrumb: false,
    submenu: [
      {
        key: "disasters~listing",
        path: `${APP_PREFIX_PATH}/disasters/listing`,
        title: "sidenav.disasters.listing",
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "disasters~inf-damage~listing",
        path: `${APP_PREFIX_PATH}/disasters/inf-damage/listing`,
        title: "sidenav.disasters.inf-damage.listing",
        icon: FormOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

/**
 * The below array will be used for showing the menu of Erosion
 */
const menuErosionTree = [
  {
    key: "app",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.app",
    icon: "",
    breadcrumb: false,
    visible: true,
    submenu: [
      {
        key: "disasters~listing",
        path: `${APP_PREFIX_PATH}/disasters/listing`,
        title: "sidenav.disasters.listing",
        icon: HomeOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~erosion~map",
        path: `${APP_PREFIX_PATH}/disasters/erosion/map`,
        title: "sidenav.disasters.erosion.map",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~erosion~dashboard",
        path: `${APP_PREFIX_PATH}/disasters/erosion/dashboard`,
        title: "sidenav.disasters.erosion.dashboard",
        icon: BarChartOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~erosion~listing",
        path: `${APP_PREFIX_PATH}/disasters/erosion/listing`,
        title: "sidenav.disasters.erosion.listing",
        icon: FormOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~erosion~review",
        path: `${APP_PREFIX_PATH}/disasters/erosion/review`,
        title: "sidenav.disasters.erosion.review",
        icon: FileDoneOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~erosion~review~revenue",
            path: `${APP_PREFIX_PATH}/disasters/erosion/review/revenue`,
            title: "sidenav.disasters.erosion.review.revenue",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~erosion~review~district",
            path: `${APP_PREFIX_PATH}/disasters/erosion/review/district`,
            title: "sidenav.disasters.erosion.review.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~erosion~review~state",
            path: `${APP_PREFIX_PATH}/disasters/erosion/review/state`,
            title: "sidenav.disasters.erosion.review.state",
            icon: "",
            visible: true,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~erosion~report",
        path: `${APP_PREFIX_PATH}/disasters/erosion/report`,
        title: "sidenav.disasters.erosion.report",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~erosion~report~district",
            path: `${APP_PREFIX_PATH}/disasters/erosion/report/district`,
            title: "sidenav.disasters.erosion.report.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~erosion~report~state",
            path: `${APP_PREFIX_PATH}/disasters/erosion/report/state`,
            title: "sidenav.disasters.erosion.report.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~erosion~user-registration",
        path: `${APP_PREFIX_PATH}/disasters/erosion/user-registration`,
        title: "sidenav.disasters.erosion.user-registration",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~erosion~user-registration~revenue~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/erosion/user-registration/revenue/camp-inmates`,
            title:
              "sidenav.disasters.erosion.report.user-registration.revenue.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~erosion~user-registration~revenue~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/erosion/user-registration/revenue/noncamp-inmates`,
            title:
              "sidenav.disasters.erosion.report.user-registration.revenue.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~erosion~user-registration~district~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/erosion/user-registration/district/camp-inmates`,
            title:
              "sidenav.disasters.erosion.report.user-registration.district.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~erosion~user-registration~district~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/erosion/user-registration/district/noncamp-inmates`,
            title:
              "sidenav.disasters.erosion.report.user-registration.district.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~erosion~user-registration~state~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/erosion/user-registration/state/camp-inmates`,
            title:
              "sidenav.disasters.erosion.report.user-registration.state.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~erosion~user-registration~state~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/erosion/user-registration/state/noncamp-inmates`,
            title:
              "sidenav.disasters.erosion.report.user-registration.state.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~erosion~freeze-report",
        path: `${APP_PREFIX_PATH}/disasters/erosion/report-freezing`,
        title: "sidenav.disasters.erosion.freeze",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
    ],
  },
];

/**
 * The below array will be used for showing the menu of Landslide
 */
const menuLandslideTree = [
  {
    key: "app",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.app",
    icon: "",
    breadcrumb: false,
    visible: true,
    submenu: [
      {
        key: "disasters~listing",
        path: `${APP_PREFIX_PATH}/disasters/listing`,
        title: "sidenav.disasters.listing",
        icon: HomeOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~landslide~map",
        path: `${APP_PREFIX_PATH}/disasters/landslide/map`,
        title: "sidenav.disasters.landslide.map",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~landslide~dashboard",
        path: `${APP_PREFIX_PATH}/disasters/landslide/dashboard`,
        title: "sidenav.disasters.landslide.dashboard",
        icon: BarChartOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~landslide~listing",
        path: `${APP_PREFIX_PATH}/disasters/landslide/listing`,
        title: "sidenav.disasters.landslide.listing",
        icon: FormOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~landslide~review",
        path: `${APP_PREFIX_PATH}/disasters/landslide/review`,
        title: "sidenav.disasters.landslide.review",
        icon: FileDoneOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~landslide~review~revenue",
            path: `${APP_PREFIX_PATH}/disasters/landslide/review/revenue`,
            title: "sidenav.disasters.landslide.review.revenue",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~landslide~review~district",
            path: `${APP_PREFIX_PATH}/disasters/landslide/review/district`,
            title: "sidenav.disasters.landslide.review.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~landslide~review~state",
            path: `${APP_PREFIX_PATH}/disasters/landslide/review/state`,
            title: "sidenav.disasters.landslide.review.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~landslide~report",
        path: `${APP_PREFIX_PATH}/disasters/landslide/report`,
        title: "sidenav.disasters.landslide.report",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~landslide~report~district",
            path: `${APP_PREFIX_PATH}/disasters/landslide/report/district`,
            title: "sidenav.disasters.landslide.report.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~landslide~report~state",
            path: `${APP_PREFIX_PATH}/disasters/landslide/report/state`,
            title: "sidenav.disasters.landslide.report.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~landslide~user-registration",
        path: `${APP_PREFIX_PATH}/disasters/landslide/user-registration`,
        title: "sidenav.disasters.landslide.user-registration",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~landslide~user-registration~revenue~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/landslide/user-registration/revenue/camp-inmates`,
            title:
              "sidenav.disasters.landslide.report.user-registration.revenue.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~landslide~user-registration~revenue~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/landslide/user-registration/revenue/noncamp-inmates`,
            title:
              "sidenav.disasters.landslide.report.user-registration.revenue.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~landslide~user-registration~district~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/landslide/user-registration/district/camp-inmates`,
            title:
              "sidenav.disasters.landslide.report.user-registration.district.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~landslide~user-registration~district~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/landslide/user-registration/district/noncamp-inmates`,
            title:
              "sidenav.disasters.landslide.report.user-registration.district.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~landslide~user-registration~state~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/landslide/user-registration/state/camp-inmates`,
            title:
              "sidenav.disasters.landslide.report.user-registration.state.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~landslide~user-registration~state~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/landslide/user-registration/state/noncamp-inmates`,
            title:
              "sidenav.disasters.landslide.report.user-registration.state.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~landslide~freeze-report",
        path: `${APP_PREFIX_PATH}/disasters/landslide/report-freezing`,
        title: "sidenav.disasters.landslide.freeze",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
    ],
  },
];

/**
 * The below array will be used for showing the menu of Storm
 */
const menuStormTree = [
  {
    key: "app",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.app",
    icon: "",
    breadcrumb: false,
    visible: true,
    submenu: [
      {
        key: "disasters~listing",
        path: `${APP_PREFIX_PATH}/disasters/listing`,
        title: "sidenav.disasters.listing",
        icon: HomeOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~storm~map",
        path: `${APP_PREFIX_PATH}/disasters/storm/map`,
        title: "sidenav.disasters.storm.map",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~storm~dashboard",
        path: `${APP_PREFIX_PATH}/disasters/storm/dashboard`,
        title: "sidenav.disasters.storm.dashboard",
        icon: BarChartOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~storm~listing",
        path: `${APP_PREFIX_PATH}/disasters/storm/listing`,
        title: "sidenav.disasters.storm.listing",
        icon: FormOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~storm~review",
        path: `${APP_PREFIX_PATH}/disasters/storm/review`,
        title: "sidenav.disasters.storm.review",
        icon: FileDoneOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~storm~review~revenue",
            path: `${APP_PREFIX_PATH}/disasters/storm/review/revenue`,
            title: "sidenav.disasters.storm.review.revenue",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~storm~review~district",
            path: `${APP_PREFIX_PATH}/disasters/storm/review/district`,
            title: "sidenav.disasters.storm.review.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~storm~review~state",
            path: `${APP_PREFIX_PATH}/disasters/storm/review/state`,
            title: "sidenav.disasters.storm.review.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~storm~report",
        path: `${APP_PREFIX_PATH}/disasters/storm/report`,
        title: "sidenav.disasters.storm.report",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~storm~report~district",
            path: `${APP_PREFIX_PATH}/disasters/storm/report/district`,
            title: "sidenav.disasters.storm.report.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~storm~report~state",
            path: `${APP_PREFIX_PATH}/disasters/storm/report/state`,
            title: "sidenav.disasters.storm.report.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~storm~user-registration",
        path: `${APP_PREFIX_PATH}/disasters/storm/user-registration`,
        title: "sidenav.disasters.storm.user-registration",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~storm~user-registration~revenue~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/storm/user-registration/revenue/camp-inmates`,
            title:
              "sidenav.disasters.storm.report.user-registration.revenue.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~storm~user-registration~revenue~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/storm/user-registration/revenue/noncamp-inmates`,
            title:
              "sidenav.disasters.storm.report.user-registration.revenue.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~storm~user-registration~district~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/storm/user-registration/district/camp-inmates`,
            title:
              "sidenav.disasters.storm.report.user-registration.district.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~storm~user-registration~district~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/storm/user-registration/district/noncamp-inmates`,
            title:
              "sidenav.disasters.storm.report.user-registration.district.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~storm~user-registration~state~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/storm/user-registration/state/camp-inmates`,
            title:
              "sidenav.disasters.storm.report.user-registration.state.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~storm~user-registration~state~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/storm/user-registration/state/noncamp-inmates`,
            title:
              "sidenav.disasters.storm.report.user-registration.state.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~storm~freeze-report",
        path: `${APP_PREFIX_PATH}/disasters/storm/report-freezing`,
        title: "sidenav.disasters.storm.freeze",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
    ],
  },
];

/**
 * The below array will be used for showing the menu of Lightning
 */
const menuLightningTree = [
  {
    key: "app",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.app",
    icon: "",
    breadcrumb: false,
    visible: true,
    submenu: [
      {
        key: "disasters~listing",
        path: `${APP_PREFIX_PATH}/disasters/listing`,
        title: "sidenav.disasters.listing",
        icon: HomeOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~lightning~map",
        path: `${APP_PREFIX_PATH}/disasters/lightning/map`,
        title: "sidenav.disasters.lightning.map",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~lightning~dashboard",
        path: `${APP_PREFIX_PATH}/disasters/lightning/dashboard`,
        title: "sidenav.disasters.lightning.dashboard",
        icon: BarChartOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~lightning~listing",
        path: `${APP_PREFIX_PATH}/disasters/lightning/listing`,
        title: "sidenav.disasters.lightning.listing",
        icon: FormOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~lightning~review",
        path: `${APP_PREFIX_PATH}/disasters/lightning/review`,
        title: "sidenav.disasters.lightning.review",
        icon: FileDoneOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~lightning~review~revenue",
            path: `${APP_PREFIX_PATH}/disasters/lightning/review/revenue`,
            title: "sidenav.disasters.lightning.review.revenue",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~lightning~review~district",
            path: `${APP_PREFIX_PATH}/disasters/lightning/review/district`,
            title: "sidenav.disasters.lightning.review.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~lightning~review~state",
            path: `${APP_PREFIX_PATH}/disasters/lightning/review/state`,
            title: "sidenav.disasters.lightning.review.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~lightning~report",
        path: `${APP_PREFIX_PATH}/disasters/lightning/report`,
        title: "sidenav.disasters.lightning.report",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~lightning~report~district",
            path: `${APP_PREFIX_PATH}/disasters/lightning/report/district`,
            title: "sidenav.disasters.lightning.report.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~lightning~report~state",
            path: `${APP_PREFIX_PATH}/disasters/lightning/report/state`,
            title: "sidenav.disasters.lightning.report.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~lightning~user-registration",
        path: `${APP_PREFIX_PATH}/disasters/lightning/user-registration`,
        title: "sidenav.disasters.lightning.user-registration",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~lightning~user-registration~revenue~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/lightning/user-registration/revenue/camp-inmates`,
            title:
              "sidenav.disasters.lightning.report.user-registration.revenue.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~lightning~user-registration~revenue~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/lightning/user-registration/revenue/noncamp-inmates`,
            title:
              "sidenav.disasters.lightning.report.user-registration.revenue.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~lightning~user-registration~district~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/lightning/user-registration/district/camp-inmates`,
            title:
              "sidenav.disasters.lightning.report.user-registration.district.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~lightning~user-registration~district~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/lightning/user-registration/district/noncamp-inmates`,
            title:
              "sidenav.disasters.lightning.report.user-registration.district.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~lightning~user-registration~state~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/lightning/user-registration/state/camp-inmates`,
            title:
              "sidenav.disasters.lightning.report.user-registration.state.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~lightning~user-registration~state~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/lightning/user-registration/state/noncamp-inmates`,
            title:
              "sidenav.disasters.lightning.report.user-registration.state.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~lightning~freeze-report",
        path: `${APP_PREFIX_PATH}/disasters/lightning/report-freezing`,
        title: "sidenav.disasters.lightning.freeze",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
    ],
  },
];

/**
 * The below array will be used for showing the menu of Urbanflood
 */
const menuUrbanFloodTree = [
  {
    key: "app",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.app",
    icon: "",
    breadcrumb: false,
    visible: true,
    submenu: [
      {
        key: "disasters~listing",
        path: `${APP_PREFIX_PATH}/disasters/listing`,
        title: "sidenav.disasters.listing",
        icon: HomeOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~urbanflood~map",
        path: `${APP_PREFIX_PATH}/disasters/urbanflood/map`,
        title: "sidenav.disasters.urbanflood.map",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~urbanflood~dashboard",
        path: `${APP_PREFIX_PATH}/disasters/urbanflood/dashboard`,
        title: "sidenav.disasters.urbanflood.dashboard",
        icon: BarChartOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~urbanflood~listing",
        path: `${APP_PREFIX_PATH}/disasters/urbanflood/listing`,
        title: "sidenav.disasters.urbanflood.listing",
        icon: FormOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~urbanflood~review",
        path: `${APP_PREFIX_PATH}/disasters/urbanflood/review`,
        title: "sidenav.disasters.urbanflood.review",
        icon: FileDoneOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~urbanflood~review~revenue",
            path: `${APP_PREFIX_PATH}/disasters/urbanflood/review/revenue`,
            title: "sidenav.disasters.urbanflood.review.revenue",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~urbanflood~review~district",
            path: `${APP_PREFIX_PATH}/disasters/urbanflood/review/district`,
            title: "sidenav.disasters.urbanflood.review.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~urbanflood~review~state",
            path: `${APP_PREFIX_PATH}/disasters/urbanflood/review/state`,
            title: "sidenav.disasters.urbanflood.review.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~urbanflood~report",
        path: `${APP_PREFIX_PATH}/disasters/urbanflood/report`,
        title: "sidenav.disasters.urbanflood.report",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~urbanflood~report~district",
            path: `${APP_PREFIX_PATH}/disasters/urbanflood/report/district`,
            title: "sidenav.disasters.urbanflood.report.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~urbanflood~report~state",
            path: `${APP_PREFIX_PATH}/disasters/urbanflood/report/state`,
            title: "sidenav.disasters.urbanflood.report.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~urbanflood~user-registration",
        path: `${APP_PREFIX_PATH}/disasters/urbanflood/user-registration`,
        title: "sidenav.disasters.urbanflood.user-registration",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~urbanflood~user-registration~revenue~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/urbanflood/user-registration/revenue/camp-inmates`,
            title:
              "sidenav.disasters.urbanflood.report.user-registration.revenue.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~urbanflood~user-registration~revenue~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/urbanflood/user-registration/revenue/noncamp-inmates`,
            title:
              "sidenav.disasters.urbanflood.report.user-registration.revenue.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~urbanflood~user-registration~district~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/urbanflood/user-registration/district/camp-inmates`,
            title:
              "sidenav.disasters.urbanflood.report.user-registration.district.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~urbanflood~user-registration~district~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/urbanflood/user-registration/district/noncamp-inmates`,
            title:
              "sidenav.disasters.urbanflood.report.user-registration.district.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~urbanflood~user-registration~state~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/urbanflood/user-registration/state/camp-inmates`,
            title:
              "sidenav.disasters.urbanflood.report.user-registration.state.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~urbanflood~user-registration~state~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/urbanflood/user-registration/state/noncamp-inmates`,
            title:
              "sidenav.disasters.urbanflood.report.user-registration.state.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~urbanflood~freeze-report",
        path: `${APP_PREFIX_PATH}/disasters/urbanflood/report-freezing`,
        title: "sidenav.disasters.urbanflood.freeze",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
    ],
  },
];

/**
 * The below array will be used for showing the menu of Earthquake
 */
const menuEarthquakeTree = [
  {
    key: "app",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.app",
    icon: "",
    breadcrumb: false,
    visible: true,
    submenu: [
      {
        key: "disasters~listing",
        path: `${APP_PREFIX_PATH}/disasters/listing`,
        title: "sidenav.disasters.listing",
        icon: HomeOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~earthquake~map",
        path: `${APP_PREFIX_PATH}/disasters/earthquake/map`,
        title: "sidenav.disasters.earthquake.map",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~earthquake~dashboard",
        path: `${APP_PREFIX_PATH}/disasters/earthquake/dashboard`,
        title: "sidenav.disasters.earthquake.dashboard",
        icon: BarChartOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~earthquake~listing",
        path: `${APP_PREFIX_PATH}/disasters/earthquake/listing`,
        title: "sidenav.disasters.earthquake.listing",
        icon: FormOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~earthquake~review",
        path: `${APP_PREFIX_PATH}/disasters/earthquake/review`,
        title: "sidenav.disasters.earthquake.review",
        icon: FileDoneOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~earthquake~review~revenue",
            path: `${APP_PREFIX_PATH}/disasters/earthquake/review/revenue`,
            title: "sidenav.disasters.earthquake.review.revenue",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~earthquake~review~district",
            path: `${APP_PREFIX_PATH}/disasters/earthquake/review/district`,
            title: "sidenav.disasters.earthquake.review.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~earthquake~review~state",
            path: `${APP_PREFIX_PATH}/disasters/earthquake/review/state`,
            title: "sidenav.disasters.earthquake.review.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~earthquake~report",
        path: `${APP_PREFIX_PATH}/disasters/earthquake/report`,
        title: "sidenav.disasters.earthquake.report",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~earthquake~report~district",
            path: `${APP_PREFIX_PATH}/disasters/earthquake/report/district`,
            title: "sidenav.disasters.earthquake.report.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~earthquake~report~state",
            path: `${APP_PREFIX_PATH}/disasters/earthquake/report/state`,
            title: "sidenav.disasters.earthquake.report.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~earthquake~user-registration",
        path: `${APP_PREFIX_PATH}/disasters/earthquake/user-registration`,
        title: "sidenav.disasters.earthquake.user-registration",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~earthquake~user-registration~revenue~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/earthquake/user-registration/revenue/camp-inmates`,
            title:
              "sidenav.disasters.earthquake.report.user-registration.revenue.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~earthquake~user-registration~revenue~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/earthquake/user-registration/revenue/noncamp-inmates`,
            title:
              "sidenav.disasters.earthquake.report.user-registration.revenue.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~earthquake~user-registration~district~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/earthquake/user-registration/district/camp-inmates`,
            title:
              "sidenav.disasters.earthquake.report.user-registration.district.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~earthquake~user-registration~district~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/earthquake/user-registration/district/noncamp-inmates`,
            title:
              "sidenav.disasters.earthquake.report.user-registration.district.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~earthquake~user-registration~state~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/earthquake/user-registration/state/camp-inmates`,
            title:
              "sidenav.disasters.earthquake.report.user-registration.state.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~earthquake~user-registration~state~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/earthquake/user-registration/state/noncamp-inmates`,
            title:
              "sidenav.disasters.earthquake.report.user-registration.state.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~earthquake~freeze-report",
        path: `${APP_PREFIX_PATH}/disasters/earthquake/report-freezing`,
        title: "sidenav.disasters.earthquake.freeze",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
    ],
  },
];
//#endregion

//#region Fire navigation
/**
 * The below array will be used for showing the menu of Fire
 */
const menuFireTree = [
  {
    key: "app",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.app",
    icon: "",
    breadcrumb: false,
    visible: true,
    submenu: [
      {
        key: "disasters~listing",
        path: `${APP_PREFIX_PATH}/disasters/listing`,
        title: "sidenav.disasters.listing",
        icon: HomeOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      // {
      //   key: "disasters~fire~map",
      //   path: `${APP_PREFIX_PATH}/disasters/fire/map`,
      //   title: "sidenav.disasters.fire.map",
      //   icon: EnvironmentOutlined,
      //   breadcrumb: false,
      //   visible: true,
      //   submenu: [],
      // },
      {
        key: "disasters~fire~dashboard",
        path: `${APP_PREFIX_PATH}/disasters/fire/dashboard`,
        title: "sidenav.disasters.fire.dashboard",
        icon: BarChartOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~fire~listing",
        path: `${APP_PREFIX_PATH}/disasters/fire/listing`,
        title: "sidenav.disasters.fire.listing",
        icon: FormOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~fire~review",
        path: `${APP_PREFIX_PATH}/disasters/fire/review`,
        title: "sidenav.disasters.fire.review",
        icon: FileDoneOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~fire~review~hqcr",
            path: `${APP_PREFIX_PATH}/disasters/fire/review/headquarter`,
            title: "sidenav.disasters.fire.review.hqcr",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~fire~review~wing",
            path: `${APP_PREFIX_PATH}/disasters/fire/review/wing`,
            title: "sidenav.disasters.fire.review.wing",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~fire~review~spfe",
            path: `${APP_PREFIX_PATH}/disasters/fire/review/spfe`,
            title: "sidenav.disasters.fire.review.spfe",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~fire~review~dfes",
            path: `${APP_PREFIX_PATH}/disasters/fire/review/dfes`,
            title: "sidenav.disasters.fire.review.dfes",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];
//#endregion

/**
 * The below array will be used for showing the menu of Flood
 */
const menuFloodTree = [
  {
    key: "app",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.app",
    icon: "",
    breadcrumb: false,
    visible: true,
    submenu: [
      {
        key: "disasters~listing",
        path: `${APP_PREFIX_PATH}/disasters/listing`,
        title: "sidenav.disasters.listing",
        icon: HomeOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~flood~map",
        path: `${APP_PREFIX_PATH}/disasters/flood/map`,
        title: "sidenav.disasters.flood.map",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~flood~dashboard",
        path: `${APP_PREFIX_PATH}/disasters/flood/dashboard`,
        title: "sidenav.disasters.flood.dashboard",
        icon: BarChartOutlined,
        breadcrumb: false,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~flood~listing",
        path: `${APP_PREFIX_PATH}/disasters/flood/listing`,
        title: "sidenav.disasters.flood.listing",
        icon: FormOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~flood~review",
        path: `${APP_PREFIX_PATH}/disasters/flood/review`,
        title: "sidenav.disasters.flood.review",
        icon: FileDoneOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~flood~review~revenue",
            path: `${APP_PREFIX_PATH}/disasters/flood/review/revenue`,
            title: "sidenav.disasters.flood.review.revenue",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~flood~review~district",
            path: `${APP_PREFIX_PATH}/disasters/flood/review/district`,
            title: "sidenav.disasters.flood.review.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~flood~review~state",
            path: `${APP_PREFIX_PATH}/disasters/flood/review/state`,
            title: "sidenav.disasters.flood.review.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~flood~report",
        path: `${APP_PREFIX_PATH}/disasters/flood/report`,
        title: "sidenav.disasters.flood.report",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~flood~report~district",
            path: `${APP_PREFIX_PATH}/disasters/flood/report/district`,
            title: "sidenav.disasters.flood.report.district",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~flood~report~state",
            path: `${APP_PREFIX_PATH}/disasters/flood/report/state`,
            title: "sidenav.disasters.flood.report.state",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "cwc",
        path: `${APP_PREFIX_PATH}/disasters/flood/cwc`,
        title: "sidenav.cwc",
        icon: HomeOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
      {
        key: "disasters~flood~user-registration",
        path: `${APP_PREFIX_PATH}/disasters/flood/user-registration`,
        title: "sidenav.disasters.flood.user-registration",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [
          {
            key: "disasters~flood~user-registration~revenue~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/flood/user-registration/revenue/camp-inmates`,
            title:
              "sidenav.disasters.flood.report.user-registration.revenue.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~flood~user-registration~revenue~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/flood/user-registration/revenue/noncamp-inmates`,
            title:
              "sidenav.disasters.flood.report.user-registration.revenue.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~flood~user-registration~district~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/flood/user-registration/district/camp-inmates`,
            title:
              "sidenav.disasters.flood.report.user-registration.district.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~flood~user-registration~district~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/flood/user-registration/district/noncamp-inmates`,
            title:
              "sidenav.disasters.flood.report.user-registration.district.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~flood~user-registration~state~campInmates",
            path: `${APP_PREFIX_PATH}/disasters/flood/user-registration/state/camp-inmates`,
            title:
              "sidenav.disasters.flood.report.user-registration.state.campInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
          {
            key: "disasters~flood~user-registration~state~noncampInmates",
            path: `${APP_PREFIX_PATH}/disasters/flood/user-registration/state/noncamp-inmates`,
            title:
              "sidenav.disasters.flood.report.user-registration.state.noncampInmates",
            icon: "",
            breadcrumb: true,
            visible: true,
            submenu: [],
          },
        ],
      },
      {
        key: "disasters~flood~freeze-report",
        path: `${APP_PREFIX_PATH}/disasters/flood/report-freezing`,
        title: "sidenav.disasters.flood.freeze",
        icon: FileOutlined,
        breadcrumb: true,
        visible: true,
        submenu: [],
      },
    ],
  },
];

//const navigationConfig = [...menuNavTree];

const navigationConfig = {
  menuNavTree: menuNavTree,
  menuErosionTree: menuErosionTree,
  menuLandslideTree: menuLandslideTree,
  menuStormTree: menuStormTree,
  menuLightningTree: menuLightningTree,
  menuUrbanFloodTree: menuUrbanFloodTree,
  menuEarthquakeTree: menuEarthquakeTree,
  menuFireTree: menuFireTree,
  menuFloodTree: menuFloodTree,
};

export const getMenu = (pathName, loginUser) => {
  let menu = [];
  switch (true) {
    case pathName.includes(Disaster_Url_Slugs.InfDamage):
      menu = navigationConfig.menuInfDamageTree;
      break;
    case pathName.includes(Disaster_Url_Slugs.Erosion):
      menu = navigationConfig.menuErosionTree;
      break;
    case pathName.includes(Disaster_Url_Slugs.Landslide):
      menu = navigationConfig.menuLandslideTree;
      break;
    case pathName.includes(Disaster_Url_Slugs.Storm):
      menu = navigationConfig.menuStormTree;
      break;
    case pathName.includes(Disaster_Url_Slugs.Lightning):
      menu = navigationConfig.menuLightningTree;
      break;
    case pathName.includes(Disaster_Url_Slugs.UrbanFlood):
      menu = navigationConfig.menuUrbanFloodTree;
      break;
    case pathName.includes(Disaster_Url_Slugs.Earthquake):
      menu = navigationConfig.menuEarthquakeTree;
      break;
    case pathName.includes(Disaster_Url_Slugs.Fire):
      menu = navigationConfig.menuFireTree;
      break;
    case pathName.includes(Disaster_Url_Slugs.Flood):
      menu = navigationConfig.menuFloodTree;
      break;
    default:
      menu = navigationConfig.menuNavTree;
      break;
  }
  menu[0].submenu = updateMenu(menu[0].submenu, loginUser);
  return menu;
};

const updateMenu = (menu, loginUser) => {
  menu = menu.map((item) => {
    if (item.submenu.length > 0) {
      item.submenu = updateMenu(item.submenu, loginUser);
    }

    if (item.key === "masters") {
      item.visible = Roles.ADMIN.some(
        (id) => id.toString() === loginUser?.role?.id?.toString()
      );
    }
    if (item.key === "user") {
      item.visible = Roles.ADMIN.some(
        (id) => id.toString() === loginUser?.role?.id?.toString()
      );
    }
    if (item.key === "cwc") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    //Erosioin
    if (item.key === "disasters~erosion~listing") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~erosion~review") {
      item.visible = Roles.FO.every(
        (id) => id.toString() !== loginUser?.role?.id?.toString()
      );
    }
    if (item.key === "disasters~erosion~review~revenue") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~erosion~review~district") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key === "disasters~erosion~user-registration~state~campInmates" ||
      item.key === "disasters~erosion~user-registration~state~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key === "disasters~erosion~user-registration~revenue~campInmates" ||
      item.key === "disasters~erosion~user-registration~revenue~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key === "disasters~erosion~user-registration~district~campInmates" ||
      item.key === "disasters~erosion~user-registration~district~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~erosion~review~state") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (item.key === "disasters~erosion~freeze-report") {
      item.visible = Roles.CEOASDMA.some(
        (id) => id.toString() === loginUser?.role?.id?.toString()
      );
    }

    //Landslide
    if (item.key === "disasters~landslide~listing") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~landslide~review") {
      item.visible = Roles.FO.every(
        (id) => id.toString() !== loginUser?.role?.id?.toString()
      );
    }
    if (item.key === "disasters~landslide~review~revenue") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~landslide~review~district") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key === "disasters~landslide~user-registration~state~campInmates" ||
      item.key === "disasters~landslide~user-registration~state~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key ===
        "disasters~landslide~user-registration~revenue~campInmates" ||
      item.key ===
        "disasters~landslide~user-registration~revenue~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key ===
        "disasters~landslide~user-registration~district~campInmates" ||
      item.key ===
        "disasters~landslide~user-registration~district~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~landslide~review~state") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (item.key === "disasters~landslide~freeze-report") {
      item.visible = Roles.CEOASDMA.some(
        (id) => id.toString() === loginUser?.role?.id?.toString()
      );
    }

    //Storm
    if (item.key === "disasters~storm~listing") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~storm~review") {
      item.visible = Roles.FO.every(
        (id) => id.toString() !== loginUser?.role?.id?.toString()
      );
    }
    if (item.key === "disasters~storm~review~revenue") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~storm~review~district") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (
      item.key === "disasters~storm~user-registration~state~campInmates" ||
      item.key === "disasters~storm~user-registration~state~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key === "disasters~storm~user-registration~revenue~campInmates" ||
      item.key === "disasters~storm~user-registration~revenue~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key === "disasters~storm~user-registration~district~campInmates" ||
      item.key === "disasters~storm~user-registration~district~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (item.key === "disasters~storm~review~state") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (item.key === "disasters~storm~freeze-report") {
      item.visible = Roles.CEOASDMA.some(
        (id) => id.toString() === loginUser?.role?.id?.toString()
      );
    }

    //Lightning
    if (item.key === "disasters~lightning~listing") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~lightning~review") {
      item.visible = Roles.FO.every(
        (id) => id.toString() !== loginUser?.role?.id?.toString()
      );
    }
    if (item.key === "disasters~lightning~review~revenue") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~lightning~review~district") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (
      item.key === "disasters~lightning~user-registration~state~campInmates" ||
      item.key === "disasters~lightning~user-registration~state~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key ===
        "disasters~lightning~user-registration~revenue~campInmates" ||
      item.key ===
        "disasters~lightning~user-registration~revenue~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key ===
        "disasters~lightning~user-registration~district~campInmates" ||
      item.key ===
        "disasters~lightning~user-registration~district~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (item.key === "disasters~lightning~review~state") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (item.key === "disasters~lightning~freeze-report") {
      item.visible = Roles.CEOASDMA.some(
        (id) => id.toString() === loginUser?.role?.id?.toString()
      );
    }

    //UrbanFlood
    if (item.key === "disasters~urbanflood~listing") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~urbanflood~review") {
      item.visible = Roles.FO.every(
        (id) => id.toString() !== loginUser?.role?.id?.toString()
      );
    }
    if (item.key === "disasters~urbanflood~review~revenue") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~urbanflood~review~district") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (
      item.key === "disasters~urbanflood~user-registration~state~campInmates" ||
      item.key === "disasters~urbanflood~user-registration~state~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key ===
        "disasters~urbanflood~user-registration~revenue~campInmates" ||
      item.key ===
        "disasters~urbanflood~user-registration~revenue~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key ===
        "disasters~urbanflood~user-registration~district~campInmates" ||
      item.key ===
        "disasters~urbanflood~user-registration~district~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (item.key === "disasters~urbanflood~review~state") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (item.key === "disasters~urbanflood~freeze-report") {
      item.visible = Roles.CEOASDMA.some(
        (id) => id.toString() === loginUser?.role?.id?.toString()
      );
    }

    //Earthquake
    if (item.key === "disasters~earthquake~listing") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~earthquake~review") {
      item.visible = Roles.FO.every(
        (id) => id.toString() !== loginUser?.role?.id?.toString()
      );
    }
    if (item.key === "disasters~earthquake~review~revenue") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~earthquake~review~district") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (
      item.key === "disasters~earthquake~user-registration~state~campInmates" ||
      item.key === "disasters~earthquake~user-registration~state~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key ===
        "disasters~earthquake~user-registration~revenue~campInmates" ||
      item.key ===
        "disasters~earthquake~user-registration~revenue~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key ===
        "disasters~earthquake~user-registration~district~campInmates" ||
      item.key ===
        "disasters~earthquake~user-registration~district~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (item.key === "disasters~earthquake~review~state") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    if (item.key === "disasters~earthquake~freeze-report") {
      item.visible = Roles.CEOASDMA.some(
        (id) => id.toString() === loginUser?.role?.id?.toString()
      );
    }

    // Fire
    if (item.key === "disasters~fire~listing") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FS.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~fire~review") {
      item.visible = Roles.FS.every(
        (id) => id.toString() !== loginUser?.role?.id?.toString()
      );
    }
    if (item.key === "disasters~fire~review~wing") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.WING.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~fire~review~hqcr") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.HQCR.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~fire~review~spfe") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SPFE.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~fire~review~dfes") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DFES.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }

    //Flood
    if (item.key === "disasters~flood~listing") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~flood~review") {
      item.visible = Roles.FO.every(
        (id) => id.toString() !== loginUser?.role?.id?.toString()
      );
    }
    if (item.key === "disasters~flood~review~revenue") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~flood~review~district") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key === "disasters~flood~user-registration~state~campInmates" ||
      item.key === "disasters~flood~user-registration~state~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key === "disasters~flood~user-registration~revenue~campInmates" ||
      item.key === "disasters~flood~user-registration~revenue~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.FO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CO.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (
      item.key === "disasters~flood~user-registration~district~campInmates" ||
      item.key === "disasters~flood~user-registration~district~noncampInmates"
    ) {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.DDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~flood~review~state") {
      item.visible =
        Roles.ADMIN.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.SEOC.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        ) ||
        Roles.CEOASDMA.some(
          (id) => id.toString() === loginUser?.role?.id?.toString()
        );
    }
    if (item.key === "disasters~flood~freeze-report") {
      item.visible = Roles.CEOASDMA.some(
        (id) => id.toString() === loginUser?.role?.id?.toString()
      );
    }
    return item;
  });
  return menu;
};

export default navigationConfig;
