import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_ALL_CREATE_LIGHTNING_NON_INMATES_REGISTRATION,
  SET_ALL_LIGHTNING_NON_INMATES_REGISTRATION,
  SET_ALL_UPDATE_LIGHTNING_NON_INMATES_REGISTRATION,
  SET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_LIGHTNING_NON_INMATES_REGISTRATION,
} from "redux/constants/LightningNonInmatesRegistration";

// Define an initial state
const initialState = {
  lightningNonInmatesRegistrationData: [],
  lightningIsNonInmatesRegistrationDataFetched: false,
  lightningNonInmatesRegistrationListingParam: {},
  lightningNonCampInmatesRegistrationExportedData: {},
  token: localStorage.getItem(AUTH_TOKEN),
};

// Reducer function
const lightningNonInmatesRegistration = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_LIGHTNING_NON_INMATES_REGISTRATION:
      return {
        ...state,
        lightningNonInmatesRegistrationData: action.payload,
        lightningIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_CREATE_LIGHTNING_NON_INMATES_REGISTRATION:
      // Handle the logic to create a new report here
      // You can update the state accordingly
      // For example, if you want to store the new report data, you can do something like this:

      return {
        ...state,
        lightningNonInmatesRegistrationData: action.payload,
        lightningIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_UPDATE_LIGHTNING_NON_INMATES_REGISTRATION:
      return {
        ...state,
        lightningNonInmatesRegistrationData: action.payload,
        lightningIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_LIGHTNING_NON_INMATES_REGISTRATION:
      return {
        ...state,
        lightningNonInmatesRegistrationListingParam: action.payload,
      };

    case GET_EXPORT_NON_CAMP_INMATES_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_NON_CAMP_INMATES_DATA:
      return {
        ...state,
        lightningNonCampInmatesRegistrationExportedData: action.payload,
      };

    default:
      return state;
  }
};

export default lightningNonInmatesRegistration;
