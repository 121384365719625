/**
 * The below file is the Storm Inmates Registration service which contains method for doing the API calls.
 * @author: vishal.mishra@velsof.com
 * @date : 08-03-2024
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Storm_Create_Inmates_Registration,
  Storm_Export_Inmates_Registration,
  Storm_Import_Inmates_Registration,
  Multipart_Form_Data,
  Blob,
  Storm_Export_Template_Inmates_Registration,
  Storm_Import_Inmates_Registration_Xlsx,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const StormInmatesRegistrationServices = {};

//#region Method for Storm Freeze Report listing page.

/**
 * Method for getting the Storm Freeze Report listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
StormInmatesRegistrationServices.getAll = function (param) {
  return fetch({
    url: Storm_Create_Inmates_Registration,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

//#region Method for Storm Freeze Report .

/**
 * Method for importing the Storm Freeze Report
 * @param {any} data API Data
 * @returns Fetch Method
 */
StormInmatesRegistrationServices.create = function (data) {
  return fetch({
    url: Storm_Create_Inmates_Registration,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for Storm Freeze Report .

/**
 * Method for importing the Storm Freeze Report
 * @param {any} data API Data
 * @returns Fetch Method
 */
StormInmatesRegistrationServices.update = function (data, id) {
  return fetch({
    url: Storm_Create_Inmates_Registration + `/${id}`,
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for Storm Freeze Report .

/**
 * Method for importing the Camp Inmates Registration
 * @param {any} data API data
 * @returns Fetch Method
 */
StormInmatesRegistrationServices.campInmatesRegistrationImport = function (
  data
) {
  return fetch({
    url: Storm_Import_Inmates_Registration,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for importing the Non Camp Inmates Registration Excel
 * @param {any} data API data
 * @returns Fetch Method
 */
StormInmatesRegistrationServices.campInmatesRegistrationImportExcel =
  function (data) {
    return fetch({
      url: Storm_Import_Inmates_Registration_Xlsx,
      method: "post",
      headers: {
        "Content-Type": Application_Json,
        Authorization: format(
          Authorization_Token,
          localStorage.getItem(AUTH_TOKEN)
        ),
      },
      data: data,
    });
  };

/**
 * Method for getting the reliefCamp export data
 * @returns Fetch Method
 */
StormInmatesRegistrationServices.getExportInmatesRegistrationData =
  function (payload) {
    return fetch({
      url: Storm_Export_Inmates_Registration,
      method: "post",
      headers: {
        "Content-Type": Application_Json,
        Authorization: format(
          Authorization_Token,
          localStorage.getItem(AUTH_TOKEN)
        ),
      },
      data: payload,
      responseType: Blob,
    });
  };

/**
 * Method for getting the Template Import
 * @returns Fetch Method
 */
StormInmatesRegistrationServices.getImportTemplateInmatesRegistrationData =
  function (payload) {
    return fetch({
      url: Storm_Export_Template_Inmates_Registration,
      method: "post",
      headers: {
        "Content-Type": Application_Json,
        Authorization: format(
          Authorization_Token,
          localStorage.getItem(AUTH_TOKEN)
        ),
      },
      data: payload,
      responseType: Blob,
    });
  };

export default StormInmatesRegistrationServices;
