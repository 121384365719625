import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_CAMP_INMATES_DATA,
  GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  SET_ALL_CREATE_URBANFLOOD_INMATES_REGISTRATION,
  SET_ALL_URBANFLOOD_INMATES_REGISTRATION,
  SET_ALL_UPDATE_URBANFLOOD_INMATES_REGISTRATION,
  SET_EXPORT_CAMP_INMATES_DATA,
  SET_URBANFLOOD_INMATES_REGISTRATION,
  SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
} from "redux/constants/UrbanFloodInmatesRegistration";

// Define an initial state
const initialState = {
  urbanfloodInmatesRegistrationData: [],
  urbanfloodIsInmatesRegistrationDataFetched: false,
  urbanfloodInmatesRegistrationListingParam: {},
  urbanfloodCampInmatesRegistrationExportedData: {},
  urbanfloodCampInmatesRegistrationImportTemplateData: {},
  token: localStorage.getItem(AUTH_TOKEN),
};

// Reducer function
const urbanfloodInmatesRegistration = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_URBANFLOOD_INMATES_REGISTRATION:
      return {
        ...state,
        urbanfloodInmatesRegistrationData: action.payload,
        urbanfloodIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_CREATE_URBANFLOOD_INMATES_REGISTRATION:
      // Handle the logic to create a new report here
      // You can update the state accordingly
      // For example, if you want to store the new report data, you can do something like this:

      return {
        ...state,
        urbanfloodInmatesRegistrationData: action.payload,
        urbanfloodIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_UPDATE_URBANFLOOD_INMATES_REGISTRATION:
      return {
        ...state,
        urbanfloodInmatesRegistrationData: action.payload,
        urbanfloodIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_URBANFLOOD_INMATES_REGISTRATION:
      return {
        ...state,
        urbanfloodInmatesRegistrationListingParam: action.payload,
      };

    case GET_EXPORT_CAMP_INMATES_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_CAMP_INMATES_DATA:
      return {
        ...state,
        urbanfloodCampInmatesRegistrationExportedData: action.payload,
      };

    case GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA:
      return {
        ...state,
        payload: action.payload,
      };

    case SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA:
      return {
        ...state,
        urbanfloodCampInmatesRegistrationImportTemplateData: action.payload,
      };

    default:
      return state;
  }
};

export default urbanfloodInmatesRegistration;
