/**
 * The below file contains the lightning reports state objects
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 26-12-2022
 */

import {
  BasicSection,
  CampInmatesSection,
  DiscardButton,
  HllSection,
  HouseDamagedSection,
  InfDamageOtherSection,
  LiveStockSection,
  PopAffectedSection,
  ReliefCampSection,
  ReliefDistributionSection,
  RemarkSection,
  RescueOperationSection,
  SubmitButton,
} from "constants/ApplicationConstant";
import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_LIGHTNING_DATA,
  LIGHTNING_ADD_BASIC_SECTION,
  LIGHTNING_ADD_INFDAMAGEOTHER_SECTION,
  LIGHTNING_ADD_REMARK_SECTION,
  LIGHTNING_EDIT_BASIC_SECTION,
  LIGHTNING_REPORT_DISCARD,
  LIGHTNING_REPORT_SUBMIT,
  LIGHTNING_RESET_BUTTON_LOADING,
  LIGHTNING_SET_ALL_SECTION,
  LIGHTNING_SET_DATA_SAVED,
  LIGHTNING_SET_NIL_REPORTING,
  LIGHTNING_SET_PARENT_ID,
  LIGHTNING_SET_REDIRECT_TO_LISTING,
  SET_EXPORT_LIGHTNING_DATA,
  SET_LIGHTNING_LISTING,
  SET_LIGHTNING_LISTING_PARAM,
  LIGHTNING_ADD_POPAFFECTED_SECTION,
  LIGHTNING_ADD_CAMPINMATES_SECTION,
  LIGHTNING_ADD_RELIEFCAMP_SECTION,
  LIGHTNING_ADD_HLL_SECTION,
  LIGHTNING_ADD_LIVESTOCK_SECTION,
  LIGHTNING_ADD_HOUSEDAMAGED_SECTION,
  LIGHTNING_ADD_RELIEFDISTRIBUTION_SECTION,
  LIGHTNING_ADD_RESCUEOPERATION_SECTION,
  SET_LIGHTNING_DISTRICT_REVIEW_LIST,
  SET_LIGHTNING_STATE_REVIEW_LIST,
  LIGHTNING_REVENUE_REPORT_DISAPPROVE,
  LIGHTNING_REVENUE_REPORT_VERIFY,
  LIGHTNING_DISTRICT_REPORT_APPROVE,
  LIGHTNING_DISTRICT_REPORT_DISAPPROVE,
  LIGHTNING_DISTRICT_REPORT_VERIFY,
  LIGHTNING_STATE_REPORT_APPROVE,
  LIGHTNING_STATE_REPORT_DISAPPROVE,
  SET_EXPORT_FO_LEVEL_LIGHTNING_REPORT,
  SET_STATE_CUMULATIVE_LIGHTNING_REPORT,
  SET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT,
} from "redux/constants/Lightning";
import { NonCampInmatesSection } from "../../constants/ApplicationConstant";
import {
  LIGHTNING_ADD_NONCAMPINMATES_SECTION,
  LIGHTNING_SET_RELIEF_CENTER_SECTION,
  FREEZE_UNFREEZE_LIGHTNING_RANGE_REPORT,
} from "../constants/Lightning";

//Initial state
const initState = {
  //Keys for listing
  lightningList: {},
  isLightningDataFetched: false,
  token: localStorage.getItem(AUTH_TOKEN),
  lightningListingParam: {},
  lightningExportedData: {},
  exportFoLevelLightningReport: {
    report: "",
    format: "",
  },
  exportStateCumulativeLightningReport: {
    report: "",
    format: "",
  },
  exportDistrictCumulativeLightningReport: {
    report: "",
    format: "",
  },
  //Keys for listing

  //Keys for All Section Data
  lightningParentId: "",
  lightningAllSectionData: {},
  lightningReliefCenterData: [],
  lightningButtonLoading: {
    basicSection: false,
    popAffectedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    nonCampInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
    submitButton: false,
    discardButton: false,
    approveButton: false,
    disapproveButton: false,
    verifyButton: false,
  },
  lightningDataSaved: {
    basicSection: false,
    popAffectedSection: false,
    reliefCampSection: false,
    campInmatesSection: false,
    nonCampInmatesSection: false,
    hLLSection: false,
    liveStockSection: false,
    houseDamagedSection: false,
    rescueOperationSection: false,
    reliefDistributionSection: false,
    infDamageOtherSection: false,
    remarkSection: false,
  },
  lightningIsNilReporting: false,
  lightningRedirectToListing: false,
  //Keys for All Section Data

  //Keys for Approval Disapproval
  lightningDistrictReviewList: {},
  lightningStateReviewList: {},
  //Keys for Approval Disapproval

  //keys for Flood Form Freeze Report Range
  lightningFreezeReportRange: {},
  //keys for Flood Form Freeze Report Range
};

/**
 * The below function will update the state on the basis of action type.
 * @param {any} state State
 * @param {any} action Action
 * @returns Json object
 */
const lightning = (state = initState, action) => {
  switch (action.type) {
    //Cases for listing
    case SET_LIGHTNING_LISTING:
      return {
        ...state,
        lightningList: action.payload,
        isLightningDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_LIGHTNING_LISTING_PARAM:
      return {
        ...state,
        lightningListingParam: action.payload,
      };

    case GET_EXPORT_LIGHTNING_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_LIGHTNING_DATA:
      return {
        ...state,
        lightningExportedData: action.payload,
      };
    //Cases for listing

    //Cases for All Section
    case LIGHTNING_SET_ALL_SECTION:
      return {
        ...state,
        lightningAllSectionData: action.payload,
      };

    case LIGHTNING_SET_RELIEF_CENTER_SECTION:
      return {
        ...state,
        lightningReliefCenterData: action.payload,
      };

    case LIGHTNING_RESET_BUTTON_LOADING:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            basicSection: false,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            popAffectedSection: false,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            reliefCampSection: false,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            campInmatesSection: false,
          },
        };
      } else if (action.payload.section === NonCampInmatesSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            nonCampInmatesSection: false,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            hLLSection: false,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            liveStockSection: false,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            houseDamagedSection: false,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            rescueOperationSection: false,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            reliefDistributionSection: false,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            infDamageOtherSection: false,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            remarkSection: false,
          },
        };
      } else if (action.payload.section === SubmitButton) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            submitButton: false,
          },
        };
      } else if (action.payload.section === DiscardButton) {
        return {
          ...state,
          lightningButtonLoading: {
            ...state.lightningButtonLoading,
            discardButton: false,
          },
        };
      } else {
        return {
          ...state,
          lightningButtonLoading: {
            basicSection: false,
            popAffectedSection: false,
            reliefCampSection: false,
            campInmatesSection: false,
            nonCampInmatesSection: false,
            hLLSection: false,
            liveStockSection: false,
            houseDamagedSection: false,
            rescueOperationSection: false,
            reliefDistributionSection: false,
            infDamageOtherSection: false,
            remarkSection: false,
            submitButton: false,
            discardButton: false,
            approveButton: false,
            disapproveButton: false,
            verifyButton: false,
          },
        };
      }

    case LIGHTNING_SET_PARENT_ID:
      return {
        ...state,
        lightningParentId: action.payload,
      };

    case LIGHTNING_SET_REDIRECT_TO_LISTING:
      return {
        ...state,
        lightningRedirectToListing: action.payload,
      };

    case LIGHTNING_SET_DATA_SAVED:
      if (action.payload.section === BasicSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            basicSection: action.payload.value,
          },
        };
      } else if (action.payload.section === PopAffectedSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            popAffectedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefCampSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            reliefCampSection: action.payload.value,
          },
        };
      } else if (action.payload.section === CampInmatesSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            campInmatesSection: action.payload.value,
          },
        };
      } else if (action.payload.section === NonCampInmatesSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            nonCampInmatesSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HllSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            hLLSection: action.payload.value,
          },
        };
      } else if (action.payload.section === LiveStockSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            liveStockSection: action.payload.value,
          },
        };
      } else if (action.payload.section === HouseDamagedSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            houseDamagedSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RescueOperationSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            rescueOperationSection: action.payload.value,
          },
        };
      } else if (action.payload.section === ReliefDistributionSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            reliefDistributionSection: action.payload.value,
          },
        };
      } else if (action.payload.section === InfDamageOtherSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            infDamageOtherSection: action.payload.value,
          },
        };
      } else if (action.payload.section === RemarkSection) {
        return {
          ...state,
          lightningDataSaved: {
            ...state.lightningDataSaved,
            remarkSection: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case LIGHTNING_REPORT_SUBMIT:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          submitButton: true,
        },
      };

    case LIGHTNING_REPORT_DISCARD:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          approveButton: true,
        },
      };

    case LIGHTNING_REVENUE_REPORT_DISAPPROVE:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          disapproveButton: true,
        },
      };

    case LIGHTNING_REVENUE_REPORT_VERIFY:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          verifyButton: true,
        },
      };
    //#endregion

    case LIGHTNING_REPORT_DISCARD:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          discardButton: true,
        },
      };

    case LIGHTNING_DISTRICT_REPORT_APPROVE:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          approveButton: true,
        },
      };

    case LIGHTNING_DISTRICT_REPORT_DISAPPROVE:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          disapproveButton: true,
        },
      };

    case LIGHTNING_DISTRICT_REPORT_VERIFY:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          verifyButton: true,
        },
      };

    case LIGHTNING_STATE_REPORT_APPROVE:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          approveButton: true,
        },
      };

    case LIGHTNING_STATE_REPORT_DISAPPROVE:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          disapproveButton: true,
        },
      };
    //Cases for All Section

    //Cases for Basic Section
    case LIGHTNING_ADD_BASIC_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          basicSection: true,
        },
      };

    case LIGHTNING_EDIT_BASIC_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          basicSection: true,
        },
      };

    case LIGHTNING_SET_NIL_REPORTING:
      return {
        ...state,
        lightningIsNilReporting: action.payload,
      };
    //Cases for Basic Section

    //Cases for InfDamageOther Section
    case LIGHTNING_ADD_INFDAMAGEOTHER_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          infDamageOtherSection: true,
        },
      };

    //Cases for Remark Section
    case LIGHTNING_ADD_REMARK_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          remarkSection: true,
        },
      };

    //Cases for Lightning Pop Affected Section
    case LIGHTNING_ADD_POPAFFECTED_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          popAffectedSection: true,
        },
      };
    //Cases for Lightning Pop Affected Section

    //Cases for Lightning Relief Camp Section
    case LIGHTNING_ADD_RELIEFCAMP_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          reliefCampSection: true,
        },
      };
    //Cases for Lightning Relief Camp Section

    //Cases for Lightning Relief Camp Section
    case LIGHTNING_ADD_CAMPINMATES_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          campInmatesSection: true,
        },
      };
    //Cases for Lightning Relief Camp Section

    //Cases for Lightning Relief Camp Section
    case LIGHTNING_ADD_NONCAMPINMATES_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          nonCampInmatesSection: true,
        },
      };
    //Cases for Lightning Relief Camp Section

    //Cases for Lightning Hll Section
    case LIGHTNING_ADD_HLL_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          hLLSection: true,
        },
      };
    //Cases for Lightning Hll Section

    //Cases for Lightning LiveStock Section
    case LIGHTNING_ADD_LIVESTOCK_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          liveStockSection: true,
        },
      };
    //Cases for Lightning LiveStock Section

    //Cases for Lightning House Damaged Section
    case LIGHTNING_ADD_HOUSEDAMAGED_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          houseDamagedSection: true,
        },
      };
    //Cases for Lightning House Damaged Section

    //Cases for Lightning Rescue Operation Section
    case LIGHTNING_ADD_RESCUEOPERATION_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          rescueOperationSection: true,
        },
      };
    //Cases for Lightning Rescue Operation Section

    //Cases for Lightning Relief Distribution Section
    case LIGHTNING_ADD_RELIEFDISTRIBUTION_SECTION:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          reliefDistributionSection: true,
        },
      };
    //Cases for Lightning Relief Distribution Section

    // Cases for Lightning form PDF download section
    case SET_EXPORT_FO_LEVEL_LIGHTNING_REPORT:
      return {
        ...state,
        exportFoLevelLightningReport: {
          report: action.payload.url,
          format: action.payload.format,
        },
      };
    // Cases for Lightning form export file section

    //Cases for Approval and Disapproval
    case SET_LIGHTNING_DISTRICT_REVIEW_LIST:
      return {
        ...state,
        lightningDistrictReviewList: action.payload,
      };

    case SET_LIGHTNING_STATE_REVIEW_LIST:
      return {
        ...state,
        lightningStateReviewList: action.payload,
      };
    //Cases for Approval and Disapproval

    // Cases for Lightning state cumulative report
    case SET_STATE_CUMULATIVE_LIGHTNING_REPORT:
      return {
        ...state,
        exportStateCumulativeLightningReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Lightning state cumulative report

    // Cases for Lightning district cumulative report
    case SET_DISTRICT_CUMULATIVE_LIGHTNING_REPORT:
      return {
        ...state,
        exportDistrictCumulativeLightningReport: {
          report: action.payload.reportData,
          format: action.payload.format,
        },
      };
    // Cases for Lightning district cumulative report

    // Cases for Lightning Freeze report for a selected range

    case FREEZE_UNFREEZE_LIGHTNING_RANGE_REPORT:
      return {
        ...state,
        lightningButtonLoading: {
          ...state.lightningButtonLoading,
          lightningFreezeButton: true,
        },
        lightningFreezeReportRange: action.payload,
      };
    // Cases for Lightning Freeze report for a selected range

    default:
      return state;
  }
};

export default lightning;
