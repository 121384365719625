/**
 * The below file contains the actions used in the Camp Inmates Registration.
 * @author: vishal.mishra@velsof.com
 * @date :11-10-2023
 */

import {
  CAMP_INMATES_REGISTRATION_IMPORT,
  GET_ALL_CREATE_LIGHTNING_INMATES_REGISTRATION,
  GET_ALL_LIGHTNING_INMATES_REGISTRATION,
  GET_ALL_UPDATE_LIGHTNING_INMATES_REGISTRATION,
  GET_EXPORT_CAMP_INMATES_DATA,
  GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  SET_ALL_CREATE_LIGHTNING_INMATES_REGISTRATION,
  SET_ALL_LIGHTNING_INMATES_REGISTRATION,
  SET_ALL_UPDATE_LIGHTNING_INMATES_REGISTRATION,
  SET_EXPORT_CAMP_INMATES_DATA,
  SET_LIGHTNING_INMATES_REGISTRATION,
  SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
} from "redux/constants/LightningInmatesRegistration";

// Import the constants you defined

// Action to fetch the Camp Inmates Registration
export const lightningGetAllInmatesRegistration = (data) => {
  return {
    type: GET_ALL_LIGHTNING_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Camp Inmates Registration
export const lightningSetAllInmatesRegistration = (data) => {
  return {
    type: SET_ALL_LIGHTNING_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Camp Inmates Registration
export const lightningGetAllCreateInmatesRegistration = (data) => {
  return {
    type: GET_ALL_CREATE_LIGHTNING_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Camp Inmates Registration
export const lightningSetAllCreateInmatesRegistration = (data) => {
  return {
    type: SET_ALL_CREATE_LIGHTNING_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Camp Inmates Registration
export const lightningGetAllUpdateInmatesRegistration = (data, id) => {
  return {
    type: GET_ALL_UPDATE_LIGHTNING_INMATES_REGISTRATION,
    payload: data,
    id: id,
  };
};

// Action to fetch a new Camp Inmates Registration
export const lightningSetAllUpdateInmatesRegistration = (data) => {
  return {
    type: SET_ALL_UPDATE_LIGHTNING_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for setting the Lightning Inmates Registration parameters
 * @param {any} data Lightning Data
 * @returns Json Object
 */
export const lightningSetInmatesRegistrationTableParam = (data) => {
  return {
    type: SET_LIGHTNING_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for Camp Inmates Registration import
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const lightningCampInmatesRegistrationImport = (data) => {
  return {
    type: CAMP_INMATES_REGISTRATION_IMPORT,
    payload: data,
  };
};

/**
 * Method for Non Camp Inmates Registration import excel
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const lightningCampInmatesRegistrationImportExcel = (data) => {
  return {
    type: CAMP_INMATES_REGISTRATION_IMPORT_EXCEL,
    payload: data,
  };
};

/**
 * Method for getting the export file of Camp Inmates Registration
 * @returns Json Object
 */
export const lightningGetExportInmatesRegistration = (data) => {
  return {
    type: GET_EXPORT_CAMP_INMATES_DATA,
    payload: data,
  };
};

/**
 * Method for setting the reliefCamp export data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const lightningSetExportInmatesRegistration = (data) => {
  return {
    type: SET_EXPORT_CAMP_INMATES_DATA,
    payload: data,
  };
};
/**
 * Method for getting the import template of Camp Inmates Registration
 * @returns Json Object
 */
export const lightningGetImportTemplateInmatesRegistration = (data) => {
  return {
    type: GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
    payload: data,
  };
};

/**
 * Method for setting the import template data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const lightningSetImportTemplateInmatesRegistration = (data) => {
  return {
    type: SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
    payload: data,
  };
};
