/**
 * The below file is the Lightning Inmates Registration service which contains method for doing the API calls.
 * @author: vishal.mishra@velsof.com
 * @date : 08-03-2024
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Lightning_Create_Inmates_Registration,
  Lightning_Export_Inmates_Registration,
  Lightning_Import_Inmates_Registration,
  Multipart_Form_Data,
  Blob,
  Lightning_Export_Template_Inmates_Registration,
  Lightning_Import_Inmates_Registration_Xlsx,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const LightningInmatesRegistrationServices = {};

//#region Method for Lightning Freeze Report listing page.

/**
 * Method for getting the Lightning Freeze Report listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
LightningInmatesRegistrationServices.getAll = function (param) {
  return fetch({
    url: Lightning_Create_Inmates_Registration,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

//#region Method for Lightning Freeze Report .

/**
 * Method for importing the Lightning Freeze Report
 * @param {any} data API Data
 * @returns Fetch Method
 */
LightningInmatesRegistrationServices.create = function (data) {
  return fetch({
    url: Lightning_Create_Inmates_Registration,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for Lightning Freeze Report .

/**
 * Method for importing the Lightning Freeze Report
 * @param {any} data API Data
 * @returns Fetch Method
 */
LightningInmatesRegistrationServices.update = function (data, id) {
  return fetch({
    url: Lightning_Create_Inmates_Registration + `/${id}`,
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for Lightning Freeze Report .

/**
 * Method for importing the Camp Inmates Registration
 * @param {any} data API data
 * @returns Fetch Method
 */
LightningInmatesRegistrationServices.campInmatesRegistrationImport = function (
  data
) {
  return fetch({
    url: Lightning_Import_Inmates_Registration,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for importing the Non Camp Inmates Registration Excel
 * @param {any} data API data
 * @returns Fetch Method
 */
LightningInmatesRegistrationServices.campInmatesRegistrationImportExcel =
  function (data) {
    return fetch({
      url: Lightning_Import_Inmates_Registration_Xlsx,
      method: "post",
      headers: {
        "Content-Type": Application_Json,
        Authorization: format(
          Authorization_Token,
          localStorage.getItem(AUTH_TOKEN)
        ),
      },
      data: data,
    });
  };

/**
 * Method for getting the reliefCamp export data
 * @returns Fetch Method
 */
LightningInmatesRegistrationServices.getExportInmatesRegistrationData =
  function (payload) {
    return fetch({
      url: Lightning_Export_Inmates_Registration,
      method: "post",
      headers: {
        "Content-Type": Application_Json,
        Authorization: format(
          Authorization_Token,
          localStorage.getItem(AUTH_TOKEN)
        ),
      },
      data: payload,
      responseType: Blob,
    });
  };

/**
 * Method for getting the Template Import
 * @returns Fetch Method
 */
LightningInmatesRegistrationServices.getImportTemplateInmatesRegistrationData =
  function (payload) {
    return fetch({
      url: Lightning_Export_Template_Inmates_Registration,
      method: "post",
      headers: {
        "Content-Type": Application_Json,
        Authorization: format(
          Authorization_Token,
          localStorage.getItem(AUTH_TOKEN)
        ),
      },
      data: payload,
      responseType: Blob,
    });
  };

export default LightningInmatesRegistrationServices;
