/**
 * The below file contains the Sagas method for Storm reports.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  BasicSection,
  DiscardButton,
  InfDamageOtherSection,
  PopAffectedSection,
  RemarkSection,
  SubmitButton,
  approveButton,
  disapproveButton,
  ReliefCampSection,
  CampInmatesSection,
  HllSection,
  LiveStockSection,
  HouseDamagedSection,
  RescueOperationSection,
  ReliefDistributionSection,
  verifyButton,
} from "constants/ApplicationConstant";
import {
  Storm_Added_Basic_Section,
  Storm_Added_CampInmates_Section,
  Storm_Added_HLL_Section,
  Storm_Added_HouseDamaged_Section,
  Storm_Added_InfDamageOther_Section,
  Storm_Added_LiveStock_Section,
  Storm_Added_PopAffected_Section,
  Storm_Added_ReliefCamp_Section,
  Storm_Added_ReliefDistribution_Section,
  Storm_Added_Remark_Section,
  Storm_Added_RescueOperation_Section,
  Storm_Imported,
  Storm_Report_Discard,
  Storm_Report_Submit,
  Storm_Report_Approved,
  Storm_Report_Disapproved,
  Storm_Updated_Basic_Section,
  Storm_Report_Verify,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  stormResetButtonLoading,
  stormSetAllSection,
  stormSetDataSaved,
  stormSetParentId,
  stormSetRedirectToListing,
  setExportStorm,
  setStormListing,
  setStormDistrictReviewList,
  setExportDistrictCumulativeStormReport,
  setExportStateCumulativeStormReport,
  setExportFOLevelStormReport,
  setStormStateReviewList,
} from "redux/actions/Storm";
import {
  GET_ALL_STORM,
  GET_EXPORT_STORM_DATA,
  STORM_ADD_BASIC_SECTION,
  STORM_ADD_INFDAMAGEOTHER_SECTION,
  STORM_ADD_REMARK_SECTION,
  STORM_EDIT_BASIC_SECTION,
  STORM_GET_ALL_SECTION,
  STORM_IMPORT,
  STORM_REPORT_DISCARD,
  STORM_REPORT_SUBMIT,
  STORM_ADD_POPAFFECTED_SECTION,
  STORM_ADD_RELIEFCAMP_SECTION,
  STORM_ADD_CAMPINMATES_SECTION,
  STORM_ADD_HLL_SECTION,
  STORM_ADD_LIVESTOCK_SECTION,
  STORM_ADD_HOUSEDAMAGED_SECTION,
  STORM_ADD_RESCUEOPERATION_SECTION,
  STORM_ADD_RELIEFDISTRIBUTION_SECTION,
  GET_STORM_DISTRICT_REVIEW_LIST,
  GET_STORM_STATE_REVIEW_LIST,
  EXPORT_FO_LEVEL_STORM_REPORT,
  STORM_REVENUE_REPORT_APPROVE,
  STORM_REVENUE_REPORT_DISAPPROVE,
  STORM_REVENUE_REPORT_VERIFY,
  STORM_DISTRICT_REPORT_APPROVE,
  STORM_DISTRICT_REPORT_DISAPPROVE,
  STORM_DISTRICT_REPORT_VERIFY,
  STORM_STATE_REPORT_APPROVE,
  STORM_STATE_REPORT_DISAPPROVE,
  GET_DISTRICT_CUMULATIVE_STORM_REPORT,
  GET_STATE_CUMULATIVE_STORM_REPORT,
} from "redux/constants/Storm";
import StormService from "services/StormService";
import {
  STORM_ADD_NONCAMPINMATES_SECTION,
  FREEZE_UNFREEZE_STORM_RANGE_REPORT,
} from "../constants/Storm";
import {
  freezeButton,
  NonCampInmatesSection,
} from "../../constants/ApplicationConstant";
import {
  Storm_Added_NonCampInmates_Section,
  Storm_Freeze_Successfully,
} from "../../constants/MessageConstant";
import { stormSetReliefCenterSection } from "../actions/Storm";

//#region Method for listing

/**
 * Method for getting the storms
 */
export function* getAllStorm() {
  yield takeEvery(GET_ALL_STORM, function* ({ payload }) {
    try {
      const storm = yield call(StormService.getAllStorm, payload);
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(setStormListing(storm));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the storm export details
 */
export function* getStormExport() {
  yield takeEvery(GET_EXPORT_STORM_DATA, function* () {
    try {
      const storm = yield call(StormService.getExportStormData);
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        let exportData = {
          url: window.URL.createObjectURL(storm),
        };
        yield put(setExportStorm(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for storm import page

/**
 * Method for importing the storm
 */
export function* stormImport() {
  yield takeEvery(STORM_IMPORT, function* ({ payload }) {
    try {
      const storm = yield call(StormService.stormImport, payload);
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Storm_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for storm import page

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/**
 * Method for getting the all section data
 */
export function* stormGetAllSection() {
  yield takeEvery(STORM_GET_ALL_SECTION, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        StormService.stormGetAllSection,
        payload.id,
        payload.param
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(stormSetAllSection(infDepartment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for submitting the report
 */
export function* stormReportSubmit() {
  yield takeEvery(STORM_REPORT_SUBMIT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        StormService.stormReportSubmit,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(showMessage({ message: Storm_Report_Submit, type: Success }));
        yield put(stormSetRedirectToListing(true));
        yield put(stormResetButtonLoading({ section: SubmitButton }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: SubmitButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for discarding the report
 */
export function* stormReportDiscard() {
  yield takeEvery(STORM_REPORT_DISCARD, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        StormService.stormReportDiscard,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: Storm_Report_Discard, type: Success })
        );
        yield put(stormSetRedirectToListing(true));
        yield put(stormResetButtonLoading({ section: DiscardButton }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: DiscardButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding basic section
 */
export function* stormAddBasicSection() {
  yield takeEvery(STORM_ADD_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };

      const storm = yield call(StormService.stormAddBasicSection, final);
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(stormSetParentId(storm.data.id));
        yield put(stormResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({ message: Storm_Added_Basic_Section, type: Success })
        );
        yield put(stormSetDataSaved({ section: BasicSection, value: true }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for editing basic section
 */
export function* stormEditBasicSection() {
  yield takeEvery(STORM_EDIT_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const storm = yield call(
        StormService.stormEditBasicSection,
        payload.id,
        final
      );
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(stormResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: Storm_Updated_Basic_Section,
            type: Success,
          })
        );
        yield put(stormSetDataSaved({ section: BasicSection, value: true }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Basic Section

//#region InfDamageOther Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding infDamageOther section
 */
export function* stormAddInfDamageOtherSection() {
  yield takeEvery(STORM_ADD_INFDAMAGEOTHER_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const storm = yield call(
        StormService.stormAddInfDamageOtherSection,
        final
      );
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(stormResetButtonLoading({ section: InfDamageOtherSection }));
        yield put(
          showMessage({
            message: Storm_Added_InfDamageOther_Section,
            type: Success,
          })
        );
        yield put(
          stormSetDataSaved({
            section: InfDamageOtherSection,
            value: true,
          })
        );
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: InfDamageOtherSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion InfDamageOther Section

//#region Remark Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding Remark section
 */
export function* stormAddRemarkSection() {
  yield takeEvery(STORM_ADD_REMARK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const Storm = yield call(StormService.stormAddRemarkSection, final);
      if (Storm.error != null && Storm.error.message) {
        yield put(showMessage({ message: Storm.error.message, type: Error }));
      } else {
        yield put(stormResetButtonLoading({ section: RemarkSection }));
        yield put(
          showMessage({
            message: Storm_Added_Remark_Section,
            type: Success,
          })
        );
        yield put(stormSetDataSaved({ section: RemarkSection, value: true }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: RemarkSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Remark Section

//#region PopAffected Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding popaffected section
 */
export function* stormAddPopAffectedSection() {
  yield takeEvery(STORM_ADD_POPAFFECTED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const storm = yield call(StormService.stormAddPopAffectedSection, final);
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(stormResetButtonLoading({ section: PopAffectedSection }));
        yield put(
          showMessage({
            message: Storm_Added_PopAffected_Section,
            type: Success,
          })
        );
        yield put(
          stormSetDataSaved({ section: PopAffectedSection, value: true })
        );
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: PopAffectedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion PopAffected Section

//#region Relief Camp Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding relief camp section
 */
export function* stormAddReliefCampSection() {
  yield takeEvery(STORM_ADD_RELIEFCAMP_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const storm = yield call(StormService.stormAddReliefCampSection, final);
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(stormResetButtonLoading({ section: ReliefCampSection }));
        yield put(
          showMessage({
            message: Storm_Added_ReliefCamp_Section,
            type: Success,
          })
        );
        yield put(
          stormSetDataSaved({ section: ReliefCampSection, value: true })
        );
        yield put(
          stormSetReliefCenterSection(storm?.data?.data?.reliefCenterData)
        );
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: ReliefCampSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Relief Camp Section

//#region Camp inmates Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

/** Method for adding camp inmates section
 */
export function* stormAddCampInmatesSection() {
  yield takeEvery(STORM_ADD_CAMPINMATES_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const storm = yield call(StormService.stormAddCampInmatesSection, final);
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(stormResetButtonLoading({ section: CampInmatesSection }));
        yield put(
          showMessage({
            message: Storm_Added_CampInmates_Section,
            type: Success,
          })
        );
        yield put(
          stormSetDataSaved({ section: CampInmatesSection, value: true })
        );
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: CampInmatesSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Camp Inmates Section

//#region Non Camp inmates Section
//@author: vishal.mishra@velsof.com
//@date : 30-01-2025

/** Method for adding non camp inmates section
 */
export function* stormAddNonCampInmatesSection() {
  yield takeEvery(STORM_ADD_NONCAMPINMATES_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const storm = yield call(
        StormService.stormAddNonCampInmatesSection,
        final
      );
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(stormResetButtonLoading({ section: NonCampInmatesSection }));
        yield put(
          showMessage({
            message: Storm_Added_NonCampInmates_Section,
            type: Success,
          })
        );
        yield put(
          stormSetDataSaved({
            section: NonCampInmatesSection,
            value: true,
          })
        );
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: NonCampInmatesSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Non Camp Inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding Human Live Lost section
 */
export function* stormAddHLLSection() {
  yield takeEvery(STORM_ADD_HLL_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const storm = yield call(StormService.stormAddHLLSection, final);
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(stormResetButtonLoading({ section: HllSection }));
        yield put(
          showMessage({
            message: Storm_Added_HLL_Section,
            type: Success,
          })
        );
        yield put(stormSetDataSaved({ section: HllSection, value: true }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: HllSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding LiveStock section
 */
export function* stormAddLiveStockSection() {
  yield takeEvery(STORM_ADD_LIVESTOCK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const storm = yield call(StormService.stormAddLiveStockSection, final);
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(stormResetButtonLoading({ section: LiveStockSection }));
        yield put(
          showMessage({
            message: Storm_Added_LiveStock_Section,
            type: Success,
          })
        );
        yield put(
          stormSetDataSaved({ section: LiveStockSection, value: true })
        );
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: LiveStockSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding HouseDamaged section
 */
export function* stormAddHouseDamagedSection() {
  yield takeEvery(STORM_ADD_HOUSEDAMAGED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const storm = yield call(StormService.stormAddHouseDamagedSection, final);
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(stormResetButtonLoading({ section: HouseDamagedSection }));
        yield put(
          showMessage({
            message: Storm_Added_HouseDamaged_Section,
            type: Success,
          })
        );
        yield put(
          stormSetDataSaved({ section: HouseDamagedSection, value: true })
        );
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: HouseDamagedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding RescueOperation section
 */
export function* stormAddRescueOperationSection() {
  yield takeEvery(STORM_ADD_RESCUEOPERATION_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const storm = yield call(
        StormService.stormAddRescueOperationSection,
        final
      );
      if (storm.error != null && storm.error.message) {
        yield put(showMessage({ message: storm.error.message, type: Error }));
      } else {
        yield put(stormResetButtonLoading({ section: RescueOperationSection }));
        yield put(
          showMessage({
            message: Storm_Added_RescueOperation_Section,
            type: Success,
          })
        );
        yield put(
          stormSetDataSaved({ section: RescueOperationSection, value: true })
        );
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: RescueOperationSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding ReliefDistribution section
 */
export function* stormAddReliefDistributionSection() {
  yield takeEvery(
    STORM_ADD_RELIEFDISTRIBUTION_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const storm = yield call(
          StormService.stormAddReliefDistributionSection,
          final
        );
        if (storm.error != null && storm.error.message) {
          yield put(showMessage({ message: storm.error.message, type: Error }));
        } else {
          yield put(
            stormResetButtonLoading({ section: ReliefDistributionSection })
          );
          yield put(
            showMessage({
              message: Storm_Added_ReliefDistribution_Section,
              type: Success,
            })
          );
          yield put(
            stormSetDataSaved({
              section: ReliefDistributionSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          stormResetButtonLoading({ section: ReliefDistributionSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion ReliefDistribution Section

/**
 * The below method is for exporting the report in storm view form
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */

/**
 * Method for getting the district export details
 */
export function* getExportFoLevelStormReport() {
  yield takeEvery(EXPORT_FO_LEVEL_STORM_REPORT, function* ({ payload }) {
    try {
      const exportedReport = yield call(
        StormService.getExportFoLevelStormReport,
        payload
      );

      if (exportedReport.error != null && exportedReport.error.message) {
        yield put(
          showMessage({ message: exportedReport.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(exportedReport),
          format: payload.format,
        };
        yield put(setExportFOLevelStormReport(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#region Method for Approval and Disapproval
/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for approving the revenue report
 */
export function* stormRevenueReportApprove() {
  yield takeEvery(STORM_REVENUE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const stormReportStatus = yield call(
        StormService.stormRevenueReportApprove,
        payload
      );
      if (stormReportStatus.error != null && stormReportStatus.error.message) {
        yield put(
          showMessage({
            message: stormReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Storm_Report_Approved, type: Success })
        );
        yield put(stormSetRedirectToListing(true));
        yield put(stormResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the revenue report
 */
export function* stormRevenueReportDisapprove() {
  yield takeEvery(STORM_REVENUE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const stormReportStatus = yield call(
        StormService.stormRevenueReportDisapprove,
        payload
      );
      if (stormReportStatus.error != null && stormReportStatus.error.message) {
        yield put(
          showMessage({
            message: stormReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Storm_Report_Disapproved, type: Success })
        );
        yield put(stormSetRedirectToListing(true));
        yield put(stormResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: disapproveButton }));
    }
  });
}

/**
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
/**
 * Method for verify the revenue report
 */
export function* stormRevenueReportVerify() {
  yield takeEvery(STORM_REVENUE_REPORT_VERIFY, function* ({ payload }) {
    try {
      const stormReportStatus = yield call(
        StormService.stormRevenueReportVerify,
        payload
      );
      if (stormReportStatus.error != null && stormReportStatus.error.message) {
        yield put(
          showMessage({
            message: stormReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(showMessage({ message: Storm_Report_Verify, type: Success }));
        yield put(stormSetRedirectToListing(true));
        yield put(stormResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* stormDistrictReportApprove() {
  yield takeEvery(STORM_DISTRICT_REPORT_APPROVE, function* ({ payload }) {
    try {
      const stormReportStatus = yield call(
        StormService.stormDistrictReportApprove,
        payload
      );
      if (stormReportStatus.error != null && stormReportStatus.error.message) {
        yield put(
          showMessage({
            message: stormReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Storm_Report_Approved, type: Success })
        );
        yield put(stormSetRedirectToListing(true));
        yield put(stormResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* stormDistrictReportDisapprove() {
  yield takeEvery(STORM_DISTRICT_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const stormReportStatus = yield call(
        StormService.stormDistrictReportDisapprove,
        payload
      );
      if (stormReportStatus.error != null && stormReportStatus.error.message) {
        yield put(
          showMessage({
            message: stormReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({
            message: Storm_Report_Disapproved,
            type: Success,
          })
        );
        yield put(stormSetRedirectToListing(true));
        yield put(stormResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: disapproveButton }));
    }
  });
}

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* stormDistrictReportVerify() {
  yield takeEvery(STORM_DISTRICT_REPORT_VERIFY, function* ({ payload }) {
    try {
      const stormReportStatus = yield call(
        StormService.stormDistrictReportVerify,
        payload
      );
      if (stormReportStatus.error != null && stormReportStatus.error.message) {
        yield put(
          showMessage({
            message: stormReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(showMessage({ message: Storm_Report_Verify, type: Success }));
        yield put(stormSetRedirectToListing(true));
        yield put(stormResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* stormStateReportApprove() {
  yield takeEvery(STORM_STATE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const stormReportStatus = yield call(
        StormService.stormStateReportApprove,
        payload
      );
      if (stormReportStatus.error != null && stormReportStatus.error.message) {
        yield put(
          showMessage({
            message: stormReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Storm_Report_Approved, type: Success })
        );
        yield put(stormSetRedirectToListing(true));
        yield put(stormResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
export function* stormStateReportDisapprove() {
  yield takeEvery(STORM_STATE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const stormReportStatus = yield call(
        StormService.stormStateReportDisapprove,
        payload
      );
      if (stormReportStatus.error != null && stormReportStatus.error.message) {
        yield put(
          showMessage({
            message: stormReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Storm_Report_Disapproved, type: Success })
        );
        yield put(stormSetRedirectToListing(true));
        yield put(stormResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: disapproveButton }));
    }
  });
}
//#endregion Method for Approval and Disapproval

//#region
/**
 * The below method is for exporting the report in storm cumulative state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */

/**
 * Method for getting the cumulative state export details
 */
export function* getExportStateCumulativeStormReport() {
  yield takeEvery(GET_STATE_CUMULATIVE_STORM_REPORT, function* ({ payload }) {
    try {
      const exportedReport = yield call(
        StormService.getExportStateCumulativeStormReport,
        payload
      );
      if (exportedReport.error != null && exportedReport.error.message) {
        yield put(
          showMessage({ message: exportedReport.error.message, type: Error })
        );
      } else {
        if (payload?.sendEmail) {
          // Convert the Blob response to text
          const textResponse = yield exportedReport?.text();
          const parsedResponse = JSON.parse(textResponse);
          yield put(
            showMessage({
              message: parsedResponse?.message,
              type: Success,
            })
          );
        } else {
          let exportData = {
            reportData:
              payload.format === "html"
                ? exportedReport
                : window.URL.createObjectURL(exportedReport),
            format: payload.format,
          };
          yield put(setExportStateCumulativeStormReport(exportData));
        }
      }
    } catch (err) {
      let errPromise = yield err.response.data.text();
      yield put(
        showMessage({
          message: JSON.parse(errPromise).error.message,
          type: Error,
        })
      );
    }
  });
}
//#endregion

/**
 * Method for getting the cumulative district export details
 */
export function* getExportDistrictCumulativeStormReport() {
  yield takeEvery(
    GET_DISTRICT_CUMULATIVE_STORM_REPORT,
    function* ({ payload }) {
      try {
        const exportedReport = yield call(
          StormService.getExportDistrictCumulativeStormReport,
          payload
        );
        if (exportedReport.error != null && exportedReport.error.message) {
          yield put(
            showMessage({ message: exportedReport.error.message, type: Error })
          );
        } else {
          let exportData = {
            reportData:
              payload.format === "html"
                ? exportedReport
                : window.URL.createObjectURL(exportedReport),
            format: payload.format,
          };
          yield put(setExportDistrictCumulativeStormReport(exportData));
        }
      } catch (err) {
        let errPromise = yield err.response.data.text();
        yield put(
          showMessage({
            message: JSON.parse(errPromise).error.message,
            type: Error,
          })
        );
      }
    }
  );
}
//#endregion

/**
 * Method for getting the district review list
 */
export function* getStormDistrictReviewList() {
  yield takeEvery(GET_STORM_DISTRICT_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        StormService.getStormDistrictReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setStormDistrictReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the state review list
 */
export function* getStormStateReviewList() {
  yield takeEvery(GET_STORM_STATE_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        StormService.getStormStateReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setStormStateReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for Freezing and unfreezing storm report for a selected date range
 * @author: vishal.mishra@velsof.com
 * @date : 30-01-2024
 */
export function* stormFreezeReportRange() {
  yield takeEvery(FREEZE_UNFREEZE_STORM_RANGE_REPORT, function* ({ payload }) {
    try {
      const stormReportStatus = yield call(
        StormService.stormFreezeReportRange,
        payload
      );
      if (stormReportStatus.error != null && stormReportStatus.error.message) {
        yield put(
          showMessage({
            message: stormReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({
            message: Storm_Freeze_Successfully,
            type: Success,
          })
        );
        yield put(stormResetButtonLoading({ section: freezeButton }));
      }
    } catch (err) {
      yield put(stormResetButtonLoading({ section: freezeButton }));
    }
  });
}

//#endregion Method for Approval and Disapproval

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllStorm),
    fork(getStormExport),
    fork(stormImport),
    fork(stormAddBasicSection),
    fork(stormEditBasicSection),
    fork(stormGetAllSection),
    fork(stormAddInfDamageOtherSection),
    fork(stormAddRemarkSection),
    fork(stormReportSubmit),
    fork(stormReportDiscard),
    fork(stormAddPopAffectedSection),
    fork(stormAddReliefCampSection),
    fork(stormAddCampInmatesSection),
    fork(stormAddNonCampInmatesSection),
    fork(stormAddHLLSection),
    fork(stormAddLiveStockSection),
    fork(stormAddHouseDamagedSection),
    fork(stormAddRescueOperationSection),
    fork(stormAddReliefDistributionSection),
    fork(stormRevenueReportApprove),
    fork(stormRevenueReportDisapprove),
    fork(stormRevenueReportVerify),
    fork(stormDistrictReportApprove),
    fork(stormDistrictReportDisapprove),
    fork(stormDistrictReportVerify),
    fork(stormStateReportApprove),
    fork(stormStateReportDisapprove),
    fork(getExportFoLevelStormReport),
    fork(getStormDistrictReviewList),
    fork(getStormStateReviewList),
    fork(getExportStateCumulativeStormReport),
    fork(getExportDistrictCumulativeStormReport),
    fork(stormFreezeReportRange),
  ]);
}
