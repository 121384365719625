/**
 * The below file contains the constants used in the storm report.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */

/**Constants for storm Listing page */
export const GET_ALL_STORM = "GET_ALL_STORM";
export const SET_STORM_LISTING = "SET_STORM_LISTING";
export const SET_STORM_LISTING_PARAM = "SET_STORM_LISTING_PARAM";
export const GET_EXPORT_STORM_DATA = "GET_EXPORT_STORM_DATA";
export const SET_EXPORT_STORM_DATA = "SET_EXPORT_STORM_DATA";
/**Constants for storm Listing page */

/**Constants for storm Import page */
export const STORM_IMPORT = "STORM_IMPORT";
/**Constants for storm Import page */

//#region All Section
/**Constants for all section details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_GET_ALL_SECTION = "STORM_GET_ALL_SECTION";
export const STORM_SET_ALL_SECTION = "STORM_SET_ALL_SECTION";
export const STORM_SET_RELIEF_CENTER_SECTION =
  "STORM_SET_RELIEF_CENTER_SECTION";
export const STORM_RESET_BUTTON_LOADING = "STORM_RESET_BUTTON_LOADING";
export const STORM_SET_PARENT_ID = "STORM_SET_PARENT_ID";
export const STORM_REPORT_SUBMIT = "STORM_REPORT_SUBMIT";
export const STORM_REPORT_DISCARD = "STORM_REPORT_DISCARD";
export const STORM_SET_REDIRECT_TO_LISTING = "STORM_SET_REDIRECT_TO_LISTING";
export const STORM_SET_DATA_SAVED = "STORM_SET_DATA_SAVED";
//#endregion All Section

//#region Basic Section
/**Constants for adding the basic section details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_ADD_BASIC_SECTION = "STORM_ADD_BASIC_SECTION";
export const STORM_EDIT_BASIC_SECTION = "STORM_EDIT_BASIC_SECTION";
export const STORM_SET_NIL_REPORTING = "STORM_SET_NIL_REPORTING";
//#endregion Basic Section

//#region InfDamageOther Section
/**Constants for adding the infrastructure others damage details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_ADD_INFDAMAGEOTHER_SECTION =
  "STORM_ADD_INFDAMAGEOTHER_SECTION";
//#endregion InfDamageOther Section

//#region Remark Section
/**Constants for adding the Remark details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_ADD_REMARK_SECTION = "STORM_ADD_REMARK_SECTION";
//#endregion Remark Section

//#region PopAffected Section
/**Constants for adding the population affected details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_ADD_POPAFFECTED_SECTION = "STORM_ADD_POPAFFECTED_SECTION";
//#endregion PopAffected Section

//#region Relief Camp Section
/**Constants for adding the relief camp details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_ADD_RELIEFCAMP_SECTION = "STORM_ADD_RELIEFCAMP_SECTION";
//#endregion relief camp Section

//#region Camp Inmates Section
/**Constants for adding the camp Inmates details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_ADD_CAMPINMATES_SECTION = "STORM_ADD_CAMPINMATES_SECTION";
//#endregion Camp Inmates Section

//#region Camp Non Inmates Section
/**Constants for adding the non camp Inmates details
 * @author: vishal.mishra@velsof.com
 * @date : 30-01-2025
 */
export const STORM_ADD_NONCAMPINMATES_SECTION =
  "STORM_ADD_NONCAMPINMATES_SECTION";
//#endregion Camp Inmates Section

//#region Human Live Lost Section
/**Constants for adding the Human Live Lost details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_ADD_HLL_SECTION = "STORM_ADD_HLL_SECTION";
//#endregion Human Live Lost Section

//#region LiveStock Section
/**Constants for adding the LiveStock details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_ADD_LIVESTOCK_SECTION = "STORM_ADD_LIVESTOCK_SECTION";
//#endregion LiveStock Section

//#region HouseDamaged Section
/**Constants for adding the HouseDamaged details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_ADD_HOUSEDAMAGED_SECTION = "STORM_ADD_HOUSEDAMAGED_SECTION";
//#endregion HouseDamaged Section

//#region RescueOperation Section
/**Constants for adding the RescueOperation details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_ADD_RESCUEOPERATION_SECTION =
  "STORM_ADD_RESCUEOPERATION_SECTION";
//#endregion RescueOperation Section

//#region ReliefDistribution Section
/**Constants for adding the ReliefDistribution details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 09-12-2022
 */
export const STORM_ADD_RELIEFDISTRIBUTION_SECTION =
  "STORM_ADD_RELIEFDISTRIBUTION_SECTION";
//#endregion ReliefDistribution Section

/**
 * Constants for Reports and Approval and Disapproval
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
//#region Report and Approval and Disapproval
export const STORM_REVENUE_REPORT_APPROVE = "STORM_REVENUE_REPORT_APPROVE";
export const STORM_REVENUE_REPORT_DISAPPROVE =
  "STORM_REVENUE_REPORT_DISAPPROVE";
export const STORM_REVENUE_REPORT_VERIFY = "STORM_REVENUE_REPORT_VERIFY";

export const EXPORT_FO_LEVEL_STORM_REPORT = "EXPORT_FO_LEVEL_STORM_REPORT";
export const SET_EXPORT_FO_LEVEL_STORM_REPORT =
  "SET_EXPORT_FO_LEVEL_STORM_REPORT";

export const STORM_DISTRICT_REPORT_APPROVE = "STORM_DISTRICT_REPORT_APPROVE";
export const STORM_DISTRICT_REPORT_DISAPPROVE =
  "STORM_DISTRICT_REPORT_DISAPPROVE";
export const STORM_DISTRICT_REPORT_VERIFY = "STORM_DISTRICT_REPORT_VERIFY";

export const STORM_STATE_REPORT_APPROVE = "STORM_STATE_REPORT_APPROVE";
export const STORM_STATE_REPORT_DISAPPROVE = "STORM_STATE_REPORT_DISAPPROVE";

export const GET_STORM_DISTRICT_REVIEW_LIST = "GET_STORM_DISTRICT_REVIEW_LIST";
export const SET_STORM_DISTRICT_REVIEW_LIST = "SET_STORM_DISTRICT_REVIEW_LIST";
export const GET_STORM_STATE_REVIEW_LIST = "GET_STORM_STATE_REVIEW_LIST";
export const SET_STORM_STATE_REVIEW_LIST = "SET_STORM_STATE_REVIEW_LIST";

export const SET_DISTRICT_STORM_REPORT = "SET_DISTRICT_STORM_REPORT";
export const GET_DISTRICT_STORM_REPORT = "GET_DISTRICT_STORM_REPORT";
export const GET_STATE_CUMULATIVE_STORM_REPORT =
  "GET_STATE_CUMULATIVE_STORM_REPORT";
export const SET_STATE_CUMULATIVE_STORM_REPORT =
  "SET_STATE_CUMULATIVE_STORM_REPORT";
export const GET_DISTRICT_CUMULATIVE_STORM_REPORT =
  "GET_DISTRICT_CUMULATIVE_STORM_REPORT";
export const SET_DISTRICT_CUMULATIVE_STORM_REPORT =
  "SET_DISTRICT_CUMULATIVE_STORM_REPORT";
//#region for lightning form freeze unfreeze report for a selected Range
export const FREEZE_UNFREEZE_STORM_RANGE_REPORT =
  "FREEZE_UNFREEZE_STORM_RANGE_REPORT";
//#endregion Report and Approval and Disapproval
