/**
 * The below file contains the constants used in the Flood Non Inmates Registration
 * @author: vishal.mishra@velsof.com
 * @date :01-04-2024
 */

export const GET_ALL_FLOOD_NON_INMATES_REGISTRATION =
  "GET_ALL_FLOOD_NON_INMATES_REGISTRATION";
export const SET_ALL_FLOOD_NON_INMATES_REGISTRATION =
  "SET_ALL_FLOOD_NON_INMATES_REGISTRATION";

export const REPORT_DATE_PARAM = "reportDate";
export const STATUS_PARAM = "status";
export const GET_ALL_CREATE_FLOOD_NON_INMATES_REGISTRATION =
  "GET_ALL_CREATE_FLOOD_NON_INMATES_REGISTRATION";
export const SET_ALL_CREATE_FLOOD_NON_INMATES_REGISTRATION =
  "SET_ALL_CREATE_FLOOD_NON_INMATES_REGISTRATION";

export const GET_ALL_UPDATE_FLOOD_NON_INMATES_REGISTRATION =
  "GET_ALL_UPDATE_FLOOD_NON_INMATES_REGISTRATION";
export const SET_ALL_UPDATE_FLOOD_NON_INMATES_REGISTRATION =
  "SET_ALL_UPDATE_FLOOD_NON_INMATES_REGISTRATION";

export const SET_FLOOD_NON_INMATES_REGISTRATION = "SET_FLOOD_NON_INMATES_REGISTRATION";

/**Constants for relief camp Import page */
export const NON_CAMP_INMATES_REGISTRATION_IMPORT =
  "NON_CAMP_INMATES_REGISTRATION_IMPORT";
export const NON_CAMP_INMATES_REGISTRATION_IMPORT_EXCEL =
  "NON_CAMP_INMATES_REGISTRATION_IMPORT_EXCEL";
/**Constants for relief camp Import page */

/**Constants for camp inmates registration export */
export const GET_EXPORT_NON_CAMP_INMATES_DATA = "GET_EXPORT_NON_CAMP_INMATES_DATA";
export const SET_EXPORT_NON_CAMP_INMATES_DATA = "SET_EXPORT_NON_CAMP_INMATES_DATA";